import prebidCheck from './checks/prebidCheck';
import mpBannerLibConfigCheck from './checks/mpBannerLibConfigCheck';
import getConfig from '../../../requestAds/getConfig';
import debugMessage from '../../../v6/tools/debugMessage';

const DEBUG_NAMESPACE = 'dependenciesCheck';

const config = getConfig();
const isPubmaticWrapperEnabled = config?.experiments?.isPubmaticWrapperEnabled || false;
const dependencies: TCheckDependency[] = [mpBannerLibConfigCheck];

debugMessage(DEBUG_NAMESPACE, 'isPubmaticWrapperEnabled', isPubmaticWrapperEnabled);

if (!isPubmaticWrapperEnabled) {
  dependencies.push(prebidCheck);
}

export default dependencies;
