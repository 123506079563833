import SLOT_EVENTS from '../constants/SLOT_EVENTS';
import debugMessage from '../../../tools/debugMessage';
import logV6ExperimentMetric from '../../../tools/logV6ExperimentMetric';
import W from '../../../../globals/Window';

/**
 * Event listener that is fired when the creative's iframe fires its load event.
 * When rendering rich media ads in sync rendering mode, no iframe is used so
 * no SlotOnloadEvent will be fired.
 *
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotonloadevent
 *
 * @param event {{ slot: {} }} see https://developers.google.com/publisher-tag/reference#googletag.slot
 *  for slot definition
 */
const slotOnloadEventListener = (event: googletag.events.SlotOnloadEvent): void => {
  /**
   * Get targeting will return an array and not a string
   */
  const position = event.slot.getTargeting('pos')?.slice()?.pop();

  if (!position) {
    return;
  }

  const elementId = event.slot.getSlotElementId();

  debugMessage(position, 'Creative iframe for slot has loaded.', event);

  const windowCustomEvent: CustomEvent = new CustomEvent('gptSlotOnLoad', {
    detail: {
      elementId,
    },
  });

  W.dispatchEvent(windowCustomEvent);

  logV6ExperimentMetric(position, SLOT_EVENTS.SLOT_ONLOAD);
};

export default slotOnloadEventListener;
