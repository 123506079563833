import getConfig from '../../../requestAds/getConfig';
import SENTRY from '../../tools/constants/SENTRY';
import debugMessage from '../../tools/debugMessage';
import rewriteSentryFrameFileName from './rewriteSentryFrameFileName';

/**
 * For sentry to work, please ensure your MpAbTest cookie contains
 * `SentryForEcgJsBanners.B|AUR2848.B|HZ218.B`
 */
const createSentryClient = () => {
  const config = getConfig();
  const sentryExists = typeof window.Sentry !== 'undefined';

  if (config.enableSentryErrorLogging && sentryExists) {
    const client = new Sentry.BrowserClient({
      dsn: SENTRY.DSN,
      release: packageVersion,
      dist: commitHash,
      integrations: Sentry.defaultIntegrations,
      beforeSend: rewriteSentryFrameFileName,
    });
    const hub = new Sentry.Hub(client);

    debugMessage(DEBUG_NAMESPACE, `Sentry client created`, hub);

    return hub;
  }

  debugMessage(DEBUG_NAMESPACE, `Sentry client not created`, null);

  return null;
};

export default createSentryClient;
