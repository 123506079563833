import tenantData from '../../locales';
import isMobile from '../../helpers/isMobile';
import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';
import CATEGORY_IDS from '../constants/CATEGORY_IDS';

const { SSP_IMPROVE, SSP_CRITEO } = SSP_PROVIDERS;

type TBuildPlacementIdObjectProps = {
  categoryId: string;
  position: string;
};

const buildPlacementIdObject = ({ categoryId, position }: TBuildPlacementIdObjectProps): Record<string, any> => {
  const result = {};

  const categoryIndex = categoryId ? CATEGORY_IDS.indexOf(categoryId) : 0;
  const screenType = isMobile() ? 'mobile' : 'default';
  const placementsForPosition = tenantData.PLACEMENT_IDS[position];

  // eslint-disable-next-line complexity
  tenantData.SSP_PROVIDERS.forEach((ssp) => {
    if (!placementsForPosition) {
      return;
    }

    const isPlacementIdDataIsArray = Array.isArray(placementsForPosition.default[ssp]);
    if (ssp === SSP_IMPROVE && placementsForPosition[screenType] && placementsForPosition[screenType][ssp]) {
      // Improve doesn't support size mappings yet
      result[ssp] = isPlacementIdDataIsArray
        ? tenantData?.PLACEMENT_IDS?.[position]?.[screenType]?.[ssp]?.[categoryIndex]
        : tenantData?.PLACEMENT_IDS?.[position]?.[screenType]?.[ssp];
    } else if (ssp === SSP_CRITEO && placementsForPosition.default && placementsForPosition.default[ssp]) {
      result[ssp] = tenantData.PLACEMENT_IDS[position].default[ssp];
    } else if (placementsForPosition.default && placementsForPosition.default[ssp]) {
      result[ssp] = isPlacementIdDataIsArray
        ? tenantData?.PLACEMENT_IDS?.[position]?.default?.[ssp]?.[categoryIndex]
        : tenantData?.PLACEMENT_IDS?.[position]?.default?.[ssp];
    }
  });

  return result;
};

export default buildPlacementIdObject;
