import W from '../globals/Window';

/**
 * Using `window.matchMedia` here to check if the screen is smaller than
 * 760px. We handle all screens less than 760px as mobile.
 */
function isMobile() {
  return W.matchMedia && W.matchMedia(`(max-width: 760px)`).matches;
}

export default isMobile;
