import debugMessage from '../../v6/tools/debugMessage';
import buildAdSlotConfigV6 from '../../v6/adSlots/buildAdSlotConfigV6';

const setupAdditionalBannersAdapter = ({ getSlotReference, allAdSlots, setupAdSlots }) =>
  /**
   * Exposed global function used for setting up additional banners
   *
   * @param positions - an array of targets, i.e ['banner-viptop-dt', 'banner-vipleft-dt']
   * @returns {*}
   */
  function setupAdditionalBanners(positions) {
    if (!Array.isArray(positions)) {
      throw Error('Positions is not an array');
    }

    if (!allAdSlots.data) {
      allAdSlots.data = buildAdSlotConfigV6();
    }

    const adSlotsData = allAdSlots.data.filter((adSlot) => {
      const slotReference = getSlotReference(adSlot.target);
      const isSlotDisplayed = slotReference ? slotReference.displayed : false;
      return !isSlotDisplayed && adSlot.displayAfterInteraction && positions.indexOf(adSlot.position) >= 0;
    });

    if (adSlotsData.length === 0) {
      return false;
    }

    debugMessage(
      `setupAdditionalBannersAdapter`,
      'Setting up additional banners',
      adSlotsData.map((adSlot) => adSlot?.target),
    );

    return setupAdSlots(adSlotsData);
  };

export default setupAdditionalBannersAdapter;
