import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';
import getConfig from '../../requestAds/getConfig';
import isMobile from '../../helpers/isMobile';

const { SSP_TMP } = SSP_PROVIDERS;

const buildSSPTmpBidConfig = ({ placementIdObject, bannerParametersFormatted }): TSSPTMPConfig[] => {
  const {
    experiments: { enableTmp },
  } = getConfig();

  if (enableTmp && placementIdObject[SSP_TMP]) {
    const keyValueObjectPrefixed = {};
    Object.entries(bannerParametersFormatted).forEach((entry) => {
      const [key, value] = entry;
      keyValueObjectPrefixed[`kw_${key}`] = value;
    });

    const tmpConfig = Object.assign(keyValueObjectPrefixed, {
      placementId: placementIdObject[SSP_TMP],
      supplyType: isMobile() ? 'mobile_web' : 'web',
      kw_cat: bannerParametersFormatted.cat,
    });

    return [
      {
        bidder: SSP_TMP,
        params: tmpConfig,
      },
    ];
  }
  return [];
};

export default buildSSPTmpBidConfig;
