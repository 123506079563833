import isBrowserViewportTooLarge from './isBrowserViewportTooLarge';
import debugMessage from '../tools/debugMessage';

const getViewportSizesForPositionV6 = (adSlot: TBannerConfig): TBannerSizes[] => {
  const position = adSlot.bannerParameters?.pos;
  const sizeMappingThatFitsWidth = adSlot.prebidMapping?.find((sizeMap) => isBrowserViewportTooLarge(sizeMap.minWidth));

  if (!sizeMappingThatFitsWidth) {
    debugMessage(position, `Using fallback size mappings`, adSlot.fallbackSizeMappings);

    return adSlot.fallbackSizeMappings;
  }

  debugMessage(position, `Using prebid mappings`, sizeMappingThatFitsWidth.sizes);

  return sizeMappingThatFitsWidth.sizes;
};

export default getViewportSizesForPositionV6;
