import 'regenerator-runtime/runtime';
import createVersionIndicator from '../tools/createVersionIndicator';
import injectGPTforNewUsers from '../helpers/injectGPTforNewUsers';
import initMalvertisingProtection from '../../requestAds/malvertisingProtection';
import debugMessage from '../tools/debugMessage';
import setupSafeFrameMessaging from '../../requestAds/setupSafeFrameMessaging';
import { setupSafeFrameTracking } from '../../requestAds/eventLogger';
import { addMetrics } from '../metrics/bannerMetrics';
import setupPageWithBanners from '../../requestAds/requestBanners';
import setupGlobalMethods from '../api/setupGlobalMethods';
import dispatchLoadedEvent from '../../helpers/dispatchLoadedEvent';
import logSentryError from '../metrics/sentry/logSentryError';

let startedLoading = 0;

const initiateBannerLib = () => {
  startedLoading = Math.round(window?.performance?.now());
  debugMessage(DEBUG_NAMESPACE, `ecg-js-banners@${packageVersion} initiation started`);

  try {
    /**
     * For debugging puposes, indicates which version of the banner lib we are using
     */
    createVersionIndicator();
    /**
     * Check if GPT has been loaded by the serverside if not, we inject it.
     * In case user does not accept the cookie banner this script will not be loaded via Express-react or Aurora-templates
     * In that case we need to inject it
     */
    injectGPTforNewUsers();
    /**
     * Injects a script from Confiant that protects against Malvertising
     *
     * @required - For preventing bad actors.
     */
    initMalvertisingProtection();
    /**
     * Adds an event listener that will set classes to adjust the layout
     * of the page based on messages emitted by ads using the IAB's SafeFrame
     * specification
     *
     * SafeFrame is an API-capable iframe that provides a single,
     * unified mechanism for communication between advertiser and publisher content.
     * SafeFrame technology in Ad Manager provides transparent and rich interactions between
     * page content and ads, while preventing external access to sensitive data and providing
     * more granular control over which creatives are rendered using the SafeFrame container
     * with GPT.
     *
     * More on -> https://support.google.com/admanager/answer/6023110?hl=en-GB
     *
     * @required - We need this for adjusting our layouts
     */
    setupSafeFrameMessaging();
    /**
     * Adds an event listener that will log message events to GA
     * if the data has eventAction and eventLabel or just an eventAction
     * using the IAB's SafeFrame specification
     *
     * @required - We need this for tracking GA events
     */
    setupSafeFrameTracking();
    /**
     * Sets up the ad config and loads banners
     *
     * @required - This will be a different function that instatiates the new
     * lib and provides the prebid and banner config.
     */
    setupPageWithBanners();

    /**
     * Expose global api for this library
     *
     * @required - These are global functions used by several bff's
     */
    setupGlobalMethods();

    /**
     * Dispatches a banner-lib-loaded event so we know that we can
     * start using the exposed global functions
     *
     * @required - We still need this because there is a listener in the homepage
     */
    dispatchLoadedEvent();

    /**
     * Missing features.
     *
     * Investigate adding loadCmp.js in the head - https://jira.es.ecg.tools/browse/BNL-13064
     */
    debugMessage(DEBUG_NAMESPACE, `ecg-js-banners@${packageVersion} was initiated successfully`);
  } catch (error) {
    logSentryError(error);

    debugMessage(DEBUG_NAMESPACE, `ecg-js-banners@${packageVersion} failed to initiate`, error);
  }

  addMetrics(
    {
      startedLoading,
      finishedLoading: Math.round(window?.performance?.now()),
    },
    DEBUG_NAMESPACE,
  );
};

export default initiateBannerLib;
