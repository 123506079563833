import debugMessage from '../../../tools/debugMessage';
import logV6ExperimentMetric from '../../../tools/logV6ExperimentMetric';
import SLOT_EVENTS from '../constants/SLOT_EVENTS';

/**
 *  Event listener that is fired when an impression becomes viewable.
 *
 * An active view is defined by the IAB as the following
 * • For ads, 50% of the ad’s pixels are visible in the browser window
 *   for a continuous 1 second.
 * • For larger ads (those greater than 242,000 pixels), 30% of the ad’s
 *   pixels are visible in the browser window.
 * • For in-stream video ads, 50% of the ad’s pixels must be visible in
 *   the browser window for a continuous 2 seconds.
 *
 * @see https://support.google.com/admanager/answer/4524488
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.impressionviewableevent
 *
 * @param event {{ slot: {} }} see https://developers.google.com/publisher-tag/reference#googletag.slot
 *  for slot definition
 */
const impressionViewableEventListener = (event: googletag.events.ImpressionViewableEvent): void => {
  /**
   * Get targeting will return an array and not a string
   */
  const position = event.slot.getTargeting('pos')?.slice()?.pop();

  if (!position) {
    return;
  }

  debugMessage(position, 'Impression for slot became viewable', event);

  logV6ExperimentMetric(position, SLOT_EVENTS.IMPRESSION_VIEWABLE);
};

export default impressionViewableEventListener;
