/**
 * Function used for cloning data so we can preserve states in our
 * debugging log
 *
 * @param data
 * @returns {any}
 */
const cloneData = (data) => JSON.parse(JSON.stringify(data));

export default cloneData;
