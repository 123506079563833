/**
 * We need this adapter because the old test suite is written in mocha
 * using proxyquire which makes is very difficult to extract functions out
 * of the file without the tests breaking or requiring heavy maintenance.
 *
 * Using an adapter we can do two things.
 *
 * 1. we can preserve the legacy code
 * 2. test our new jest test suites against
 *    this code to ensure the functionality is preserved between the
 *    legacy code and the refactored code.
 */
const disableInitialLoadAdapter = ({ W, googleTag }) =>
  function disableInitialLoad() {
    if (!W.isInitialLoadDisabled) {
      W.isInitialLoadDisabled = true;
      googleTag.cmd.push(() => {
        googleTag.pubads().disableInitialLoad();
      });
    }
  };

export default disableInitialLoadAdapter;
