import setupSlotMetrics from '../../v6/metrics/bannerSlotMetrics/setupSlotMetrics';
import { addPerformanceMetric } from '../../v6/metrics/bannerMetrics';
import buildAdSlotConfigV6 from '../../v6/adSlots/buildAdSlotConfigV6';

const setupPageWithBannersAdapter = ({
  allAdSlots,
  clearSlotReferences,
  disableInitialLoad,
  gptPrebid,
  setupAdSlots,
}) =>
  function setupPageWithBanners(isPageReset = false) {
    /**
     * Clear all slot references. In LRP we call
     * `window.resetAllBannersInPageAdapter` which will destroy all slots and
     * run `setupPageWithBanners` again. Until we stop referencing slots internally we
     * need this function
     */
    clearSlotReferences();

    /**
     * Initiate Prebid
     */
    gptPrebid.initPrebid();

    /**
     * Setup ad slots and assign to the global adSlots store.
     *
     * TODO: Create a proper store for this
     *
     * @required - We need to format banner configs for the new lib
     */
    allAdSlots.data = buildAdSlotConfigV6();

    /**
     * displayAfterInteraction comes from the banner config but it looks like
     * it is always set to true
     *
     * @required - This should come from the BFF already formatted for the page
     */
    const pageAdSlotsData = allAdSlots.data.filter(
      (adSlot) =>
        /**
         * isPageReset is set to true when resetting page banners, this is so
         * we do not filter out banners with displayAfterInteraction set to true as this
         * will prevent them from being refreshed.
         */
        isPageReset || !adSlot.displayAfterInteraction,
    );

    /**
     * Pushes a function to `googleTag.cmd` which invokes
     * `googleTag.pubads().disableInitialLoad();` but only once.
     * Subsequent calls of `disableInitialLoad` is ignored
     *
     * @doneByLib - Done here https://github.com/eBayClassifiedsGroup/react-advertising/blob/a07ede7211ff677e1324f7c20763ee903e2d7585/src/Advertising.js#L341
     */
    disableInitialLoad();

    /**
     * This metric is logged to Grafana.
     * This marks that bids were requested, but this is a little primitive.
     * We should also mark how many slots were bidden for and how many came back with
     * valid bids
     * https://jira.es.ecg.tools/browse/BNL-13011
     *
     * @required - We should install metrics
     */
    addPerformanceMetric('bidsRequested');

    /**
     * This function adds metrics to GPT's event lifecycle.
     *
     * @documentation https://developers.google.com/publisher-tag/reference#googletag.events.event
     */
    setupSlotMetrics(isPageReset);
    /**
     * This function calls `defineSlots` which pushes a command to `googleTag.cmd`
     * This will then go through each defined page slot and then invoke the `displayBanners`
     * function once the slots have been registered with google
     *
     * @required - Setup the lib with our config here
     */
    return setupAdSlots(pageAdSlotsData, true);
  };

export default setupPageWithBannersAdapter;
