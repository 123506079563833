/**
 * Default currency is EUR
 * Full documentation of currency module available here
 * https://docs.prebid.org/dev-docs/modules/currency.html#currency-module
 */
const setupCurrencyModule = (pbjs: any) => {
  pbjs.setConfig({
    currency: {
      adServerCurrency: 'EUR',
      rates: 'https://cdn.jsdelivr.net/gh/prebid/currency-file@1/latest.json',
      defaultRates: {
        USD: {
          EUR: 0.86, // Default rate in case the rates above fail to load
        },
      },
    },
  });
};

export default setupCurrencyModule;
