import debugMessage from '../tools/debugMessage';

const hideBannerElement = (hideElementSelector?: string): void => {
  if (hideElementSelector) {
    const elementsToHide = document.querySelectorAll<HTMLElement>(hideElementSelector);
    const numberOfElements = elementsToHide?.length;

    if (!numberOfElements) {
      return;
    }

    elementsToHide.forEach((element) => {
      element.style.display = 'none';
    });

    debugMessage(
      'hideBannerElement',
      `${numberOfElements} elements hidden using the selector '${hideElementSelector}'`,
      elementsToHide,
    );
  }
};

export default hideBannerElement;
