import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';
import tenantData from '../../locales';
import isMobile from '../../helpers/isMobile';

const { SSP_GUMGUM } = SSP_PROVIDERS;

const buildSSPGumGumBidConfig = ({ placementIdObject }): TSSPGumGumConfig[] => {
  /**
   * https://docs.prebid.org/dev-docs/bidders/gumgum.html
   */
  if (placementIdObject[SSP_GUMGUM]) {
    return [
      {
        bidder: SSP_GUMGUM,
        params: isMobile()
          ? {
              zone: tenantData.GUMGUM_CONFIG.zone,
              slot: tenantData.GUMGUM_CONFIG.slot,
            }
          : {
              zone: tenantData.GUMGUM_CONFIG.zone,
              product: 'skins',
            },
      },
    ];
  }

  return [];
};

export default buildSSPGumGumBidConfig;
