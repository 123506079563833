const CATEGORY_IDS = [
  '0',
  '1',
  '31',
  '48',
  '91',
  '167',
  '201',
  '239',
  '289',
  '322',
  '356',
  '378',
  '395',
  '428',
  '445',
  '504',
  '537',
  '565',
  '621',
  '678',
  '728',
  '784',
  '820',
  '856',
  '895',
  '976',
  '1032',
  '1085',
  '1098',
  '1099',
  '1744',
  '1776',
  '1784',
  '1826',
  '1847',
  '1984',
  '2600',
];

export default CATEGORY_IDS;
