import debugMessage from '../../../tools/debugMessage';
import logV6ExperimentMetric from '../../../tools/logV6ExperimentMetric';
import SLOT_EVENTS from '../constants/SLOT_EVENTS';

/**
 * Event listener that is fired when an ad has been
 * requested for a particular slot.
 *
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotrequestedevent
 *
 * @param event {{ slot: {} }} see https://developers.google.com/publisher-tag/reference#googletag.slot
 *  for slot definition
 */
const slotRequestedEventListener = (event: googletag.events.SlotRequestedEvent): void => {
  /**
   * Get targeting will return an array and not a string
   */
  const position = event.slot.getTargeting('pos')?.slice()?.pop();
  const slotId = event.slot.getSlotElementId();

  if (!position) {
    return;
  }

  debugMessage(position, `Slot ${slotId} has been requested.`, event);

  logV6ExperimentMetric(position, SLOT_EVENTS.SLOT_REQUESTED);
};

export default slotRequestedEventListener;
