/**
 * Injects a script that will load if a user has not installed an
 * ad blocker and will not load if a user has installed an ad blocker.
 * It uses the `onload` and `onerror` global event handlers to assess
 * the status.
 *
 * @param adblockerFile
 */
const injectAdBlockerDetectionScript = (adblockerFile: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');

    scriptTag.type = 'text/javascript';
    scriptTag.src = `${adblockerFile}`;
    scriptTag.async = true;
    scriptTag.onload = () => {
      resolve();
    };
    scriptTag.onerror = () => {
      reject();
    };

    document.body.appendChild(scriptTag);
  });
};

export default injectAdBlockerDetectionScript;
