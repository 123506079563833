type TTrackGaEventArgs = {
  eventLabel: string;
  eventAction: string;
};

const trackGaEvent = (args: TTrackGaEventArgs): void => {
  window?.ecGa?.('trackEvent', args);
};

export default trackGaEvent;
