import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';

const { SSP_IMPROVE } = SSP_PROVIDERS;

const buildSSpImproveBidConfig = ({ placementIdObject, bannerParametersFormatted }): TSSPImproveBidConfig[] => {
  /**
   * https://docs.prebid.org/dev-docs/bidders/improvedigital.html
   */
  if (placementIdObject[SSP_IMPROVE]) {
    return [
      {
        bidder: SSP_IMPROVE,
        params: {
          placementId: parseInt(placementIdObject[SSP_IMPROVE], 10),
          singleRequest: false,
          keyValues: Object.assign(bannerParametersFormatted, {
            kv: ['hb'],
            hb: ['1'],
          }),
        },
      },
    ];
  }
  return [];
};

export default buildSSpImproveBidConfig;
