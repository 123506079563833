/**
 * Use this configuration when a bid adapter is an
 * entity (such as a reseller) that requires an SChain node
 * https://docs.prebid.org/dev-docs/modules/schain.html#how-to-use-the-module
 */
const setupSupplyChainModule = (pbjs: any, { bidder, asi, sid }: { bidder: string; asi: string; sid: string }) => {
  pbjs.setBidderConfig(
    {
      bidders: [bidder], // can list more bidders here if they share the same config
      config: {
        schain: {
          config: {
            ver: '1.0',
            complete: 1,
            nodes: [
              {
                asi,
                sid,
                hp: 1,
              },
            ],
          },
        },
      },
    },
    true,
  );
};

export default setupSupplyChainModule;
