import SSP_PROVIDERS from '../v6/constants/SSP_PROVIDERS';

const locales: {
  PLACEMENT_IDS: TPlacementIds;
  SSP_PROVIDERS: SSP_PROVIDERS[];
  RUBICON_CONFIG: TRubiconConfig;
  ADHESE_CONFIG?: TAdheseConfig;
  APP_NEXUS_WEBORAMA_CONFIG?: TAppNexusWeboramaConfig;
  BID_ADJUSTMENTS_CONFIG?: TBidAdjustmentsConfig;
  GUMGUM_CONFIG: TGumGumConfig;
} = {
  PLACEMENT_IDS: {},
  SSP_PROVIDERS: [],
  RUBICON_CONFIG: {
    accountId: '',
    siteId: '',
    referrer: '',
  },
  GUMGUM_CONFIG: {
    zone: '',
    slot: undefined,
  },
};

export default locales;
