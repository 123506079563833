import tenantData from './locales';

import placementIds from './locales/placementIds.mp.nlnl';
import Ssp from './locales/Ssp.mp.nlnl';
import RubiconConfig from './locales/rubiconConfig.mp.nlnl';
import AdheseConfig from './locales/adheseConfig.mp.nlnl';
import AppNexusWeboramaConfig from './locales/appNexusWeboramaConfig.mp.nlnl';
import BidAdjustmentsConfig from './locales/bidAdjustments.mp.nlnl';
import GumGumConfig from './locales/gumGumConfig.mp.nlnl';
import setup from './v6/setup';

tenantData.PLACEMENT_IDS = placementIds;
tenantData.SSP_PROVIDERS = Ssp;
tenantData.RUBICON_CONFIG = RubiconConfig;
tenantData.ADHESE_CONFIG = AdheseConfig;
tenantData.APP_NEXUS_WEBORAMA_CONFIG = AppNexusWeboramaConfig;
tenantData.BID_ADJUSTMENTS_CONFIG = BidAdjustmentsConfig;
tenantData.GUMGUM_CONFIG = GumGumConfig;

setup();
