/**
 * Slot lifecycle events
 *
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.event
 * @type {{
 *   SLOT_RENDER_ENDED: string,
 *   SLOT_VISIBILITY_CHANGED: string,
 *   SLOT_ONLOAD: string,
 *   IMPRESSION_VIEWABLE: string,
 *   SLOT_REQUESTED: string,
 *   SLOT_RESPONSE_RECIEVED: string
 * }}
 */
const SLOT_EVENTS = {
  /**
   * This event is fired when an impression becomes viewable,
   * according to the Active View criteria.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.impressionviewableevent
   */
  IMPRESSION_VIEWABLE: 'impressionViewable',
  /**
   * This event is fired when the creative's iframe fires its load event.
   * When rendering rich media ads in sync rendering mode, no iframe
   * is used so no SlotOnloadEvent will be fired.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotonloadevent
   */
  SLOT_ONLOAD: 'slotOnload',
  /**
   * This event is fired when the creative code is injected into a slot.
   * This event will occur before the creative's resources are fetched,
   * so the creative may not be visible yet.
   *
   * If you need to know when all creative resources for a slot have
   * finished loading, consider the SlotOnloadEvent instead.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotrenderendedevent
   */
  SLOT_RENDER_ENDED: 'slotRenderEnded',
  /**
   * This event is fired when an ad has been requested for a particular slot.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotrequestedevent
   */
  SLOT_REQUESTED: 'slotRequested',
  /**
   * This event is fired when an ad response has been received for a particular slot.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotresponsereceived
   */
  SLOT_RESPONSE_RECIEVED: 'slotResponseReceived',
  /**
   * This event is fired whenever the on-screen percentage of an ad slot's area changes.
   * The event is throttled and will not fire more often than once every 200ms.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotvisibilitychangedevent
   */
  SLOT_VISIBILITY_CHANGED: 'slotVisibilityChanged',
  /**
   * This is a custom event that we use inside the slotRenderEndedEventListener.
   * We use it to track whether a position rendered empty in Grafana or not.
   */
  SLOT_RENDER_ENDED_EMPTY: 'slotRenderEndedEmpty',
  /**
   * This is a custom event that we use inside the slotRenderEndedEventListener.
   * We use it to track whether a position rendered with an ad in Grafana or not.
   */
  SLOT_RENDER_ENDED_WITH_AD: 'slotRenderEndedWithAd',
};

export default SLOT_EVENTS;
