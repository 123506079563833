import isBrowserViewportTooLarge from '../helpers/isBrowserViewportTooLarge';
import deleteSlotAndHideElement from './deleteSlotAndHideElement';
import debugMessage from '../tools/debugMessage';

/**
 * If the browser viewport width is larger than the maximum
 * viewport size set for the ad slot, we will not show the ad
 * slot because the ad slot will be too small for the screensize
 * and not display properly.
 */
const isAdSlotSmallerThanViewportWidth = (position: string, adSlot: TBannerConfig): boolean => {
  if (adSlot.maximumViewportSize && isBrowserViewportTooLarge(adSlot.maximumViewportSize[0])) {
    deleteSlotAndHideElement(adSlot);

    debugMessage(position, `Max Viewport Size Exceeded, set 'adSlot.delete = true' and hide '${adSlot.hideEl}'`);

    return true;
  }

  return false;
};

export default isAdSlotSmallerThanViewportWidth;
