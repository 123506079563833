import getConfig from './getConfig';
import D from '../globals/Document';

/**
 * Malvertising (a portmanteau of "malicious software (malware) advertising") is the use of online advertising to
 * spread malware. It typically involves injecting malicious or malware-laden advertisements into legitimate online
 * advertising networks and webpages.
 *
 * Point of contact at time of writing are ad-ops https://eclassifiedsgroup.slack.com/archives/C01J1QZ0ULT or #bnl-issues-display-adsense
 * or @john.wasely
 *
 * For more please see https://wiki.es.ecg.tools/display/Infosec/eCG+Malvertising+Management
 *
 * @returns {`https://cdn.confiant-integrations.net/${string}/gpt_and_prebid/config.js`}
 */
function injectProtectionScript(malvertisingProtectionId) {
  if (D) {
    const malvertisingProtectionScript = `https://cdn.confiant-integrations.net/${malvertisingProtectionId}/gpt_and_prebid/config.js`;
    const scriptTag = D.createElement('script');

    scriptTag.async = true;
    scriptTag.type = 'text/javascript';
    scriptTag.src = malvertisingProtectionScript;

    const head = D.getElementsByTagName('head')[0];
    head.appendChild(scriptTag);
  }
}

export default function initMalvertisingProtection() {
  const config = getConfig();

  if (config.malvertisingProtectionId) {
    injectProtectionScript(config.malvertisingProtectionId);
  }
}
