import { CmpApi } from '@iabtcf/cmpapi';
import W from '../globals/Window';
import { CONFIG, updateCmpApiWithTCString } from '@ecg-marktplaats/gdpr-consent-string';
import enhanceTcfApi from './enhanceTcfApi';
import pbjs from '../globals/pbjs';
import logSentryError from '../v6/metrics/sentry/logSentryError';

W.debugBids = function () {
  (function () {
    const responses = pbjs.getBidResponses();
    const winners = pbjs.getAllWinningBids();
    const output: Array<any> = [];
    Object.keys(responses).forEach((adUnitCode) => {
      const response = responses[adUnitCode];
      response.bids.forEach((bid) => {
        output.push({
          bid,
          adunit: adUnitCode,
          adId: bid.adId,
          bidder: bid.bidder,
          time: bid.timeToRespond,
          cpm: bid.cpm,
          msg: bid.statusMessage,
          // eslint-disable-next-line eqeqeq
          rendered: !!winners.find((winner) => winner.adId == bid.adId),
        });
      });
    });
    if (output.length) {
      if (console.table) {
        console.table(output);
      } else {
        for (let j = 0; j < output.length; j++) {
          console.log(output[j]);
        }
      }
    } else {
      console.warn('No prebid responses');
    }
  })();
};

// This is not a part of prebid, it should have it's own helper
// https://jira.es.ecg.tools/browse/BNL-13064
const loadCmp = () => {
  try {
    W.cmpApi = new CmpApi(CONFIG.CMP_ID, CONFIG.CMP_VERSION, true);
    /**
     * Must be invoked after `CmpApi`
     */
    enhanceTcfApi();
    updateCmpApiWithTCString();
    return true;
  } catch (error) {
    logSentryError(error);

    return false;
  }
};

export default loadCmp;
