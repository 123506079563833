import once from 'lodash.once';
import { CONFIG } from '@ecg-marktplaats/gdpr-consent-string';
import W from '../globals/Window';
import ifMinGoogleConsentGranted from './ifMinGoogleConsentGranted';
import pushConsentToDataLayer from './pushConsentToDataLayer';

const noop = () => {};

/**
 * Use this function to register callbacks that are invoked when
 * the TCString has been loaded successfully. Main use case at the
 * moment is to load banners only after the user has given consent.
 *
 * @param cmpLoaded
 * @param callback
 */
const registerCallbackForTCF2 = (cmpLoaded, callback = noop) => {
  if (!cmpLoaded) {
    /**
     * CMP was not loaded successfully so abort loading of banners
     */
    return;
  }

  const onceCallBack = once((tcData, success) => {
    /**
     * __TCF_INIT_PARAMS__ is for debugging purposes only, so we know what
     * data the TCF framework used when invoking the callback.
     * If window.__TCF_INIT_PARAMS__ does not exist, it means the callback
     * was not invoked.
     *
     * @type {{success: *, tcData: *}}
     * @private
     */
    window.__TCF_INIT_PARAMS__ = {
      tcData,
      success,
    };

    callback();
  });

  /**
   * Add an event listener to load the banners only when the user has
   * a valid TCString
   */
  W.__tcfapi('addEventListener', CONFIG.TCF_VERSION, (tcData, success) => {
    /**
     * We do not invoke any callbacks if there isn't minimum google
     * consent given. This means we do not load the banner library at all.
     */
    if (success && ifMinGoogleConsentGranted(tcData)) {
      onceCallBack(tcData, success);

      /**
       * emit custom event trigger to our GTM so we know if it is safe to load certain tags
       */
      if (W.dataLayer) {
        pushConsentToDataLayer(W);
      }

      W.__tcfapi(
        'removeEventListener',
        CONFIG.TCF_VERSION,
        () => {
          /**
           * Remove the event listener for loading banners
           */
        },
        tcData.listenerId,
      );
    }
  });
};

export default registerCallbackForTCF2;
