/**
 * Generates a GUID-like string not a real GUID
 * (it uses Math.random so it must be considered somehow "unsafe")
 *
 * @param count
 * @returns {string}
 */
function generate(count) {
  let out = '';
  for (let i = 0; i < count; i += 1) {
    out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return out;
}

const generateUUID = () => [generate(2), generate(1), generate(1), generate(1), generate(3)].join('-');

export default generateUUID;
