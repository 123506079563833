import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';
import tenantData from '../../locales';

const { SSP_ADHESE } = SSP_PROVIDERS;

const buildSSPAdheseBidConfig = ({
  placementIdObject,
  isConsentPresent,
  position,
  screenSize,
  publisherProvidedId,
}): TSSPAdheseConfig[] => {
  /**
   * https://docs.prebid.org/dev-docs/bidders/adhese.html#sidebar
   */
  if (placementIdObject[SSP_ADHESE]) {
    const userConsentConfig = isConsentPresent ? 'all' : 'none';

    /**
     * @documentation https://docs.prebid.org/dev-docs/pbs-bidders.html#adhese
     */
    return [
      {
        bidder: SSP_ADHESE,
        params: {
          account: tenantData.ADHESE_CONFIG?.account,
          location: placementIdObject[SSP_ADHESE].toString(),
          format: `${position}_${screenSize}`,
          data: {
            tl: [userConsentConfig],
            vp: [screenSize],
            id: [publisherProvidedId],
          },
        },
      },
    ];
  }
  return [];
};

export default buildSSPAdheseBidConfig;
