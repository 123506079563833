import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';

const { SSP_INDEXEXCHANGE } = SSP_PROVIDERS;

const buildSSPIndexExchangeBidConfig = ({ placementIdObject, sizes }): TSSPIndexExchangeConfig[] => {
  /**
   * https://docs.prebid.org/dev-docs/bidders/ix#sidebar
   */
  const result: TSSPIndexExchangeConfig[] = [];

  if (placementIdObject[SSP_INDEXEXCHANGE]) {
    let ixSiteId = placementIdObject[SSP_INDEXEXCHANGE];

    if (Array.isArray(placementIdObject[SSP_INDEXEXCHANGE]) && placementIdObject[SSP_INDEXEXCHANGE][1]) {
      /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
      ixSiteId = placementIdObject[SSP_INDEXEXCHANGE][1];
    }

    (sizes as unknown as TSizeDefinition[]).forEach((size) => {
      result.push({
        bidder: SSP_INDEXEXCHANGE,
        params: {
          siteId: ixSiteId.toString(),
          size,
        },
      });
    });
  }
  return result;
};

export default buildSSPIndexExchangeBidConfig;
