import trackGaEvent from '../v6/helpers/trackGaEvent';

/**
 * Dispatches a banner-lib-loaded event so we know that we can
 * start using the exposed global functions
 */
const dispatchLoadedEvent = () => {
  if (window.dispatchEvent && window.CustomEvent) {
    window.dispatchEvent(new CustomEvent('banner-lib-loaded'));
    trackGaEvent({ eventLabel: 'ecgJBannersLibrary', eventAction: 'loaded' });
  }
};

export default dispatchLoadedEvent;
