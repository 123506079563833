import W from '../globals/Window';
import googleTag from '../globals/googletag';
import displayBanners from './displayBanners';
import eventLogger from './eventLogger';
import { setSlotReference, getSlotReference, clearSlotReferences } from './slotReferences';
import isDOMLoaded from '../helpers/isDOMLoaded';
import gptPrebid from './gptPrebid';
import getConfig from './getConfig';
// eslint-disable-next-line import/no-cycle
import trackContainerGetsInView from './trackContainerGetsInView';
import defineSlotsAdapter from './adaptersForRefactoring/defineSlotsAdapter';
import disableInitialLoadAdapter from './adaptersForRefactoring/disableInitialLoadAdapter';
import setupAdSlotsAdapter from './adaptersForRefactoring/setupAdSlotsAdapter';
import resetAllBannersInPageAdapter from './adaptersForRefactoring/resetAllBannersInPageAdapter';
import refreshBidsAdapter from './adaptersForRefactoring/refreshBidsAdapter';
import setupAdditionalBannersAdapter from './adaptersForRefactoring/setupAdditionalBannersAdapter';
import setupPageWithBannersAdapter from './adaptersForRefactoring/setupPageWithBannersAdapter';

/**
 * Setting to global for debugging purposes
 * @type {{data: undefined}}
 */
window.allAdSlots = {
  data: undefined,
};
const { allAdSlots } = W;

const checkConsentedStatusSourcepoint = () => {
  const { _sp_lib } = W;
  const { getConsentState } = _sp_lib;
  const consentState = getConsentState(document.cookie);
  const isAllConsentAccepted =
    Object.keys(consentState).length > 1 && Object.values(consentState).every((item) => item === true);
  const isOnlyGoogleAnalyticsPurposeAccepted =
    Object.keys(consentState).length === 1 && Object.keys(consentState).includes('GoogleAnalytics');
  const isAllConsentRegected = Object.keys(consentState).length === 0 || isOnlyGoogleAnalyticsPurposeAccepted;

  if (isAllConsentAccepted) {
    return 1;
  }
  if (isAllConsentRegected) {
    return 0;
  }
};

const config = getConfig();
const defineSlots = defineSlotsAdapter({
  config,
  googleTag,
  setSlotReference,
  isDOMLoaded,
  displayBanners,
  checkConsentedStatusSourcepoint,
});
const disableInitialLoad = disableInitialLoadAdapter({
  W,
  googleTag,
});
const setupAdSlots = setupAdSlotsAdapter({
  gptPrebid,
  trackContainerGetsInView,
  defineSlots,
  allAdSlots,
});
const setupPageWithBanners = setupPageWithBannersAdapter({
  allAdSlots,
  clearSlotReferences,
  disableInitialLoad,
  gptPrebid,
  setupAdSlots,
});

export const setupAdditionalBanners = setupAdditionalBannersAdapter({
  getSlotReference,
  allAdSlots,
  setupAdSlots,
});

export const refreshBids = refreshBidsAdapter({
  allAdSlots,
  gptPrebid,
});

export const resetAllBannersInPage = resetAllBannersInPageAdapter({
  googleTag,
  eventLogger,
  setupPageWithBanners,
});

export default setupPageWithBanners;
