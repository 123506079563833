import deleteSlotAndHideElement from './deleteSlotAndHideElement';
import debugMessage from '../tools/debugMessage';
import isBrowserViewportTooSmall from '../helpers/isBrowserViewportTooSmall';

/**
 * If the browser viewport width is smaller than the minimal
 * viewport size set for the ad slot, we will not show the ad
 * slot because the ad slot will be too big for the screensize
 * and not display properly.
 */
const isAdSlotBiggerThanViewportWidth = (position: string, adSlot: TBannerConfig): boolean => {
  if (adSlot.minimalViewportSize && isBrowserViewportTooSmall(adSlot.minimalViewportSize[0])) {
    deleteSlotAndHideElement(adSlot);

    debugMessage(position, `Min Viewport Size Not Met, set 'adSlot.delete = true' and hide '${adSlot.hideEl}'`);

    return true;
  }

  return false;
};

export default isAdSlotBiggerThanViewportWidth;
