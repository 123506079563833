/* eslint-disable import/prefer-default-export */
import { MetricsClient } from '@ecg-marktplaats/ecg-js-metrics';
import getConfig from '../../requestAds/getConfig';
import debugMessage from '../tools/debugMessage';

const config = getConfig();

let metricsClient: MetricsClient;

const getMetricsClient = () => {
  if (!metricsClient) {
    if (config.gptBatchMetricsEndpointUrl && config.vurl !== undefined) {
      metricsClient = new MetricsClient({
        endpointUrl: config.gptBatchMetricsEndpointUrl,
        virtualUrl: config.vurl,
        metricsSuffix: config.grafanaSuffix,
      });
    } else {
      debugMessage(DEBUG_NAMESPACE, 'Banner metrics client is not configured');
    }
  }
  return metricsClient;
};

export const addPerformanceMetric: typeof metricsClient.addPerformanceMetric = (...params) => {
  return getMetricsClient()?.addPerformanceMetric(...params);
};

export const addMetrics: typeof metricsClient.addMetrics = (...params) => {
  return getMetricsClient()?.addMetrics(...params);
};
