import setImpressionsCount from '../../requestAds/setImpressionsCount';
import getConfig from '../../requestAds/getConfig';
import debugMessage from '../tools/debugMessage';
import cloneData from '../tools/cloneData';
import setAdSlotProperty from './setAdSlotProperty';
import doesSlotReferenceExist from './doesSlotReferenceExist';
import isUserUsingAPWA from './isUserUsingAPWA';
import isAdSlotSmallerThanViewportWidth from './isAdSlotSmallerThanViewportWidth';
import isAdSlotBiggerThanViewportWidth from './isAdSlotBiggerThanViewportWidth';

export const buildAdSlotConfigV6 = (): Array<TBannerConfig> => {
  const { releaseId, bannerData } = getConfig(true);
  /**
   * Add extra bannerParameters.
   */
  const transformedSlots = Object.entries<TBannerConfig>(bannerData).reduce<Array<TBannerConfig>>(
    (accumulator, [position, adSlot]) => {
      debugMessage(position, 'Creating AdSlot using', cloneData(adSlot));

      if (
        doesSlotReferenceExist(position, adSlot) ||
        isUserUsingAPWA(position, adSlot) ||
        isAdSlotSmallerThanViewportWidth(position, adSlot) ||
        isAdSlotBiggerThanViewportWidth(position, adSlot)
      ) {
        return accumulator;
      }

      /**
       * this param is used by The demand and adops team to target campaigns.
       * They have also shared this key/value with partners who sometimes use it in their creative code.
       */
      setAdSlotProperty(adSlot, 'position', adSlot.bannerParameters?.pos);

      /**
       * Impression count. This is stored on the client side so it can only be set on the client side.
       */
      setAdSlotProperty(adSlot, 'bannerParameters.sf', setImpressionsCount());

      /**
       * this param is used by The demand and adops team to target campaigns.
       * They have also shared this key/value with partners who sometimes use it in their creative code.
       */
      if (releaseId) {
        setAdSlotProperty(adSlot, 'bannerParameters.release', releaseId);
      }

      accumulator.push(adSlot);

      return accumulator;
    },
    [],
  );

  debugMessage('buildAdSlotConfigV6', 'transformedSlots', cloneData(transformedSlots));

  return transformedSlots;
};

export default buildAdSlotConfigV6;
