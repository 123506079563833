import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';

const { SSP_APPNEXUS } = SSP_PROVIDERS;

const buildSSPAppNexusBidConfig = ({ placementIdObject, bannerParametersFormatted }): TSSPAppNexusBidConfig[] => {
  /**
   * https://docs.prebid.org/dev-docs/bidders/appnexus.html#sidebar
   */
  if (placementIdObject[SSP_APPNEXUS]) {
    const appnexusConfig = {
      keywords: bannerParametersFormatted,
      placementId: placementIdObject[SSP_APPNEXUS],
    };

    return [
      {
        bidder: SSP_APPNEXUS,
        params: appnexusConfig,
      },
    ];
  }
  return [];
};

export default buildSSPAppNexusBidConfig;
