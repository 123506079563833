import cmpstub from '@iabtcf/stub';

import getConfig from '../../../requestAds/getConfig';
import createTcfApiConsentListener from '../../../helpers/createTcfApiConsentListener';
import loadCmp from '../../../helpers/loadCmp';
import registerCallbackForTCF2 from '../../../helpers/registerCallBackForTCF2';

const checkConsent = (): Promise<any> => {
  return new Promise((resolve) => {
    const config = getConfig();

    if (config.experiments.useSourcePointCMP) {
      createTcfApiConsentListener(resolve);
      return;
    }

    /**
     * cmpstub must be invoked first so that loadCmp.js can queue
     * their calls in the `__tcfapi` stub. Once the `CmpApi` is ready
     * the `__tcfapi` stub is replaced with the `__tcfapi` function and all
     * calls queued in the `__tcfapi` stub are processed in a FIFO order.
     */
    cmpstub();

    const cmpLoaded = loadCmp();

    /**
     * The callback is only invoked when the CMP has been loaded
     */
    registerCallbackForTCF2(cmpLoaded, resolve as () => void);
  });
};

export default checkConsent;
