import D from '../globals/Document';

export default new Promise<void>((resolve) => {
  if (D && D.readyState === 'loading') {
    D.addEventListener('DOMContentLoaded', () => {
      resolve();
    });
  } else {
    resolve();
  }
});
