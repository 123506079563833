const placementIds: TPlacementIds = {
  advertenties_top: {
    default: {
      improvedigital: ['980844'],
      rubicon: ['711462'],
      appnexus: ['12258820'],
      tmp: [],
      criteo: {
        '728x90': 1250889,
      },
      ix: [['1', '231298']],
    },
    mobile: {
      improvedigital: [],
    },
  },
  l1_top: {
    default: {
      improvedigital: [
        null,
        '589555',
        '589557',
        '589561',
        '589567',
        '589631',
        '589571',
        '589597',
        '589583',
        '589587',
        '589621',
        '589589',
        '589593',
        '589595',
        '589599',
        '540507',
        '589639',
        '589607',
        '589609',
        '589613',
        '589615',
        '589623',
        '589625',
        '589633',
        '589635',
        '589637',
        '589605',
        '589641',
        '589591',
        '589601',
        '589585',
        '589611',
        '589617',
        '589619',
        '589629',
        '589627',
        '589565',
      ],
      rubicon: [
        null,
        '709990',
        '710016',
        '710038',
        '710116',
        '711888',
        '710140',
        '711130',
        '710854',
        '710964',
        '711672',
        '710998',
        '711048',
        '711092',
        '711156',
        '711314',
        '711644',
        '711366',
        '711404',
        '711500',
        '711530',
        '711792',
        '711816',
        '711750',
        '711718',
        '711678',
        '711338',
        '711614',
        '994082',
        '711186',
        '710906',
        '711458',
        '711554',
        '711576',
        '711866',
        '711844',
        '710060',
      ],
      appnexus: [
        null,
        '12261257',
        '12261269',
        '12261280',
        '12261316',
        '12261742',
        '12261334',
        '12261415',
        '12261345',
        '12261367',
        '12261686',
        '12261381',
        '12261392',
        '12261404',
        '12261470',
        '12261495',
        '12261810',
        '12261595',
        '12261611',
        '12261638',
        '12261649',
        '12261697',
        '12261708',
        '12261755',
        '12261766',
        '12261799',
        '12261579',
        '12261821',
        '13566026',
        '12261483',
        '12261356',
        '12261626',
        '12261660',
        '12261671',
        '12261731',
        '12261719',
        '12261297',
      ],
      appnexus_weborama: [
        '29675659',
        '29675664',
        '29675669',
        '29675674',
        '29675679',
        '29675684',
        '29675689',
        '29675694',
        '29675699',
        '29675704',
        '29675709',
        '29675714',
        '29675719',
        '29675724',
        '29675734',
        '29675739',
        '29675744',
        '29675749',
        '29675754',
        '29675759',
        '29675764',
        '29675769',
        '29675774',
        '29675779',
        '29675784',
        '29675789',
        '29675794',
        '29675800',
        '29675806',
        '29675813',
        '29675819',
        '29675825',
        '29675831',
        '29675837',
        '29675842',
        '29675848',
      ],
      gumgum: 1, // this is to enable gumgum config for this position
      tmp: [
        null,
        null,
        null,
        null,
        null,
        null,
        '13373003',
        '13373139',
        null,
        null,
        '13373367',
        '13373084',
        null,
        '13373121',
        null,
        null,
        '13373554',
        '13373236',
        '13373255',
        null,
        '13373310',
        '13373386',
        null,
        null,
        null,
        null,
        '13373217',
        null,
        null,
        '13373176',
        null,
        '13373273',
        null,
        '13373347',
        null,
        null,
        '13372960',
      ],
      criteo: {
        '970x250': 1250891,
        '728x90': 1250889,
      },
      ix: [
        null,
        ['5', '234287'],
        ['16', '234298'],
        ['27', '234309'],
        ['49', '234331'],
        ['324', '234606'],
        ['60', '234342'],
        ['137', '234419'],
        ['71', '234353'],
        ['93', '234375'],
        ['269', '234551'],
        ['104', '234386'],
        ['115', '234397'],
        ['126', '234408'],
        ['148', '234430'],
        ['170', '234452'],
        ['368', '234650'],
        ['192', '234474'],
        ['203', '234485'],
        ['225', '234507'],
        ['236', '234518'],
        ['280', '234562'],
        ['291', '234573'],
        ['335', '234617'],
        ['346', '234628'],
        ['357', '234639'],
        ['181', '234463'],
        ['379', '234661'],
        [null, '282102'],
        ['159', '234441'],
        ['82', '234364'],
        ['214', '234496'],
        ['247', '234529'],
        ['258', '234540'],
        ['313', '234595'],
        ['302', '234584'],
        ['38', '234320'],
      ],
      adhese: [
        '_marktplaats.nl_web_home_',
        '_marktplaats.nl_web_antiek_en_kunst_',
        '_marktplaats.nl_web_audio_tv_en_foto_',
        '_marktplaats.nl_web_auto_diversen_',
        '_marktplaats.nl_web_autos_',
        '_marktplaats.nl_web_vacatures_',
        '_marktplaats.nl_web_boeken_',
        '_marktplaats.nl_web_doe_het_zelf_en_verbouw_',
        '_marktplaats.nl_web_caravans_en_kamperen_',
        '_marktplaats.nl_web_computers_en_software_',
        '_marktplaats.nl_web_spelcomputers_games_',
        '_marktplaats.nl_web_contacten_en_berichten_',
        '_marktplaats.nl_web_dieren_en_toebehoren_',
        '_marktplaats.nl_web_diversen_',
        '_marktplaats.nl_web_fietsen_en_brommers_',
        '_marktplaats.nl_web_huis_en_inrichting_',
        '_marktplaats.nl_web_witgoed_en_apparatuur_',
        '_marktplaats.nl_web_kinderen_en_babys_',
        '_marktplaats.nl_web_kleding_dames_',
        '_marktplaats.nl_web_motoren_',
        '_marktplaats.nl_web_muziek_en_instrumenten_',
        '_marktplaats.nl_web_sport_en_fitness_',
        '_marktplaats.nl_web_telecommunicatie_',
        '_marktplaats.nl_web_vakantie_',
        '_marktplaats.nl_web_verzamelen_',
        '_marktplaats.nl_web_watersport_en_boten_',
        '_marktplaats.nl_web_huizen_en_kamers_',
        '_marktplaats.nl_web_zakelijke_goederen_',
        '_marktplaats.nl_web_diensten_en_vakmensen_',
        '_marktplaats.nl_web_hobby_en_vrije_tijd_',
        '_marktplaats.nl_web_cds_en_dvds_',
        '_marktplaats.nl_web_kleding_heren_',
        '_marktplaats.nl_web_postzegels_en_munten_',
        '_marktplaats.nl_web_sieraden_en_tassen_',
        '_marktplaats.nl_web_tuin_en_terras_',
        '_marktplaats.nl_web_tickets_en_kaartjes_',
        '_marktplaats.nl_web_auto_onderdelen_',
      ],
    },
    mobile: {
      improvedigital: [
        null,
        '1067376',
        '1067379',
        '1067382',
        '1067385',
        '1067466',
        '1067391',
        '1067415',
        '1067394',
        '1067400',
        '1067451',
        '1067403',
        '1067409',
        '1067412',
        '1067418',
        '1067424',
        '1067478',
        '1067488',
        '1067433',
        '1067439',
        '1067442',
        '1067454',
        '1067457',
        '1067469',
        '1067472',
        '1067475',
        '1067427',
        '1067481',
        null,
        '1067421',
        '1067485',
        '1067436',
        '1067445',
        '1067448',
        '1067463',
        '1067460',
        '1067388',
      ],
    },
  },
  l1_left: {
    default: {
      improvedigital: [
        null,
        '9875',
        '9947',
        '9945',
        '535619',
        '9887',
        '9939',
        '9923',
        '9937',
        '9933',
        '9897',
        '9931',
        '9927',
        '9925',
        '9921',
        '9915',
        '9879',
        '9911',
        '9909',
        '9905',
        '9903',
        '9895',
        '9893',
        '535701',
        '9883',
        '9881',
        '9913',
        '9877',
        '535641',
        '9919',
        '9935',
        '9907',
        '9901',
        '9899',
        '9889',
        '9891',
        '9943',
      ],
      rubicon: [
        null,
        '709974',
        '710000',
        '710022',
        '710066',
        '711874',
        '710124',
        '711098',
        '710836',
        '710926',
        '711578',
        '710978',
        '711024',
        '711058',
        '711138',
        '711196',
        '711664',
        '711344',
        '711460',
        '711470',
        '711506',
        '711754',
        '711798',
        '732434',
        '711742',
        '711700',
        '711320',
        '711636',
        '994086',
        '711166',
        '710882',
        '711426',
        '711536',
        '711560',
        '711850',
        '711826',
        '710044',
      ],
      appnexus: [
        null,
        '12261258',
        '12261270',
        '12261281',
        '12261317',
        '12261744',
        '12261335',
        '12261416',
        '12261346',
        '12261369',
        '12261687',
        '12261382',
        '12261393',
        '12261405',
        '12261472',
        '12261496',
        '12261811',
        '12261599',
        '12261613',
        '12261639',
        '12261650',
        '12261698',
        '12261709',
        '12261756',
        '12261767',
        '12261800',
        '12261580',
        '12261822',
        '13566027',
        '12261484',
        '12261357',
        '12261627',
        '12261661',
        '12261672',
        '12261732',
        '12261720',
        '12261306',
      ],
      appnexus_weborama: [
        '29675651',
        '29675661',
        '29675666',
        '29675671',
        '29675676',
        '29675681',
        '29675686',
        '29675691',
        '29675696',
        '29675701',
        '29675706',
        '29675711',
        '29675716',
        '29675721',
        '29675731',
        '29675736',
        '29675741',
        '29675746',
        '29675751',
        '29675756',
        '29675761',
        '29675766',
        '29675771',
        '29675776',
        '29675781',
        '29675786',
        '29675791',
        '29675796',
        '29675803',
        '29675809',
        '29675815',
        '29675822',
        '29675828',
        '29675834',
        '29675839',
        '29675845',
      ],
      tmp: [
        null,
        null,
        null,
        '13372935',
        null,
        null,
        null,
        '13373136',
        '13373020',
        null,
        null,
        '13373081',
        '13373100',
        '13373118',
        '13373155',
        '13373194',
        null,
        null,
        null,
        '13373289',
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        '13373344',
        '13373439',
        '13373420',
        '13372957',
      ],
      criteo: {
        '300x250': 1250884,
      },
      ix: [
        null,
        ['6', '234288'],
        ['17', '234299'],
        ['28', '234310'],
        ['50', '234332'],
        ['325', '234607'],
        ['61', '234343'],
        ['138', '234420'],
        ['72', '234354'],
        ['94', '234376'],
        ['270', '234552'],
        ['105', '234387'],
        ['116', '234398'],
        ['127', '234409'],
        ['149', '234431'],
        ['171', '234453'],
        ['369', '234651'],
        ['193', '234475'],
        ['204', '234486'],
        ['226', '234508'],
        ['237', '234519'],
        ['281', '234563'],
        ['292', '234574'],
        ['336', '234618'],
        ['347', '234629'],
        ['358', '234640'],
        ['182', '234464'],
        ['380', '234662'],
        [null, '282103'],
        ['160', '234442'],
        ['83', '234365'],
        ['215', '234497'],
        ['248', '234530'],
        ['259', '234541'],
        ['314', '234596'],
        ['303', '234585'],
        ['39', '234321'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  l1_right: {
    default: {
      improvedigital: [
        null,
        '1147955',
        '1147751',
        '1147752',
        '1147781',
        '1147785',
        '1147753',
        '1147760',
        '1147754',
        '1147756',
        '1147771',
        '1147757',
        '1147758',
        '1147759',
        '1147761',
        '1147763',
        '1147779',
        '1147764',
        '1147765',
        '1147767',
        '1147768',
        '1147772',
        '1147773',
        '1147776',
        '1147777',
        '1147778',
        '1147784',
        '1147786',
        '1147782',
        '1147762',
        '1147755',
        '1147766',
        '1147769',
        '1147770',
        '1147775',
        '1147774',
        '1147780',
      ],
      rubicon: [
        null,
        '908924',
        '908926',
        '908928',
        '908932',
        '909128',
        '908934',
        '909086',
        '908936',
        '908940',
        '909110',
        '908942',
        '908946',
        '909084',
        '909088',
        '909092',
        '909114',
        '909096',
        '909100',
        '909102',
        '909104',
        '909120',
        '909122',
        '917622',
        '909118',
        '909116',
        '909094',
        '909112',
        '994088',
        '909090',
        '908938',
        '909098',
        '909106',
        '909108',
        '909126',
        '909124',
        '908930',
      ],
      appnexus: [
        null,
        '13060291',
        '13060294',
        '13060297',
        '13060313',
        '13060397',
        '13060316',
        '13060337',
        '13060319',
        '13060325',
        '13060382',
        '13060328',
        '13060331',
        '13060334',
        '13060340',
        '13060346',
        '13060410',
        '13060356',
        '13060359',
        '13060365',
        '13060368',
        '13060385',
        '13060388',
        '13060400',
        '13060403',
        '13060406',
        '13060353',
        '13060413',
        '13566029',
        '13060343',
        '13060322',
        '13060362',
        '13060372',
        '13060379',
        '13060394',
        '13060391',
        '13060310',
      ],
      appnexus_weborama: ['29675738', '29675743', '29675748', '29675778', '29675830'],
      tmp: [
        null,
        '13372893',
        '13372917',
        '13372936',
        '13372981',
        '13373463',
        '13373002',
        '13373138',
        '13373022',
        '13373062',
        '13373365',
        '13373083',
        '13373101',
        '13373119',
        '13373156',
        '13373196',
        '13373553',
        '13373235',
        '13373254',
        '13373290',
        '13373308',
        '13373385',
        '13373403',
        '13373490',
        '13373515',
        '13373533',
        '13373216',
        '13373571',
        '13373600',
        '13373175',
        '13373043',
        '13373272',
        '13373327',
        '13373346',
        '13373440',
        '13373421',
        '13372958',
      ],
      criteo: {
        '300x250': 1250905,
      },
      ix: [
        null,
        ['466', '260897'],
        ['467', '260898'],
        ['468', '260899'],
        ['470', '260901'],
        ['495', '260926'],
        ['471', '260902'],
        ['478', '260909'],
        ['472', '260903'],
        ['474', '260905'],
        ['490', '260921'],
        ['475', '260906'],
        ['476', '260907'],
        ['477', '260908'],
        ['479', '260910'],
        ['481', '260912'],
        ['499', '260930'],
        ['483', '260914'],
        ['484', '260915'],
        ['486', '260917'],
        ['487', '260918'],
        ['491', '260922'],
        ['492', '260923'],
        ['496', '260927'],
        ['497', '260928'],
        ['498', '260929'],
        ['482', '260913'],
        ['500', '260931'],
        [null, '282113'],
        ['480', '260911'],
        ['473', '260904'],
        ['485', '260916'],
        ['488', '260919'],
        ['489', '260920'],
        ['494', '260925'],
        ['493', '260924'],
        ['469', '260900'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  lrp_top: {
    default: {
      improvedigital: [
        '4680',
        '1248',
        '1250',
        '1252',
        '1310',
        '1318',
        '1254',
        '1268',
        '1256',
        '1260',
        '1290',
        '1262',
        '1264',
        '1266',
        '1270',
        '1274',
        '1306',
        '1276',
        '1278',
        '1282',
        '1284',
        '1292',
        '1294',
        '1300',
        '1302',
        '1304',
        '1316',
        '1320',
        '1312',
        '1272',
        '1258',
        '1280',
        '1286',
        '1288',
        '1298',
        '1296',
        '1308',
      ],
      rubicon: [
        '711608',
        '709988',
        '710014',
        '710036',
        '710112',
        '711886',
        '710140',
        '711128',
        '710852',
        '710960',
        '711660',
        '710996',
        '711044',
        '711090',
        '711154',
        '711312',
        '711646',
        '711364',
        '711402',
        '711496',
        '711528',
        '711790',
        '711814',
        '711756',
        '711720',
        '711680',
        '711332',
        '711616',
        '711016',
        '711184',
        '710902',
        '711456',
        '711550',
        '711574',
        '711864',
        '711842',
        '710058',
      ],
      appnexus: [
        '12261832',
        '12261259',
        '12261271',
        '12261282',
        '12261318',
        '12261745',
        '12261336',
        '12261417',
        '12261347',
        '12261370',
        '12261688',
        '12261383',
        '12261394',
        '12261406',
        '12261473',
        '12261497',
        '12261812',
        '12261600',
        '12261615',
        '12261640',
        '12261651',
        '12261699',
        '12261710',
        '12261757',
        '12261768',
        '12261801',
        '12261581',
        '12261823',
        '13566030',
        '12261485',
        '12261358',
        '12261628',
        '12261662',
        '12261673',
        '12261733',
        '12261721',
        '12261307',
      ],
      appnexus_weborama: [
        '29675659',
        '29675664',
        '29675669',
        '29675674',
        '29675679',
        '29675684',
        '29675689',
        '29675694',
        '29675699',
        '29675704',
        '29675709',
        '29675714',
        '29675719',
        '29675724',
        '29675729',
        '29675734',
        '29675739',
        '29675744',
        '29675749',
        '29675754',
        '29675759',
        '29675764',
        '29675769',
        '29675774',
        '29675779',
        '29675784',
        '29675789',
        '29675794',
        '29675800',
        '29675806',
        '29675813',
        '29675819',
        '29675825',
        '29675831',
        '29675837',
        '29675842',
        '29675848',
      ],
      gumgum: 1, // this is to enable gumgum config for this position
      tmp: [
        '13373597',
        '13372903',
        '13372926',
        '13372948',
        '13372993',
        '13373474',
        '13373012',
        '13373147',
        '13373034',
        '13373073',
        '13373374',
        '13373092',
        '13373110',
        '13373129',
        '13373165',
        '13373205',
        '13373562',
        '13373244',
        '13373263',
        '13373299',
        '13373318',
        '13373394',
        '13373412',
        '13373506',
        '13373524',
        '13373544',
        '13373226',
        '13373582',
        '13373609',
        '13373184',
        '13373053',
        '13373281',
        '13373337',
        '13373355',
        '13373451',
        '13373431',
        '13372970',
      ],
      criteo: {
        '970x250': '1250886',
        '728x90': '1250885',
        '300x250': '1250896',
        '320x50': '1250914',
        '320x100': '1257306',
        '320x240': '1257307',
      },
      ix: [
        ['390', '234672'],
        ['7', '234289'],
        ['18', '234300'],
        ['29', '234311'],
        ['51', '234333'],
        ['326', '234608'],
        ['62', '234344'],
        ['139', '234421'],
        ['73', '234355'],
        ['95', '234377'],
        ['271', '234553'],
        ['106', '234388'],
        ['117', '234399'],
        ['128', '234410'],
        ['150', '234432'],
        ['172', '234454'],
        ['370', '234652'],
        ['194', '234476'],
        ['205', '234487'],
        ['227', '234509'],
        ['238', '234520'],
        ['282', '234564'],
        ['293', '234575'],
        ['337', '234619'],
        ['348', '234630'],
        ['359', '234641'],
        ['183', '234465'],
        ['381', '234663'],
        [null, '282104'],
        ['161', '234443'],
        ['84', '234366'],
        ['216', '234498'],
        ['249', '234531'],
        ['260', '234542'],
        ['315', '234597'],
        ['304', '234586'],
        ['40', '234322'],
      ],
      adhese: [
        '_marktplaats.nl_web_home_',
        '_marktplaats.nl_web_antiek_en_kunst_',
        '_marktplaats.nl_web_audio_tv_en_foto_',
        '_marktplaats.nl_web_auto_diversen_',
        '_marktplaats.nl_web_autos_',
        '_marktplaats.nl_web_vacatures_',
        '_marktplaats.nl_web_boeken_',
        '_marktplaats.nl_web_doe_het_zelf_en_verbouw_',
        '_marktplaats.nl_web_caravans_en_kamperen_',
        '_marktplaats.nl_web_computers_en_software_',
        '_marktplaats.nl_web_spelcomputers_games_',
        '_marktplaats.nl_web_contacten_en_berichten_',
        '_marktplaats.nl_web_dieren_en_toebehoren_',
        '_marktplaats.nl_web_diversen_',
        '_marktplaats.nl_web_fietsen_en_brommers_',
        '_marktplaats.nl_web_huis_en_inrichting_',
        '_marktplaats.nl_web_witgoed_en_apparatuur_',
        '_marktplaats.nl_web_kinderen_en_babys_',
        '_marktplaats.nl_web_kleding_dames_',
        '_marktplaats.nl_web_motoren_',
        '_marktplaats.nl_web_muziek_en_instrumenten_',
        '_marktplaats.nl_web_sport_en_fitness_',
        '_marktplaats.nl_web_telecommunicatie_',
        '_marktplaats.nl_web_vakantie_',
        '_marktplaats.nl_web_verzamelen_',
        '_marktplaats.nl_web_watersport_en_boten_',
        '_marktplaats.nl_web_huizen_en_kamers_',
        '_marktplaats.nl_web_zakelijke_goederen_',
        '_marktplaats.nl_web_diensten_en_vakmensen_',
        '_marktplaats.nl_web_hobby_en_vrije_tijd_',
        '_marktplaats.nl_web_cds_en_dvds_',
        '_marktplaats.nl_web_kleding_heren_',
        '_marktplaats.nl_web_postzegels_en_munten_',
        '_marktplaats.nl_web_sieraden_en_tassen_',
        '_marktplaats.nl_web_tuin_en_terras_',
        '_marktplaats.nl_web_tickets_en_kaartjes_',
        '_marktplaats.nl_web_auto_onderdelen_',
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  lrp_bot: {
    default: {
      improvedigital: [
        '842980',
        '724415',
        '724417',
        '724421',
        '724443',
        '724597',
        '724449',
        '724483',
        '724453',
        '724463',
        '724583',
        '724467',
        '724473',
        '724475',
        '724533',
        '724537',
        '724607',
        '724541',
        '724543',
        '724547',
        '724567',
        '724589',
        '724591',
        '724599',
        '724601',
        '724605',
        '724539',
        '724609',
        '724471',
        '724535',
        '724461',
        '724545',
        '72459',
        '724571',
        '724595',
        '724593',
        '724441',
      ],
      rubicon: [
        '711618',
        '709982',
        '710008',
        '710032',
        '710076',
        '711882',
        '710132',
        '711112',
        '710848',
        '710944',
        '711596',
        '710990',
        '711036',
        '711076',
        '711146',
        '711204',
        '711654',
        '711356',
        '711392',
        '711486',
        '711518',
        '711786',
        '711806',
        '711762',
        '711734',
        '711688',
        '711328',
        '711628',
        '994092',
        '711178',
        '710896',
        '711442',
        '711544',
        '711568',
        '711858',
        '711836',
        '710052',
      ],
      appnexus: [
        '12261836',
        '12261263',
        '12261275',
        '12261286',
        '12261322',
        '12261749',
        '12261340',
        '12261421',
        '12261351',
        '12261374',
        '12261692',
        '12261387',
        '12261399',
        '12261410',
        '12261477',
        '12261501',
        '12261816',
        '12261604',
        '12261619',
        '12261644',
        '12261655',
        '12261703',
        '12261714',
        '12261761',
        '12261772',
        '12261805',
        '12261585',
        '12261827',
        '13566063',
        '12261489',
        '12261362',
        '12261632',
        '12261666',
        '12261677',
        '12261737',
        '12261725',
        '12261311',
      ],
      appnexus_weborama: [
        '29675650',
        '29675660',
        '29675665',
        '29675670',
        '29675675',
        '29675680',
        '29675685',
        '29675690',
        '29675695',
        '29675700',
        '29675705',
        '29675710',
        '29675715',
        '29675720',
        '29675730',
        '29675735',
        '29675740',
        '29675745',
        '29675750',
        '29675755',
        '29675760',
        '29675765',
        '29675770',
        '29675775',
        '29675780',
        '29675785',
        '29675790',
        '29675795',
        '29675802',
        '29675808',
        '29675814',
        '29675821',
        '29675827',
        '29675833',
        '29675838',
        '29675844',
      ],
      criteo: {
        '970x250': 1250891,
        '728x90': 1250893,
      },
      ix: [
        ['394', '234676'],
        ['11', '234293'],
        ['22', '234304'],
        ['33', '234315'],
        ['55', '234337'],
        ['330', '234612'],
        ['66', '234348'],
        ['143', '234425'],
        ['77', '234359'],
        ['99', '234381'],
        ['275', '234557'],
        ['110', '234392'],
        ['121', '234403'],
        ['132', '234414'],
        ['154', '234436'],
        ['176', '234458'],
        ['374', '234656'],
        ['198', '234480'],
        ['209', '234491'],
        ['231', '234513'],
        ['242', '234524'],
        ['286', '234568'],
        ['297', '234579'],
        ['341', '234623'],
        ['352', '234634'],
        ['363', '234645'],
        ['187', '234469'],
        ['385', '234667'],
        [null, '282108'],
        ['165', '234447'],
        ['88', '234370'],
        ['220', '234502'],
        ['253', '234535'],
        ['264', '234546'],
        ['319', '234601'],
        ['308', '234590'],
        ['44', '234326'],
      ],
      adhese: [
        '_marktplaats.nl_web_home_',
        '_marktplaats.nl_web_antiek_en_kunst_',
        '_marktplaats.nl_web_audio_tv_en_foto_',
        '_marktplaats.nl_web_auto_diversen_',
        '_marktplaats.nl_web_autos_',
        '_marktplaats.nl_web_vacatures_',
        '_marktplaats.nl_web_boeken_',
        '_marktplaats.nl_web_doe_het_zelf_en_verbouw_',
        '_marktplaats.nl_web_caravans_en_kamperen_',
        '_marktplaats.nl_web_computers_en_software_',
        '_marktplaats.nl_web_spelcomputers_games_',
        '_marktplaats.nl_web_contacten_en_berichten_',
        '_marktplaats.nl_web_dieren_en_toebehoren_',
        '_marktplaats.nl_web_diversen_',
        '_marktplaats.nl_web_fietsen_en_brommers_',
        '_marktplaats.nl_web_huis_en_inrichting_',
        '_marktplaats.nl_web_witgoed_en_apparatuur_',
        '_marktplaats.nl_web_kinderen_en_babys_',
        '_marktplaats.nl_web_kleding_dames_',
        '_marktplaats.nl_web_motoren_',
        '_marktplaats.nl_web_muziek_en_instrumenten_',
        '_marktplaats.nl_web_sport_en_fitness_',
        '_marktplaats.nl_web_telecommunicatie_',
        '_marktplaats.nl_web_vakantie_',
        '_marktplaats.nl_web_verzamelen_',
        '_marktplaats.nl_web_watersport_en_boten_',
        '_marktplaats.nl_web_huizen_en_kamers_',
        '_marktplaats.nl_web_zakelijke_goederen_',
        '_marktplaats.nl_web_diensten_en_vakmensen_',
        '_marktplaats.nl_web_hobby_en_vrije_tijd_',
        '_marktplaats.nl_web_cds_en_dvds_',
        '_marktplaats.nl_web_kleding_heren_',
        '_marktplaats.nl_web_postzegels_en_munten_',
        '_marktplaats.nl_web_sieraden_en_tassen_',
        '_marktplaats.nl_web_tuin_en_terras_',
        '_marktplaats.nl_web_tickets_en_kaartjes_',
        '_marktplaats.nl_web_auto_onderdelen_',
      ],
    },
    mobile: {
      improvedigital: [
        '1067484',
        '1067377',
        '1067380',
        '1067383',
        '1067386',
        '1067467',
        '1067392',
        '1067416',
        '1067395',
        '1067401',
        '1067452',
        '1067404',
        '1067410',
        '1067413',
        '1067419',
        '1067425',
        '1067479',
        '1067489',
        '1067434',
        '1067440',
        '1067443',
        '1067455',
        '1067458',
        '1067470',
        '1067473',
        '1067476',
        '1067428',
        '1067482',
        null,
        '1067422',
        '1067486',
        '1067437',
        '1067446',
        '1067449',
        '1067464',
        '1067461',
        '1067389',
      ],
    },
  },
  lrp_gv_mid: {
    default: {
      improvedigital: [
        null,
        '996789',
        '996790',
        '996791',
        '996827',
        '996830',
        '996792',
        '996803',
        '996794',
        '996798',
        '996815',
        '996800',
        '996801',
        '996802',
        '996804',
        '996806',
        '996825',
        '996807',
        '996808',
        '996810',
        '996811',
        '996817',
        '996819',
        '996822',
        '996823',
        '996824',
        '996829',
        '996831',
        '996828',
        '996805',
        '996795',
        '996809',
        '996812',
        '996813',
        '996821',
        '996820',
        '996826',
      ],
      rubicon: [
        '732456',
        '709978',
        '710002',
        '710026',
        '710072',
        '711878',
        '710128',
        '711106',
        '710842',
        '710938',
        '711590',
        '710986',
        '711028',
        '711068',
        '711142',
        '711200',
        '711658',
        '711348',
        '711378',
        '711476',
        '711510',
        '711780',
        '711802',
        '711766',
        '711738',
        '711694',
        '711324',
        '711630',
        '711014',
        '711172',
        '710892',
        '711436',
        '711540',
        '711564',
        '711854',
        '711830',
        '710048',
      ],
      appnexus: [
        '12261834',
        '12261261',
        '12261273',
        '12261284',
        '12261320',
        '12261747',
        '12261338',
        '12261419',
        '12261349',
        '12261372',
        '12261690',
        '12261385',
        '12261396',
        '12261408',
        '12261475',
        '12261499',
        '12261814',
        '12261602',
        '12261617',
        '12261642',
        '12261653',
        '12261701',
        '12261712',
        '12261759',
        '12261770',
        '12261803',
        '12261583',
        '12261825',
        '13566032',
        '12261487',
        '12261360',
        '12261630',
        '12261664',
        '12261675',
        '12261735',
        '12261723',
        '12261309',
      ],
      tmp: [
        '13373591',
        '13372897',
        '13372920',
        null,
        null,
        null,
        '13373006',
        '13373142',
        null,
        '13373068',
        '13373369',
        null,
        null,
        null,
        null,
        '13373199',
        null,
        null,
        null,
        '13373294',
        null,
        null,
        null,
        null,
        '13373519',
        '13373537',
        null,
        '13373575',
        null,
        '13373179',
        '13373048',
        null,
        null,
        null,
        '13373444',
        null,
        '13372962',
      ],
      criteo: {
        '336x280': 1250892,
        '300x250': 1250890,
      },
      ix: [
        ['392', '234674'],
        ['9', '234291'],
        ['20', '234302'],
        ['31', '234313'],
        ['53', '234335'],
        ['328', '234610'],
        ['64', '234346'],
        ['141', '234423'],
        ['75', '234357'],
        ['97', '234379'],
        ['273', '234555'],
        ['108', '234390'],
        ['119', '234401'],
        ['130', '234412'],
        ['152', '234434'],
        ['174', '234456'],
        ['372', '234654'],
        ['196', '234478'],
        ['207', '234489'],
        ['229', '234511'],
        ['240', '234522'],
        ['284', '234566'],
        ['295', '234577'],
        ['339', '234621'],
        ['350', '234632'],
        ['361', '234643'],
        ['185', '234467'],
        ['383', '234665'],
        [null, '282106'],
        ['163', '234445'],
        ['86', '234368'],
        ['218', '234500'],
        ['251', '234533'],
        ['262', '234544'],
        ['317', '234599'],
        ['306', '234588'],
        ['42', '234324'],
      ],
      adhese: [
        '_marktplaats.nl_web_home_',
        '_marktplaats.nl_web_antiek_en_kunst_',
        '_marktplaats.nl_web_audio_tv_en_foto_',
        '_marktplaats.nl_web_auto_diversen_',
        '_marktplaats.nl_web_autos_',
        '_marktplaats.nl_web_vacatures_',
        '_marktplaats.nl_web_boeken_',
        '_marktplaats.nl_web_doe_het_zelf_en_verbouw_',
        '_marktplaats.nl_web_caravans_en_kamperen_',
        '_marktplaats.nl_web_computers_en_software_',
        '_marktplaats.nl_web_spelcomputers_games_',
        '_marktplaats.nl_web_contacten_en_berichten_',
        '_marktplaats.nl_web_dieren_en_toebehoren_',
        '_marktplaats.nl_web_diversen_',
        '_marktplaats.nl_web_fietsen_en_brommers_',
        '_marktplaats.nl_web_huis_en_inrichting_',
        '_marktplaats.nl_web_witgoed_en_apparatuur_',
        '_marktplaats.nl_web_kinderen_en_babys_',
        '_marktplaats.nl_web_kleding_dames_',
        '_marktplaats.nl_web_motoren_',
        '_marktplaats.nl_web_muziek_en_instrumenten_',
        '_marktplaats.nl_web_sport_en_fitness_',
        '_marktplaats.nl_web_telecommunicatie_',
        '_marktplaats.nl_web_vakantie_',
        '_marktplaats.nl_web_verzamelen_',
        '_marktplaats.nl_web_watersport_en_boten_',
        '_marktplaats.nl_web_huizen_en_kamers_',
        '_marktplaats.nl_web_zakelijke_goederen_',
        '_marktplaats.nl_web_diensten_en_vakmensen_',
        '_marktplaats.nl_web_hobby_en_vrije_tijd_',
        '_marktplaats.nl_web_cds_en_dvds_',
        '_marktplaats.nl_web_kleding_heren_',
        '_marktplaats.nl_web_postzegels_en_munten_',
        '_marktplaats.nl_web_sieraden_en_tassen_',
        '_marktplaats.nl_web_tuin_en_terras_',
        '_marktplaats.nl_web_tickets_en_kaartjes_',
        '_marktplaats.nl_web_auto_onderdelen_',
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  lrp_left: {
    default: {
      improvedigital: [
        '722837',
        '1247',
        '1249',
        '1251',
        '1309',
        '1317',
        '1253',
        '1267',
        '1255',
        '1259',
        '1289',
        '1261',
        '1263',
        '1265',
        '1269',
        '1273',
        '1305',
        '1275',
        '1277',
        '1281',
        '1283',
        '1291',
        '1293',
        '1299',
        '1301',
        '1303',
        '1315',
        '1319',
        '1311',
        '1271',
        '1257',
        '1279',
        '1285',
        '1287',
        '1297',
        '1295',
        '1307',
      ],
      rubicon: [
        '711612',
        '709970',
        '709996',
        '710018',
        '710062',
        '711868',
        '710120',
        '711094',
        '710146',
        '710912',
        '711580',
        '710970',
        '711018',
        '711052',
        '711134',
        '711192',
        '711676',
        '711340',
        '711368',
        '711464',
        '711502',
        '711696',
        '711794',
        '711782',
        '711746',
        '711716',
        '711316',
        '711640',
        '711010',
        '711158',
        '710880',
        '711408',
        '711532',
        '711556',
        '711846',
        '711818',
        '710040',
      ],
      appnexus: [
        '12261835',
        '12261262',
        '12261274',
        '12261285',
        '12261321',
        '12261748',
        '12261339',
        '12261420',
        '12261350',
        '12261373',
        '12261691',
        '12261386',
        '12261398',
        '12261409',
        '12261476',
        '12261500',
        '12261815',
        '12261603',
        '12261618',
        '12261643',
        '12261654',
        '12261702',
        '12261713',
        '12261760',
        '12261771',
        '12261804',
        '12261584',
        '12261826',
        '13566036',
        '12261488',
        '12261361',
        '12261631',
        '12261665',
        '12261676',
        '12261736',
        '12261724',
        '12261310',
      ],
      appnexus_weborama: [
        '29675651',
        '29675661',
        '29675666',
        '29675671',
        '29675676',
        '29675681',
        '29675686',
        '29675691',
        '29675696',
        '29675701',
        '29675706',
        '29675711',
        '29675716',
        '29675721',
        '29675726',
        '29675731',
        '29675736',
        '29675741',
        '29675746',
        '29675751',
        '29675756',
        '29675761',
        '29675766',
        '29675771',
        '29675776',
        '29675781',
        '29675786',
        '29675791',
        '29675796',
        '29675803',
        '29675809',
        '29675815',
        '29675822',
        '29675828',
        '29675834',
        '29675839',
        '29675845',
      ],
      tmp: [
        '13373592',
        '13372899',
        '13372922',
        '13372941',
        null,
        '13373468',
        '13373007',
        '13373143',
        null,
        '13373069',
        '13373371',
        '13373088',
        '13373106',
        '13373125',
        '13373161',
        '13373201',
        '13373558',
        '13373240',
        '13373259',
        '13373295',
        '13373314',
        '13373390',
        '13373408',
        null,
        '13373520',
        '13373539',
        null,
        '13373577',
        '13373605',
        '13373180',
        '13373049',
        '13373277',
        '13373332',
        '13373351',
        '13373445',
        '13373427',
        '13372963',
      ],
      criteo: {
        '120x600': 1250887,
        '160x600': 1250888,
        '300x250': 1250884,
        '300x600': 1250895,
      },
      ix: [
        ['393', '234675'],
        ['10', '234292'],
        ['21', '234303'],
        ['32', '234314'],
        ['54', '234336'],
        ['329', '234611'],
        ['65', '234347'],
        ['142', '234424'],
        ['76', '234358'],
        ['98', '234380'],
        ['274', '234556'],
        ['109', '234391'],
        ['120', '234402'],
        ['131', '234413'],
        ['153', '234435'],
        ['175', '234457'],
        ['373', '234655'],
        ['197', '234479'],
        ['208', '234490'],
        ['230', '234512'],
        ['241', '234523'],
        ['285', '234567'],
        ['296', '234578'],
        ['340', '234622'],
        ['351', '234633'],
        ['362', '234644'],
        ['186', '234468'],
        ['384', '234666'],
        [null, '282107'],
        ['164', '234446'],
        ['87', '234369'],
        ['219', '234501'],
        ['252', '234534'],
        ['263', '234545'],
        ['318', '234600'],
        ['307', '234589'],
        ['43', '234325'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  lrp_left1: {
    default: {
      improvedigital: [
        null,
        '1148131',
        '1147677',
        '1147678',
        '1147707',
        '1147711',
        '1147679',
        '1147686',
        '1147680',
        '1147682',
        '1147697',
        '1147683',
        '1147684',
        '1147685',
        '1147687',
        '1147689',
        '1147705',
        '1147690',
        '1147691',
        '1147693',
        '1147694',
        '1147698',
        '1147699',
        '1147702',
        '1147703',
        '1147704',
        '1147710',
        '1147712',
        '1147708',
        '1147688',
        '1147681',
        '1147692',
        '1147695',
        '1147696',
        '1147701',
        '1147700',
        '1147706',
      ],
      rubicon: [
        '908802',
        '908722',
        '908724',
        '908726',
        '908730',
        '908826',
        '909082',
        '908776',
        '908734',
        '908738',
        '908800',
        '908740',
        '908772',
        '908774',
        '908778',
        '908782',
        '908806',
        '908786',
        '908788',
        '908792',
        '908794',
        '908808',
        '908816',
        '908814',
        '908812',
        '908810',
        '908784',
        '908804',
        '908770',
        '908780',
        '908736',
        '908790',
        '908796',
        '908798',
        '908824',
        '908822',
        '908728',
      ],
      appnexus: [
        '13060414',
        '13060270',
        '13060292',
        '13060295',
        '13060311',
        '13060395',
        '13060314',
        '13060335',
        '13060317',
        '13060323',
        '13060380',
        '13060326',
        '13060329',
        '13060332',
        '13060338',
        '13060344',
        '13060407',
        '13060354',
        '13060357',
        '13060363',
        '13060366',
        '13060383',
        '13060386',
        '13060398',
        '13060401',
        '13060404',
        '13060347',
        '13060411',
        '13566047',
        '13060341',
        '13060320',
        '13060360',
        '13060369',
        '13060458',
        '13060392',
        '13060389',
        '13060308',
      ],
      appnexus_weborama: [
        '29675651',
        '29675661',
        '29675666',
        '29675671',
        '29675676',
        '29675681',
        '29675686',
        '29675691',
        '29675696',
        '29675701',
        '29675706',
        '29675711',
        '29675716',
        '29675721',
        '29675726',
        '29675731',
        '29675736',
        '29675741',
        '29675746',
        '29675751',
        '29675756',
        '29675761',
        '29675766',
        '29675771',
        '29675776',
        '29675781',
        '29675786',
        '29675791',
        '29675796',
        '29675803',
        '29675809',
        '29675815',
        '29675822',
        '29675828',
        '29675834',
        '29675839',
        '29675845',
      ],
      tmp: [
        '13373593',
        '13372901',
        '13372923',
        '13372942',
        '13372989',
        '13373470',
        '13373010',
        '13373144',
        '13373031',
        '13373071',
        '13373372',
        '13373089',
        '13373108',
        '13373126',
        '13373163',
        '13373202',
        '13373559',
        '13373242',
        '13373260',
        '13373297',
        '13373315',
        '13373391',
        '13373410',
        '13373503',
        '13373522',
        '13373541',
        '13373223',
        '13373578',
        '13373606',
        '13373181',
        '13373050',
        '13373278',
        '13373334',
        '13373352',
        '13373447',
        '13373428',
        '13372966',
      ],
      criteo: {
        '120x600': 1250898,
        '160x600': 1250897,
        '300x250': 1250905,
        '300x600': 1350214,
      },
      ix: [
        ['430', '260861'],
        ['395', '260826'],
        ['396', '260827'],
        ['397', '260828'],
        ['399', '260830'],
        ['424', '260855'],
        ['400', '260831'],
        ['407', '260838'],
        ['401', '260832'],
        ['403', '260834'],
        ['419', '260850'],
        ['404', '260835'],
        ['405', '260836'],
        ['406', '260837'],
        ['408', '260839'],
        ['410', '260841'],
        ['428', '260859'],
        ['412', '260843'],
        ['413', '260844'],
        ['415', '260846'],
        ['416', '260847'],
        ['420', '260851'],
        ['421', '260852'],
        ['425', '260856'],
        ['426', '260857'],
        ['427', '260858'],
        ['411', '260842'],
        ['429', '260860'],
        [null, '282114'],
        ['409', '260840'],
        ['402', '260833'],
        ['414', '260845'],
        ['417', '260848'],
        ['418', '260849'],
        ['423', '260854'],
        ['422', '260853'],
        ['398', '260829'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  lrp_mid: {
    default: {
      improvedigital: [
        '722835',
        '679601',
        '679603',
        '679605',
        '679609',
        '680083',
        '679611',
        '680031',
        '679613',
        '679999',
        '680069',
        '680001',
        '680023',
        '680027',
        '680033',
        '680045',
        '680113',
        '680049',
        '680055',
        '680059',
        '680061',
        '680071',
        '680073',
        '680085',
        '680101',
        '680105',
        '680047',
        '680117',
        '680019',
        '680043',
        '679615',
        '680057',
        '680063',
        '680067',
        '680081',
        '680077',
        '679607',
      ],
      rubicon: [
        '711610',
        '709984',
        '710010',
        '710034',
        '710118',
        '711896',
        '710134',
        '711116',
        '710850',
        '710952',
        '711598',
        '710992',
        '711040',
        '711082',
        '711148',
        '711206',
        '711650',
        '711360',
        '711394',
        '711488',
        '711522',
        '711788',
        '711808',
        '711760',
        '711730',
        '711686',
        '711330',
        '711622',
        '994090',
        '711180',
        '710898',
        '711446',
        '711546',
        '711570',
        '711860',
        '711838',
        '710054',
      ],
      appnexus: [
        '12261833',
        '12261260',
        '12261272',
        '12261283',
        '12261319',
        '12261746',
        '12261337',
        '12261418',
        '12261348',
        '12261371',
        '12261689',
        '12261384',
        '12261395',
        '12261407',
        '12261474',
        '12261498',
        '12261813',
        '12261601',
        '12261616',
        '12261641',
        '12261652',
        '12261700',
        '12261711',
        '12261758',
        '12261769',
        '12261802',
        '12261582',
        '12261824',
        '13566035',
        '12261486',
        '12261359',
        '12261629',
        '12261663',
        '12261674',
        '12261734',
        '12261722',
        '12261308',
      ],
      appnexus_weborama: [
        '29675652',
        '29675662',
        '29675667',
        '29675672',
        '29675677',
        '29675682',
        '29675687',
        '29675692',
        '29675697',
        '29675702',
        '29675707',
        '29675712',
        '29675717',
        '29675722',
        '29675727',
        '29675732',
        '29675737',
        '29675742',
        '29675747',
        '29675752',
        '29675757',
        '29675762',
        '29675767',
        '29675772',
        '29675777',
        '29675782',
        '29675787',
        '29675792',
        '29675798',
        '29675804',
        '29675811',
        '29675817',
        '29675823',
        '29675829',
        '29675835',
        '29675840',
        '29675846',
      ],
      tmp: [
        '13373595',
        '13372902',
        null,
        '13372945',
        null,
        null,
        '13373011',
        '13373146',
        null,
        null,
        null,
        '13373090',
        null,
        '13373127',
        null,
        null,
        null,
        null,
        null,
        null,
        '13373316',
        '13373392',
        null,
        null,
        '13373523',
        '13373542',
        null,
        '13373579',
        null,
        '13373182',
        '13373052',
        null,
        '13373336',
        null,
        '13373450',
        null,
        '13372969',
      ],
      criteo: {
        '728x90': 1250889,
        '300x250': 1250913,
      },
      ix: [
        ['391', '234673'],
        ['8', '234290'],
        ['19', '234301'],
        ['30', '234312'],
        ['52', '234334'],
        ['327', '234609'],
        ['63', '234345'],
        ['140', '234422'],
        ['74', '234356'],
        ['96', '234378'],
        ['272', '234554'],
        ['107', '234389'],
        ['118', '234400'],
        ['129', '234411'],
        ['151', '234433'],
        ['173', '234455'],
        ['371', '234653'],
        ['195', '234477'],
        ['206', '234488'],
        ['228', '234510'],
        ['239', '234521'],
        ['283', '234565'],
        ['294', '234576'],
        ['338', '234620'],
        ['349', '234631'],
        ['360', '234642'],
        ['184', '234466'],
        ['382', '234664'],
        [null, '282105'],
        ['162', '234444'],
        ['85', '234367'],
        ['217', '234499'],
        ['250', '234532'],
        ['261', '234543'],
        ['316', '234598'],
        ['305', '234587'],
        ['41', '234323'],
      ],
      adhese: [
        '_marktplaats.nl_web_home_',
        '_marktplaats.nl_web_antiek_en_kunst_',
        '_marktplaats.nl_web_audio_tv_en_foto_',
        '_marktplaats.nl_web_auto_diversen_',
        '_marktplaats.nl_web_autos_',
        '_marktplaats.nl_web_vacatures_',
        '_marktplaats.nl_web_boeken_',
        '_marktplaats.nl_web_doe_het_zelf_en_verbouw_',
        '_marktplaats.nl_web_caravans_en_kamperen_',
        '_marktplaats.nl_web_computers_en_software_',
        '_marktplaats.nl_web_spelcomputers_games_',
        '_marktplaats.nl_web_contacten_en_berichten_',
        '_marktplaats.nl_web_dieren_en_toebehoren_',
        '_marktplaats.nl_web_diversen_',
        '_marktplaats.nl_web_fietsen_en_brommers_',
        '_marktplaats.nl_web_huis_en_inrichting_',
        '_marktplaats.nl_web_witgoed_en_apparatuur_',
        '_marktplaats.nl_web_kinderen_en_babys_',
        '_marktplaats.nl_web_kleding_dames_',
        '_marktplaats.nl_web_motoren_',
        '_marktplaats.nl_web_muziek_en_instrumenten_',
        '_marktplaats.nl_web_sport_en_fitness_',
        '_marktplaats.nl_web_telecommunicatie_',
        '_marktplaats.nl_web_vakantie_',
        '_marktplaats.nl_web_verzamelen_',
        '_marktplaats.nl_web_watersport_en_boten_',
        '_marktplaats.nl_web_huizen_en_kamers_',
        '_marktplaats.nl_web_zakelijke_goederen_',
        '_marktplaats.nl_web_diensten_en_vakmensen_',
        '_marktplaats.nl_web_hobby_en_vrije_tijd_',
        '_marktplaats.nl_web_cds_en_dvds_',
        '_marktplaats.nl_web_kleding_heren_',
        '_marktplaats.nl_web_postzegels_en_munten_',
        '_marktplaats.nl_web_sieraden_en_tassen_',
        '_marktplaats.nl_web_tuin_en_terras_',
        '_marktplaats.nl_web_tickets_en_kaartjes_',
        '_marktplaats.nl_web_auto_onderdelen_',
      ],
    },
    mobile: {
      improvedigital: [
        '1040573',
        '1040632',
        '1040633',
        '1040641',
        '1040636',
        '1040637',
        '1040642',
        '1040679',
        '1040644',
        '1040658',
        '1040676',
        '1040672',
        '1040677',
        '1040574',
        '1040681',
        '1040684',
        '1040629',
        '1040690',
        '1040688',
        '1040685',
        '1040683',
        '1040673',
        '1040643',
        '1040635',
        '1040634',
        '1040631',
        '1040686',
        '1040623',
        null,
        '1040682',
        '1040689',
        '1040687',
        '1040680',
        '1040678',
        '1040638',
        '1040640',
        '1040639',
      ],
    },
  },
  home_top: {
    default: {
      improvedigital: ['589603'],
      rubicon: ['711190'],
      appnexus: ['12261255'],
      appnexus_weborama: ['29675729'],
      gumgum: 1, // this is to enable gumgum config for this position
      tmp: ['13373193'],
      criteo: {
        '970x250': 1250886,
        '728x90': 1250885,
        '300x250': 1250905,
        '320x50': 1250904,
        '320x100': 1257306,
        '320x240': 1257307,
      },
      ix: [['3', '234285']],
      adhese: ['_marktplaats.nl_web_home_'],
    },
    mobile: {
      improvedigital: ['235481'],
    },
  },
  vip_right: {
    default: {
      improvedigital: [
        null,
        '535391',
        '535393',
        '535395',
        '535399',
        '535503',
        '535401',
        '535417',
        '535403',
        '535407',
        '535493',
        '535409',
        '535413',
        '535415',
        '535419',
        '535425',
        '535511',
        '535429',
        '535431',
        '535485',
        '535487',
        '535495',
        '535497',
        '535505',
        '535507',
        '535509',
        '535427',
        '535513',
        '9929',
        '535421',
        '535405',
        '535483',
        '535489',
        '535491',
        '535501',
        '535499',
        '535397',
      ],
      rubicon: [
        null,
        '709976',
        '709992',
        '710024',
        '710070',
        '711876',
        '710126',
        '711104',
        '710840',
        '710932',
        '711584',
        '710984',
        '711026',
        '711064',
        '711140',
        '711198',
        '711662',
        '711346',
        '711374',
        '711474',
        '711508',
        '711778',
        '711800',
        '711774',
        '711740',
        '711698',
        '711322',
        '711632',
        '994104',
        '711170',
        '710888',
        '711432',
        '711538',
        '711562',
        '711852',
        '711828',
        '710046',
      ],
      appnexus: [
        null,
        '12261265',
        '12261277',
        '12261288',
        '12261324',
        '12261752',
        '12261342',
        '12261423',
        '12261353',
        '12261376',
        '12261694',
        '12261389',
        '12261401',
        '12261412',
        '12261480',
        '12261503',
        '12261818',
        '12261608',
        '12261623',
        '12261646',
        '12261657',
        '12261705',
        '12261716',
        '12261763',
        '12261774',
        '12261807',
        '12261592',
        '12261829',
        '13566067',
        '12261492',
        '12261364',
        '12261634',
        '12261668',
        '12261679',
        '12261739',
        '12261727',
        '12261313',
      ],
      appnexus_weborama: [
        '29675653',
        '29675663',
        '29675668',
        '29675673',
        '29675678',
        '29675683',
        '29675688',
        '29675693',
        '29675698',
        '29675703',
        '29675708',
        '29675713',
        '29675718',
        '29675723',
        '29675733',
        '29675738',
        '29675743',
        '29675748',
        '29675753',
        '29675758',
        '29675763',
        '29675768',
        '29675773',
        '29675778',
        '29675783',
        '29675788',
        '29675793',
        '29675799',
        '29675805',
        '29675812',
        '29675818',
        '29675824',
        '29675830',
        '29675836',
        '29675841',
        '29675847',
      ],
      criteo: {
        '300x600': 1250895,
        '300x250': 1250896,
        '320x50': 1250914,
        '320x100': 1257306,
        '320x240': 1250913,
      },
      ix: [
        null,
        ['13', '234295'],
        ['24', '234306'],
        ['35', '234317'],
        ['57', '234339'],
        ['332', '234614'],
        ['68', '234350'],
        ['145', '234427'],
        ['79', '234361'],
        ['101', '234383'],
        ['277', '234559'],
        ['112', '234394'],
        ['123', '234405'],
        ['134', '234416'],
        ['156', '234438'],
        ['178', '234460'],
        ['376', '234658'],
        ['200', '234482'],
        ['211', '234493'],
        ['233', '234515'],
        ['244', '234526'],
        ['288', '234570'],
        ['299', '234581'],
        ['343', '234625'],
        ['354', '234636'],
        ['365', '234647'],
        ['189', '234471'],
        ['387', '234669'],
        [null, '282110'],
        ['167', '234449'],
        ['90', '234372'],
        ['222', '234504'],
        ['255', '234537'],
        ['266', '234548'],
        ['321', '234603'],
        ['310', '234592'],
        ['46', '234328'],
      ],
      adhese: [
        '_marktplaats.nl_web_home_',
        '_marktplaats.nl_web_antiek_en_kunst_',
        '_marktplaats.nl_web_audio_tv_en_foto_',
        '_marktplaats.nl_web_auto_diversen_',
        '_marktplaats.nl_web_autos_',
        '_marktplaats.nl_web_vacatures_',
        '_marktplaats.nl_web_boeken_',
        '_marktplaats.nl_web_doe_het_zelf_en_verbouw_',
        '_marktplaats.nl_web_caravans_en_kamperen_',
        '_marktplaats.nl_web_computers_en_software_',
        '_marktplaats.nl_web_spelcomputers_games_',
        '_marktplaats.nl_web_contacten_en_berichten_',
        '_marktplaats.nl_web_dieren_en_toebehoren_',
        '_marktplaats.nl_web_diversen_',
        '_marktplaats.nl_web_fietsen_en_brommers_',
        '_marktplaats.nl_web_huis_en_inrichting_',
        '_marktplaats.nl_web_witgoed_en_apparatuur_',
        '_marktplaats.nl_web_kinderen_en_babys_',
        '_marktplaats.nl_web_kleding_dames_',
        '_marktplaats.nl_web_motoren_',
        '_marktplaats.nl_web_muziek_en_instrumenten_',
        '_marktplaats.nl_web_sport_en_fitness_',
        '_marktplaats.nl_web_telecommunicatie_',
        '_marktplaats.nl_web_vakantie_',
        '_marktplaats.nl_web_verzamelen_',
        '_marktplaats.nl_web_watersport_en_boten_',
        '_marktplaats.nl_web_huizen_en_kamers_',
        '_marktplaats.nl_web_zakelijke_goederen_',
        '_marktplaats.nl_web_diensten_en_vakmensen_',
        '_marktplaats.nl_web_hobby_en_vrije_tijd_',
        '_marktplaats.nl_web_cds_en_dvds_',
        '_marktplaats.nl_web_kleding_heren_',
        '_marktplaats.nl_web_postzegels_en_munten_',
        '_marktplaats.nl_web_sieraden_en_tassen_',
        '_marktplaats.nl_web_tuin_en_terras_',
        '_marktplaats.nl_web_tickets_en_kaartjes_',
        '_marktplaats.nl_web_auto_onderdelen_',
      ],
    },
    mobile: {
      improvedigital: [
        null,
        '1067378',
        '1067381',
        '1067384',
        '1067387',
        '1067468',
        '1067393',
        '1067417',
        '1067396',
        '1067402',
        '1067453',
        '1067405',
        '1067411',
        '1067414',
        '1067420',
        '1067426',
        '1067480',
        '1067492',
        '1067435',
        '1067441',
        '1067444',
        '1067456',
        '1067459',
        '1067471',
        '1067474',
        '1067477',
        '1067429',
        '1067483',
        null,
        '1067423',
        '1067487',
        '1067438',
        '1067447',
        '1067450',
        '1067465',
        '1067462',
        '1067390',
      ],
    },
  },
  vip_right1: {
    default: {
      improvedigital: 22182069,
      rubicon: 1508214,
      appnexus: 17957705,
      appnexus_weborama: [
        '29675651',
        '29675661',
        '29675666',
        '29675671',
        '29675676',
        '29675681',
        '29675686',
        '29675691',
        '29675696',
        '29675701',
        '29675706',
        '29675711',
        '29675716',
        '29675721',
        '29675731',
        '29675736',
        '29675741',
        '29675746',
        '29675751',
        '29675756',
        '29675761',
        '29675766',
        '29675771',
        '29675776',
        '29675781',
        '29675786',
        '29675791',
        '29675796',
        '29675803',
        '29675809',
        '29675815',
        '29675822',
        '29675828',
        '29675834',
        '29675839',
        '29675845',
      ],
      tmp: 18356356,
      criteo: {
        '120x600': 1250898,
        '160x600': 1250897,
        '300x250': 1491279,
        '300x600': 1491283,
      },
      ix: 436335,
    },
    mobile: {
      improvedigital: [],
    },
  },
  vip_top: {
    default: {
      improvedigital: [
        null,
        '842187',
        '843467',
        '843471',
        '843477',
        '843558',
        '843481',
        '843518',
        '843482',
        '843489',
        '843547',
        '843493',
        '843497',
        '843512',
        '843519',
        '843528',
        '843565',
        '843534',
        '843536',
        '843542',
        '843543',
        '843551',
        '843552',
        '843560',
        '843563',
        '843603',
        '843531',
        '843569',
        '843496',
        '843524',
        '843488',
        '843541',
        '843544',
        '843545',
        '843556',
        '843553',
        '843474',
      ],
      rubicon: [
        null,
        '709980',
        '710006',
        '710030',
        '710074',
        '711880',
        '710130',
        '711110',
        '710844',
        '710942',
        '711594',
        '710988',
        '711034',
        '711072',
        '711144',
        '711202',
        '711656',
        '711350',
        '711382',
        '711480',
        '711512',
        '711784',
        '711804',
        '711764',
        '711736',
        '711690',
        '711326',
        '711626',
        '994100',
        '711174',
        '710894',
        '711440',
        '711542',
        '711566',
        '711856',
        '711834',
        '710050',
      ],
      appnexus: [
        null,
        '12261264',
        '12261276',
        '12261287',
        '12261323',
        '12261751',
        '12261341',
        '12261422',
        '12261352',
        '12261375',
        '12261693',
        '12261388',
        '12261400',
        '12261411',
        '12261479',
        '12261502',
        '12261817',
        '12261607',
        '12261622',
        '12261645',
        '12261656',
        '12261704',
        '12261715',
        '12261762',
        '12261773',
        '12261806',
        '12261590',
        '12261828',
        '13566065',
        '12261491',
        '12261363',
        '12261633',
        '12261667',
        '12261678',
        '12261738',
        '12261726',
        '12261312',
      ],
      appnexus_weborama: [
        '29675659',
        '29675664',
        '29675669',
        '29675674',
        '29675679',
        '29675684',
        '29675689',
        '29675694',
        '29675699',
        '29675704',
        '29675709',
        '29675714',
        '29675719',
        '29675724',
        '29675734',
        '29675739',
        '29675744',
        '29675749',
        '29675754',
        '29675759',
        '29675764',
        '29675769',
        '29675774',
        '29675779',
        '29675784',
        '29675789',
        '29675794',
        '29675800',
        '29675806',
        '29675813',
        '29675819',
        '29675825',
        '29675831',
        '29675837',
        '29675842',
        '29675848',
      ],
      tmp: [
        null,
        '13372914',
        '13372933',
        '13372955',
        '13372999',
        '13373484',
        '13373019',
        '13373154',
        '13373040',
        '13373080',
        '13373382',
        '13373098',
        '13373117',
        '13373135',
        '13373172',
        '13373212',
        '13373569',
        '13373251',
        '13373269',
        '13373306',
        '13373324',
        '13373400',
        '13373419',
        '13373512',
        '13373531',
        '13373550',
        '13373232',
        '13373588',
        '13373664',
        '13373190',
        '13373060',
        '13373288',
        '13373343',
        '13373361',
        '13373458',
        '13373437',
        '13372978',
      ],
      criteo: {
        '970x250': 1250894,
        '728x90': 1250893,
      },
      ix: [
        null,
        ['12', '234294'],
        ['23', '234305'],
        ['34', '234316'],
        ['56', '234338'],
        ['331', '234613'],
        ['67', '234349'],
        ['144', '234426'],
        ['78', '234360'],
        ['100', '234382'],
        ['276', '234558'],
        ['111', '234393'],
        ['122', '234404'],
        ['133', '234415'],
        ['155', '234437'],
        ['177', '234459'],
        ['375', '234657'],
        ['199', '234481'],
        ['210', '234492'],
        ['232', '234514'],
        ['243', '234525'],
        ['287', '234569'],
        ['298', '234580'],
        ['342', '234624'],
        ['353', '234635'],
        ['364', '234646'],
        ['188', '234470'],
        ['386', '234668'],
        [null, '282109'],
        ['166', '234448'],
        ['89', '234371'],
        ['221', '234503'],
        ['254', '234536'],
        ['265', '234547'],
        ['320', '234602'],
        ['309', '234591'],
        ['45', '234327'],
      ],
      adhese: [
        '_marktplaats.nl_web_home_',
        '_marktplaats.nl_web_antiek_en_kunst_',
        '_marktplaats.nl_web_audio_tv_en_foto_',
        '_marktplaats.nl_web_auto_diversen_',
        '_marktplaats.nl_web_autos_',
        '_marktplaats.nl_web_vacatures_',
        '_marktplaats.nl_web_boeken_',
        '_marktplaats.nl_web_doe_het_zelf_en_verbouw_',
        '_marktplaats.nl_web_caravans_en_kamperen_',
        '_marktplaats.nl_web_computers_en_software_',
        '_marktplaats.nl_web_spelcomputers_games_',
        '_marktplaats.nl_web_contacten_en_berichten_',
        '_marktplaats.nl_web_dieren_en_toebehoren_',
        '_marktplaats.nl_web_diversen_',
        '_marktplaats.nl_web_fietsen_en_brommers_',
        '_marktplaats.nl_web_huis_en_inrichting_',
        '_marktplaats.nl_web_witgoed_en_apparatuur_',
        '_marktplaats.nl_web_kinderen_en_babys_',
        '_marktplaats.nl_web_kleding_dames_',
        '_marktplaats.nl_web_motoren_',
        '_marktplaats.nl_web_muziek_en_instrumenten_',
        '_marktplaats.nl_web_sport_en_fitness_',
        '_marktplaats.nl_web_telecommunicatie_',
        '_marktplaats.nl_web_vakantie_',
        '_marktplaats.nl_web_verzamelen_',
        '_marktplaats.nl_web_watersport_en_boten_',
        '_marktplaats.nl_web_huizen_en_kamers_',
        '_marktplaats.nl_web_zakelijke_goederen_',
        '_marktplaats.nl_web_diensten_en_vakmensen_',
        '_marktplaats.nl_web_hobby_en_vrije_tijd_',
        '_marktplaats.nl_web_cds_en_dvds_',
        '_marktplaats.nl_web_kleding_heren_',
        '_marktplaats.nl_web_postzegels_en_munten_',
        '_marktplaats.nl_web_sieraden_en_tassen_',
        '_marktplaats.nl_web_tuin_en_terras_',
        '_marktplaats.nl_web_tickets_en_kaartjes_',
        '_marktplaats.nl_web_auto_onderdelen_',
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  vip_mid: {
    default: {
      improvedigital: 22078767,
      rubicon: 1383272,
      appnexus: 16731814,
      appnexus_weborama: [
        '29675652',
        '29675662',
        '29675667',
        '29675672',
        '29675677',
        '29675682',
        '29675687',
        '29675692',
        '29675697',
        '29675702',
        '29675707',
        '29675712',
        '29675717',
        '29675722',
        '29675732',
        '29675737',
        '29675742',
        '29675747',
        '29675752',
        '29675757',
        '29675762',
        '29675767',
        '29675772',
        '29675777',
        '29675782',
        '29675787',
        '29675792',
        '29675798',
        '29675804',
        '29675811',
        '29675817',
        '29675823',
        '29675829',
        '29675835',
        '29675840',
        '29675846',
      ],
      tmp: 18356399,
      criteo: {
        '320x50': 1429550,
        '468x60': 1429551,
        '728x90': 1345887,
      },
      ix: 396476,
    },
    mobile: {
      improvedigital: 22078767,
    },
  },
  vip_left: {
    default: {
      improvedigital: [
        null,
        '921621',
        '921622',
        '921623',
        '921625',
        '921666',
        '921627',
        '921641',
        '921628',
        '921635',
        '921660',
        '921636',
        '921638',
        '921639',
        '921644',
        '921647',
        '921672',
        '921649',
        '921650',
        '921653',
        '921654',
        '921661',
        '921662',
        '921668',
        '921669',
        '921671',
        '921648',
        '921674',
        '921637',
        '921645',
        '921630',
        '921652',
        '921656',
        '921657',
        '921665',
        '921663',
        '921624',
      ],
      rubicon: [
        null,
        '709972',
        '709998',
        '710020',
        '710064',
        '711872',
        '710122',
        '711096',
        '710818',
        '710920',
        '711582',
        '710972',
        '711022',
        '711056',
        '711136',
        '711194',
        '711668',
        '711342',
        '711370',
        '711466',
        '711504',
        '711692',
        '711796',
        '711776',
        '711744',
        '711712',
        '711318',
        '711638',
        '994102',
        '711162',
        '710856',
        '711410',
        '711534',
        '711558',
        '711848',
        '711824',
        '710042',
      ],
      appnexus: [
        null,
        '12261266',
        '12261278',
        '12261289',
        '12261325',
        '12261753',
        '12261343',
        '12261424',
        '12261354',
        '12261377',
        '12261695',
        '12261390',
        '12261402',
        '12261413',
        '12261481',
        '12261504',
        '12261819',
        '12261609',
        '12261624',
        '12261647',
        '12261658',
        '12261706',
        '12261717',
        '12261764',
        '12261775',
        '12261808',
        '12261593',
        '12261830',
        '13566066',
        '12261493',
        '12261365',
        '12261635',
        '12261669',
        '12261680',
        '12261740',
        '12261728',
        '12261314',
      ],
      appnexus_weborama: [
        '29675651',
        '29675661',
        '29675666',
        '29675671',
        '29675676',
        '29675681',
        '29675686',
        '29675691',
        '29675696',
        '29675701',
        '29675706',
        '29675711',
        '29675716',
        '29675721',
        '29675731',
        '29675736',
        '29675741',
        '29675746',
        '29675751',
        '29675756',
        '29675761',
        '29675766',
        '29675771',
        '29675776',
        '29675781',
        '29675786',
        '29675791',
        '29675796',
        '29675803',
        '29675809',
        '29675815',
        '29675822',
        '29675828',
        '29675834',
        '29675839',
        '29675845',
      ],
      tmp: [
        null,
        '13372910',
        '13372931',
        '13372952',
        '13372997',
        '13373478',
        '13373016',
        '13373151',
        '13373038',
        '13373077',
        '13373378',
        '13373096',
        '13373114',
        '13373133',
        '13373169',
        '13373210',
        '13373566',
        '13373248',
        '13373267',
        '13373303',
        '13373322',
        '13373398',
        '13373416',
        '13373510',
        '13373528',
        '13373548',
        '13373230',
        '13373585',
        '13373613',
        '13373188',
        '13373057',
        '13373285',
        '13373341',
        '13373359',
        '13373455',
        '13373435',
        '13372974',
      ],
      criteo: {
        '120x600': 1250887,
        '160x600': 1250888,
        '300x250': 1250905,
        '300x600': 1350214,
      },
      ix: [
        null,
        ['14', '234296'],
        ['25', '234307'],
        ['36', '234318'],
        ['58', '234340'],
        ['333', '234615'],
        ['69', '234351'],
        ['146', '234428'],
        ['80', '234362'],
        ['102', '234384'],
        ['278', '234560'],
        ['113', '234395'],
        ['124', '234406'],
        ['135', '234417'],
        ['157', '234439'],
        ['179', '234461'],
        ['377', '234659'],
        ['201', '234483'],
        ['212', '234494'],
        ['234', '234516'],
        ['245', '234527'],
        ['289', '234571'],
        ['300', '234582'],
        ['344', '234626'],
        ['355', '234637'],
        ['366', '234648'],
        ['190', '234472'],
        ['388', '234670'],
        [null, '282111'],
        ['168', '234450'],
        ['91', '234373'],
        ['223', '234505'],
        ['256', '234538'],
        ['267', '234549'],
        ['322', '234604'],
        ['311', '234593'],
        ['47', '234329'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  vip_bot: {
    default: {
      improvedigital: [
        null,
        '1147910',
        '1147912',
        '1147913',
        '1147948',
        '1147952',
        '1147915',
        '1147922',
        '1147916',
        '1147918',
        '1147936',
        '1147919',
        '1147920',
        '1147921',
        '1147923',
        '1147926',
        '1147946',
        '1147927',
        '1147928',
        '1147931',
        '1147932',
        '1147937',
        '1147938',
        '1147942',
        '1147943',
        '1147945',
        '1147951',
        '1148158',
        '1147950',
        '1147925',
        '1147917',
        '1147929',
        '1147933',
        '1147733',
        '1147941',
        '1147939',
        '1147947',
      ],
      rubicon: [
        null,
        '908828',
        '908832',
        '908834',
        '908840',
        '908922',
        '908842',
        '908856',
        '908844',
        '908848',
        '908900',
        '908850',
        '908852',
        '908854',
        '908864',
        '908872',
        '908906',
        '908878',
        '908880',
        '908890',
        '908894',
        '908914',
        '908916',
        '908912',
        '908910',
        '908908',
        '908876',
        '908904',
        '994106',
        '908868',
        '908846',
        '908886',
        '908896',
        '908898',
        '908920',
        '908918',
        '908836',
      ],
      appnexus: [
        null,
        '13060285',
        '13060293',
        '13060296',
        '13060312',
        '13060396',
        '13060315',
        '13060336',
        '13060318',
        '13060324',
        '13060381',
        '13060327',
        '13060330',
        '13060333',
        '13060339',
        '13060345',
        '13060409',
        '13060355',
        '13060358',
        '13060364',
        '13060367',
        '13060384',
        '13060387',
        '13060399',
        '13060402',
        '13060405',
        '13060352',
        '13060412',
        '13566070',
        '13060342',
        '13060321',
        '13060361',
        '13060370',
        '13060378',
        '13060393',
        '13060390',
        '13060309',
      ],
      appnexus_weborama: [
        '29675650',
        '29675660',
        '29675665',
        '29675670',
        '29675675',
        '29675680',
        '29675685',
        '29675690',
        '29675695',
        '29675700',
        '29675705',
        '29675710',
        '29675715',
        '29675720',
        '29675730',
        '29675735',
        '29675740',
        '29675745',
        '29675750',
        '29675755',
        '29675760',
        '29675765',
        '29675770',
        '29675775',
        '29675780',
        '29675785',
        '29675790',
        '29675795',
        '29675802',
        '29675808',
        '29675814',
        '29675821',
        '29675827',
        '29675833',
        '29675838',
        '29675844',
      ],
      criteo: {
        '728x90': 1345887,
      },
      ix: [
        null,
        ['431', '260862'],
        ['432', '260863'],
        ['433', '260864'],
        ['435', '260866'],
        ['460', '260891'],
        ['436', '260867'],
        ['443', '260874'],
        ['437', '260868'],
        ['439', '260870'],
        ['455', '260886'],
        ['440', '260871'],
        ['441', '260872'],
        ['442', '260873'],
        ['444', '260875'],
        ['446', '260877'],
        ['464', '260895'],
        ['448', '260879'],
        ['449', '260880'],
        ['451', '260882'],
        ['452', '260883'],
        ['456', '260887'],
        ['457', '260888'],
        ['461', '260892'],
        ['462', '260893'],
        ['463', '260894'],
        ['447', '260878'],
        ['465', '260896'],
        [null, '282115'],
        ['445', '260876'],
        ['438', '260869'],
        ['450', '260881'],
        ['453', '260884'],
        ['454', '260885'],
        ['459', '260890'],
        ['458', '260889'],
        ['434', '260865'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  vip_map_top: {
    default: {
      improvedigital: [
        null,
        '837679',
        '837680',
        '837681',
        '837685',
        '837888',
        '837686',
        '837850',
        '837687',
        '837800',
        '837883',
        '837801',
        '837803',
        '837846',
        '837851',
        '837865',
        '837892',
        '837867',
        '837868',
        '837870',
        '837871',
        '837884',
        '837885',
        '837889',
        '837890',
        '837891',
        '837866',
        '837893',
        '837802',
        '837853',
        '837690',
        '837869',
        '837872',
        '837882',
        '837887',
        '837886',
        '837684',
      ],
      rubicon: [
        null,
        '709986',
        '710012',
        '710028',
        '710078',
        '711884',
        '710138',
        '711120',
        '710144',
        '710956',
        '711652',
        '710994',
        '711042',
        '711086',
        '711150',
        '711310',
        '711648',
        '711362',
        '711398',
        '711492',
        '711526',
        '711912',
        '711812',
        '711758',
        '711722',
        '711684',
        '711336',
        '711620',
        '994108',
        '711182',
        '710900',
        '711448',
        '711548',
        '711572',
        '711862',
        '711840',
        '710056',
      ],
      appnexus: [
        null,
        '12261267',
        '12261279',
        '12261290',
        '12261326',
        '12261754',
        '12261344',
        '12261425',
        '12261355',
        '12261380',
        '12261696',
        '12261391',
        '12261403',
        '12261414',
        '12261482',
        '12261505',
        '12261820',
        '12261610',
        '12261625',
        '12261648',
        '12261659',
        '12261707',
        '12261718',
        '12261765',
        '12261776',
        '12261809',
        '12261594',
        '12261831',
        '13566083',
        '12261494',
        '12261366',
        '12261636',
        '12261670',
        '12261681',
        '12261741',
        '12261729',
        '12261315',
      ],
      tmp: [
        null,
        '13372907',
        '13372929',
        '13372951',
        '13372996',
        '13373477',
        '13373015',
        '13373150',
        '13373036',
        '13373076',
        '13373377',
        '13373094',
        '13373113',
        '13373131',
        '13373168',
        '13373208',
        '13373565',
        '13373247',
        '13373265',
        '13373302',
        '13373320',
        '13373396',
        '13373415',
        '13373508',
        '13373527',
        '13373546',
        '13373228',
        '13373584',
        '13373611',
        '13373186',
        '13373056',
        '13373284',
        '13373340',
        '13373358',
        '13373454',
        '13373433',
        '13372973',
      ],
      criteo: {
        '728x90': 1345888,
      },
      ix: [
        null,
        ['15', '234297'],
        ['26', '234308'],
        ['37', '234319'],
        ['59', '234341'],
        ['334', '234616'],
        ['70', '234352'],
        ['147', '234429'],
        ['81', '234363'],
        ['103', '234385'],
        ['279', '234561'],
        ['114', '234396'],
        ['125', '234407'],
        ['136', '234418'],
        ['158', '234440'],
        ['180', '234462'],
        ['378', '234660'],
        ['202', '234484'],
        ['213', '234495'],
        ['235', '234517'],
        ['246', '234528'],
        ['290', '234572'],
        ['301', '234583'],
        ['345', '234627'],
        ['356', '234638'],
        ['367', '234649'],
        ['191', '234473'],
        ['389', '234671'],
        [null, '282112'],
        ['169', '234451'],
        ['92', '234374'],
        ['224', '234506'],
        ['257', '234539'],
        ['268', '234550'],
        ['323', '234605'],
        ['312', '234594'],
        ['48', '234330'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  vip_img_bot: {
    default: {
      improvedigital: [
        null,
        '837679',
        '837680',
        '837681',
        '837685',
        '837888',
        '837686',
        '837850',
        '837687',
        '837800',
        '837883',
        '837801',
        '837803',
        '837846',
        '837851',
        '837865',
        '837892',
        '837867',
        '837868',
        '837870',
        '837871',
        '837884',
        '837885',
        '837889',
        '837890',
        '837891',
        '837866',
        '837893',
        '837802',
        '837853',
        '837690',
        '837869',
        '837872',
        '837882',
        '837887',
        '837886',
        '837684',
      ],
      rubicon: [
        null,
        '709986',
        '710012',
        '710028',
        '710078',
        '711884',
        '710138',
        '711120',
        '710144',
        '710956',
        '711652',
        '710994',
        '711042',
        '711086',
        '711150',
        '711310',
        '711648',
        '711362',
        '711398',
        '711492',
        '711526',
        '711912',
        '711812',
        '711758',
        '711722',
        '711684',
        '711336',
        '711620',
        '994108',
        '711182',
        '710900',
        '711448',
        '711548',
        '711572',
        '711862',
        '711840',
        '710056',
      ],
      appnexus: [
        null,
        '12261267',
        '12261279',
        '12261290',
        '12261326',
        '12261754',
        '12261344',
        '12261425',
        '12261355',
        '12261380',
        '12261696',
        '12261391',
        '12261403',
        '12261414',
        '12261482',
        '12261505',
        '12261820',
        '12261610',
        '12261625',
        '12261648',
        '12261659',
        '12261707',
        '12261718',
        '12261765',
        '12261776',
        '12261809',
        '12261594',
        '12261831',
        '13566083',
        '12261494',
        '12261366',
        '12261636',
        '12261670',
        '12261681',
        '12261741',
        '12261729',
        '12261315',
      ],
      tmp: [
        null,
        '13372907',
        '13372929',
        '13372951',
        '13372996',
        '13373477',
        '13373015',
        '13373150',
        '13373036',
        '13373076',
        '13373377',
        '13373094',
        '13373113',
        '13373131',
        '13373168',
        '13373208',
        '13373565',
        '13373247',
        '13373265',
        '13373302',
        '13373320',
        '13373396',
        '13373415',
        '13373508',
        '13373527',
        '13373546',
        '13373228',
        '13373584',
        '13373611',
        '13373186',
        '13373056',
        '13373284',
        '13373340',
        '13373358',
        '13373454',
        '13373433',
        '13372973',
      ],
      criteo: {
        '728x90': 1250885,
      },
      ix: [
        null,
        ['15', '234297'],
        ['26', '234308'],
        ['37', '234319'],
        ['59', '234341'],
        ['334', '234616'],
        ['70', '234352'],
        ['147', '234429'],
        ['81', '234363'],
        ['103', '234385'],
        ['279', '234561'],
        ['114', '234396'],
        ['125', '234407'],
        ['136', '234418'],
        ['158', '234440'],
        ['180', '234462'],
        ['378', '234660'],
        ['202', '234484'],
        ['213', '234495'],
        ['235', '234517'],
        ['246', '234528'],
        ['290', '234572'],
        ['301', '234583'],
        ['345', '234627'],
        ['356', '234638'],
        ['367', '234649'],
        ['191', '234473'],
        ['389', '234671'],
        [null, '282112'],
        ['169', '234451'],
        ['92', '234374'],
        ['224', '234506'],
        ['257', '234539'],
        ['268', '234550'],
        ['323', '234605'],
        ['312', '234594'],
        ['48', '234330'],
      ],
    },
    mobile: {
      improvedigital: [],
    },
  },
  soi_top: {
    default: {
      improvedigital: ['1067497'],
      rubicon: ['732460'],
      appnexus: ['12261254'],
      appnexus_weborama: [
        '29675659',
        '29675664',
        '29675669',
        '29675674',
        '29675679',
        '29675684',
        '29675689',
        '29675694',
        '29675699',
        '29675704',
        '29675709',
        '29675714',
        '29675719',
        '29675724',
        '29675729',
        '29675734',
        '29675739',
        '29675744',
        '29675749',
        '29675754',
        '29675759',
        '29675764',
        '29675769',
        '29675774',
        '29675779',
        '29675784',
        '29675789',
        '29675794',
        '29675800',
        '29675806',
        '29675813',
        '29675819',
        '29675825',
        '29675831',
        '29675837',
        '29675842',
        '29675848',
      ],
      tmp: ['13373363'],
      criteo: {
        '728x90': 1250889,
        '300x250': 1250913,
        '320x50': 1250914,
        '320x100': 1257306,
        '320x240': 1257307,
      },
      ix: [['2', '234284']],
      adhese: ['_marktplaats.nl_web_home_'],
    },
    mobile: {
      improvedigital: ['1068051'],
    },
  },
};

export default placementIds;
