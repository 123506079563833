import W from '../globals/Window';
import D from '../globals/Document';
import addEventListener from '../helpers/addEventListener';
import isTrustedOrigin from '../helpers/isTrustedOrigin';
import getSlotTarget from '../v6/helpers/getSlotTarget';

/**
 * These classes are used for out partner Weborama
 * in case you decide to delete the implementation because you can't find any code in github.
 * Don't, weborama uses these classes to style their banners
 */
function setBreakoutClasses(formatName) {
  const pageWrapper = D.getElementById('page-wrapper');

  if (!pageWrapper) {
    return;
  }

  pageWrapper.classList.add('break-out-banner');
  pageWrapper.classList.add(formatName);
}

function collapsePosition(position) {
  const hideEl = D.getElementById(getSlotTarget(position));

  if (hideEl) {
    hideEl.style.display = 'none';
  }
}

/**
 * Adds an event listener that will set classes to adjust the layout
 * of the page based on messages emitted by ads using the IAB's SafeFrame
 * specification
 *
 * To see this in action you can go to https://www.marktplaats.nl/l/sport-en-fitness/overige-sport-en-fitness/
 * and then paste the following into the console
 *
 * var event = new MessageEvent('message', {
 *    origin: `https://${window.location.hostname}`,
 *    data: 'collapse:lrp_video',
 *  });
 *
 * // Dispatch the event.
 * window.dispatchEvent(event);
 *
 * These event are created by our B2C team in our GAM, for any questions contact them in #bnl-b2c-buyers chat
 */
const setupSafeFrameMessaging = () => {
  addEventListener((event) => {
    if (isTrustedOrigin(event.origin)) {
      switch (event.data) {
        case 'collapse:lrp_video':
        case '{"teadsMsg": "noAd"}':
          collapsePosition('lrp_video');
          break;
        case 'format:skinLeaderboard':
          setBreakoutClasses('skin-leaderboard');
          break;
        case 'format:skinHeader':
          setBreakoutClasses('skin-header');
          break;
        case 'format:skinBillboard':
          setBreakoutClasses('skin-billboard');
          break;
        case 'format:APTO':
          W.dispatchEvent(new CustomEvent('banner-breakout'));
          break;
        default:
      }
    }
  });
};

export default setupSafeFrameMessaging;
