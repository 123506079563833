import mapAdSlotConfig from './mapAdSlotConfig';

const buildAdUnitForPreBid = (adSlotsData) => {
  const targets = adSlotsData.map((adSlotData) => adSlotData.target);

  return adSlotsData
    .filter((adSlot) => targets.indexOf(adSlot.target) >= 0)
    .map(mapAdSlotConfig)
    .filter(Boolean);
};

export default buildAdUnitForPreBid;
