/**
 * bidCpmAdjustment is used for adjusting a bidders gross-price bid to net price.
 *
 * We use this because some bidders return gross prices instead of the net prices
 * (what the publisher will actually get paid). For example, a publisher’s net price might be 18%
 * below the returned gross price. In this case, we want to adjust the bidder’s returned price
 * to run a true header bidding auction.
 *
 * Otherwise, this bidder’s gross price will unfairly win over your other demand sources
 * who report the real price.
 *
 * @documentation https://docs.prebid.org/dev-docs/publisher-api-reference/bidderSettings.html#2-bidder-setting-attributes
 */

const adjustBidPrices = (pbjs: any, tenantData: TTenantData) => {
  pbjs.bidderSettings = tenantData.BID_ADJUSTMENTS_CONFIG;
};

export default adjustBidPrices;
