import SSP_PROVIDERS from '../v6/constants/SSP_PROVIDERS';
import getConfig from '../requestAds/getConfig';

const {
  SSP_IMPROVE,
  SSP_RUBICON,
  SSP_APPNEXUS,
  SSP_TMP,
  SSP_INDEXEXCHANGE,
  SSP_CRITEO,
  SSP_ADHESE,
  SSP_GUMGUM,
  SSP_APPNEXUS_WEBORAMA,
} = SSP_PROVIDERS;

const sspProvidersMP = [
  SSP_RUBICON,
  SSP_APPNEXUS,
  SSP_TMP,
  SSP_INDEXEXCHANGE,
  SSP_CRITEO,
  SSP_ADHESE,
  SSP_APPNEXUS_WEBORAMA,
  SSP_GUMGUM,
];

const { isConsentPresent, enableNonCompliantSsps } = getConfig();

if (isConsentPresent || enableNonCompliantSsps) {
  sspProvidersMP.push(SSP_IMPROVE);
}

export default sspProvidersMP;
