import getConfig from '../../requestAds/getConfig';
import debugMessage from '../tools/debugMessage';

/**
 * Function used for setting the rsi segments from Adobe DMP to the banner parameters
 * on the client side.
 *
 * @param rsiSegments - Array of segments or a pipe separated string of segments
 */
const addAdobeRsiSegments = (rsiSegments: Array<string> | string = []): void => {
  const config = getConfig();
  const segments = Array.isArray(rsiSegments) ? rsiSegments : rsiSegments.split('|');
  const bannerSlots = Object.values(config.bannerData || {});

  if (!rsiSegments || !segments.length) {
    debugMessage(DEBUG_NAMESPACE, `No Adobe DMP rsi segments provided`, segments);

    return;
  }

  bannerSlots.forEach((position: TBannerConfig) => {
    if (position.bannerParameters) {
      position.bannerParameters.wl = segments;
    }
  });

  debugMessage(DEBUG_NAMESPACE, `Adobe DMP rsi segments updated with`, segments);
};

export default addAdobeRsiSegments;
