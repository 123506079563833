import getConfig from '../../requestAds/getConfig';

/**
 * Helper to get the elements to hide
 */
const getElementsToHide = (position: string = ''): string => {
  const config = getConfig();
  const slotConfig = config.bannerData?.[position];

  return slotConfig?.hideEl || '';
};

export default getElementsToHide;
