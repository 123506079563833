import window from '../globals/Window';

export const IMPRESSIONS_COUNTER = 'MpImpressionCounter';

/**
 * The impression count is set to `bannerParameters.sf` for each ad slot.
 * See GLOSSARY.md for more information.
 */
const setImpressionsCount = () => {
  const impressionsCounter = window.localStorage.getItem(IMPRESSIONS_COUNTER) || '{}';

  let counterData = JSON.parse(impressionsCounter);
  let date: number | Date = Date.now();
  let total = 0;
  const today = new Date();

  if (counterData) {
    date = new Date(counterData.date);
    ({ total } = counterData);
  } else {
    date = new Date(date);
  }

  if (date.getDate() !== today.getDate()) {
    total = 1;
    date = today;
  } else {
    total += 1;
  }

  counterData = {
    total,
    date: date.getTime(),
  };

  window.localStorage.setItem(IMPRESSIONS_COUNTER, JSON.stringify(counterData));

  return counterData.total;
};

export default setImpressionsCount;
