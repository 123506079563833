const didReachMaxTimeout = (startTimeStamp, maxTimeout) => {
  const currentTimestamp = Date.now();
  const millisecondsPassed = currentTimestamp - startTimeStamp;

  return millisecondsPassed > maxTimeout;
};

const DEFAULT_MAX_TIMEOUT_MS = 15000;
const DEFAULT_POLL_INTERVAL_MS = 20;

const intervalCheck: TCheckForDependenciesWithInterval = ({
  dependencies,
  maxTimeout = DEFAULT_MAX_TIMEOUT_MS,
} = {}) => {
  return new Promise((resolve, reject) => {
    if (!dependencies?.length) {
      resolve('No dependencies, continue loading.');

      return;
    }

    const checkIfDepsAreLoaded = () => dependencies.every((checkDependency) => checkDependency());

    if (checkIfDepsAreLoaded()) {
      resolve('All dependencies loaded.');
      return;
    }

    const startTimeStamp = Date.now();
    const intervalId = setInterval(() => {
      const didTimeOut = didReachMaxTimeout(startTimeStamp, maxTimeout);
      const areAllDependenciesLoaded = checkIfDepsAreLoaded();

      if (areAllDependenciesLoaded) {
        clearInterval(intervalId);

        resolve('All dependencies loaded.');

        return;
      }

      if (didTimeOut) {
        clearInterval(intervalId);

        reject('Reached max timeout.');
      }
    }, DEFAULT_POLL_INTERVAL_MS);
  });
};

export default intervalCheck;
