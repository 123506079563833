import getConfig from '../../requestAds/getConfig';
import debugMessage from '../tools/debugMessage';

const DEBUG_NAMESPACE = 'gpt.js';

const injectGPTforNewUsers = () => {
  const config = getConfig();
  const isPubmaticWrapperEnabled = config?.experiments?.isPubmaticWrapperEnabled || false;
  if (isPubmaticWrapperEnabled) {
    debugMessage(DEBUG_NAMESPACE, 'Skipping injectGPTforNewUsers, as Pubmatic is enabled');
    return;
  }

  const scriptLoaded = document.querySelector(`script[src~="${config.gptUrl}"]`);

  if (scriptLoaded) {
    debugMessage(DEBUG_NAMESPACE, 'gpt.js was not injected as gpt.js has already been loaded');
    return false;
  }

  const gptJsScriptTag = document.createElement('script');
  gptJsScriptTag.async = true;
  gptJsScriptTag.type = 'text/javascript';
  gptJsScriptTag.src = config.gptUrl;

  document.body.appendChild(gptJsScriptTag);

  debugMessage(DEBUG_NAMESPACE, 'gpt.js was injected as gpt.js was not loaded', config.gptUrl);

  return true;
};

export default injectGPTforNewUsers;
