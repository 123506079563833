/* eslint-disable no-magic-numbers */
import W from '../globals/Window';
import googleTag from '../globals/googletag';
import pbjs from '../globals/pbjs';
import { addMetrics, addPerformanceMetric } from '../v6/metrics/bannerMetrics';
import eventLogger from './eventLogger';
import { getSlotReference, getAdUnitReference } from './slotReferences';
import getConfig from './getConfig';
import SSP_PROVIDERS from '../v6/constants/SSP_PROVIDERS';
import getTCData from './getTCData';
import debugMessage from '../v6/tools/debugMessage';
import tenantData from '../locales';
import setupCurrencyModule from '../v6/prebid/setupCurrencyModule';
import adjustBidPrices from '../v6/prebid/adjustBidPrices';
import buildAdUnitForPreBid from '../v6/adSlots/buildAdUnitForPreBid';
import setupSupplyChainModule from '../v6/prebid/setupSupplyChainModule';
import SUPPLY_CHAIN_NODES from '../v6/constants/SUPPLY_CHAIN_NODES';

const TIME_OUT_KEY = 'to';
const DEBUG_NAMESPACE = 'gptPrebid';
const config = getConfig();
const PREBID_FAILSAFE_TIMEOUT = 'customPrebidFailsafeTimeout';
let bannerRefreshEventMeasured = false;

const isPubmaticWrapperEnabled = config?.experiments?.isPubmaticWrapperEnabled || false;
debugMessage(DEBUG_NAMESPACE, 'isPubmaticWrapperEnabled', isPubmaticWrapperEnabled);

function checkErroneousBidSize(bid) {
  if (W.innerWidth < 1750 && config.vurl !== 'LandingPageCategory') {
    eventLogger({
      eventAction: 'Wrong bid size',
      eventLabel: `${bid.bidder}-${bid.creativeId}-${bid.adUnitCode}`,
    });
  }
}

/**
 * Requests ads from Google Ad Manager
 *
 * @param targets {string[]} - targets returned from the `gptPrebid.requestBids` function
 *  it will look like this `['banner-top-dt', 'banner-right']`
 * @param isTimedOut {boolean} - Sets targeting on all GPT slots with the
 *  key `to` and value `1` or `0`
 * @param bids {PrebidBid[]} - bids returned from prebid. Refer to the following link for more
 *  information on each individual property.
 *  https://docs.prebid.org/dev-docs/publisher-api-reference/getBidResponses.html
 */
function requestAds(targets: Array<string> = [], isTimedOut, bids) {
  const slots: Array<TBannerConfig> = [];
  const adUnitCodes: Array<string> = [];

  /**
   * Sifts through each target and pushes targets with
   * both adUnitCode & slot defined.
   *
   * https://jira.es.ecg.tools/browse/BNL-13051
   * Investigate why this is the case:
   * Are some adUnitCodes without slots and vice versa? When does this case happen?
   */
  targets.forEach((target) => {
    const { adUnitCode } = getAdUnitReference(target) || false;
    const { slot } = getSlotReference(target) || false;

    /**
     * This check is needed in case the slot returns null
     * More info here -> https://developers.google.com/publisher-tag/reference?hl=en#googletag.defineSlot
     */
    if (adUnitCode && slot) {
      /**
       * adUnitCodes are used for setting targeting params for the slot
       * using `setTargeting` from GPT
       *
       * Prebid uses ad unit codes that look like this `/4282/web_nl/1099/srp/left`
       * to generate an object that looks like this.
       *   {
       *     "/4282/web_nl/1099/srp/left": {
       *       hb_adid: "35767ac2423396c2"
       *       hb_adid_rubicon: "34687fb545f37c4a"
       *       hb_bidder: "ix"
       *       hb_bidder_rubicon: "rubicon"
       *       hb_format: "banner"
       *       hb_format_rubicon: "banner"
       *       hb_pb: "0.47"
       *       hb_pb_rubicon: "0.18"
       *       hb_size: "120x600"
       *       hb_size_rubicon: "120x600"
       *       hb_source: "client"
       *       hb_source_rubicon: "client"
       *     }
       *   }
       * Then prebid calls `slot.setTargeting(key, value);` on each key value pair
       * for the slot so GPT can receive the targeting data.
       */
      adUnitCodes.push(adUnitCode);
      /**
       * Slot is an object representing single ad slot on a page.
       *
       * @documentation https://developers.google.com/publisher-tag/reference#googletag.slot
       */
      slots.push(slot);
    }
  });

  if (bids) {
    Object.keys(bids).forEach((adUnit) => {
      bids[adUnit].bids.forEach((bid) => {
        if (bid.width === 1800 && bid.height === 1000) {
          bid.width = 728; // eslint-disable-line no-param-reassign
          bid.height = 90; // eslint-disable-line no-param-reassign
          checkErroneousBidSize(bid);
        } else if (bid.width === 970 && bid.height === 1000) {
          bid.width = 970; // eslint-disable-line no-param-reassign
          bid.height = 250; // eslint-disable-line no-param-reassign
          checkErroneousBidSize(bid);
        }
      });
    });
  }

  if (adUnitCodes.length) {
    if (isPubmaticWrapperEnabled) {
      debugMessage(
        DEBUG_NAMESPACE,
        'Pubmatic Wrapper is enabled, calling requestAds without interfering with pbjs, bids will be taken care of by Pubmatic Wrapper',
        slots,
      );

      googleTag.cmd.push(() => {
        googleTag
          /**
           * Returns a reference to the pubads service.
           *
           * @documentation https://developers.google.com/publisher-tag/reference#googletag.PubAdsService
           */
          .pubads()
          /**
           * Sets custom targeting parameters for a given key that apply to all pubads
           * service ad slots. Calling this multiple times for the same key will overwrite
           * old values. These keys are defined in your Google Ad Manager account.
           *
           * @documentation https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_setTargeting
           */
          .setTargeting(TIME_OUT_KEY, W.isPubmaticTimedOut ? '1' : '0');

        googleTag
          /**
           * Returns a reference to the pubads service.
           *
           * @documentation https://developers.google.com/publisher-tag/reference#googletag.PubAdsService
           */
          .pubads()
          /**
           * Fetches and displays new ads for specific or all slots on the page. Works only in
           * asynchronous rendering mode
           *
           * @documentation https://developers.google.com/publisher-tag/reference#refreshopt_slots,-opt_options
           */
          .refresh(slots);

        if (
          /**
           * This event is tracked only once. It's because of a need to compare with "bidsRequested" metric.
           * However, the ads might be requested more than once.
           * E.g. with the case on VIP, when vip_bot is showed lazily after a scrolling down to it.
           */
          !bannerRefreshEventMeasured
        ) {
          /**
           * Logs to the `adsRequested` metric in Grafana
           */
          addPerformanceMetric('adsRequested');

          bannerRefreshEventMeasured = true;
        }
      });
    } else {
      googleTag.cmd.push(() => {
        googleTag
          /**
           * Returns a reference to the pubads service.
           *
           * @documentation https://developers.google.com/publisher-tag/reference#googletag.PubAdsService
           */
          .pubads()
          /**
           * Sets custom targeting parameters for a given key that apply to all pubads
           * service ad slots. Calling this multiple times for the same key will overwrite
           * old values. These keys are defined in your Google Ad Manager account.
           *
           * @documentation https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_setTargeting
           */
          .setTargeting(TIME_OUT_KEY, isTimedOut ? '1' : '0');

        /**
         * `pbjs.que` is not part of prebid's api's documentation or at least I couldn't find it.
         * I only found it in the examples. But this is a global Array we have to define on the
         * `pbjs` global ourselves.
         *
         * It is then accessed by the prebid library on line 1004 of the loadCmp.js
         * library.
         *
         * @prebidGithub https://github.com/prebid/Prebid.js/blob/master/src/prebid.js#L1004
         */
        pbjs.que.push(() => {
          /**
           * Set query string targeting on GPT ad units after the auction.
           * This function matches AdUnits that have returned from the auction to a GPT ad slot
           * and adds the hb_ targeting attributes to the slot so they get sent to GAM.
           *
           * Uses `slot.setTargeting(key, value);` from GPT to set key values for a slot for
           * each bid, that will look something like this.
           *
           * key: `hb_bidder`               value: `ix`
           * key: `hb_format`               value: `banner`
           * key: `hb_source`               value: `client`
           * key: `hb_size`                 value: `728x90`
           * key: `hb_pb`                   value: `0.83`
           * key: `hb_adid`                 value: `199c0c23914e578`
           *
           * key: `hb_bidder_improvedig`    value: `improvedigital`
           * key: `hb_format_improvedig`    value: `banner`
           * key: `hb_source_improvedig`    value: `client`
           * key: `hb_size_improvedigit`    value: `970x250`
           * key: `hb_pb_improvedigital`    value: `0.50`
           * key: `hb_adid_improvedigit`    value: `132af3dfedb8f56`
           *
           * key: `hb_bidder_ix`            value: `ix`
           * key: `hb_format_ix`            value: `banner`
           * key: `hb_source_ix`            value: `client`
           * key: `hb_size_ix`              value: `728x90`
           * key: `hb_pb_ix`                value: `0.83`
           * key: `hb_adid_ix`              value: `199c0c23914e578`
           *
           * @code https://github.com/prebid/Prebid.js/blob/master/src/targeting.js#L393
           * @documentation https://docs.prebid.org/dev-docs/publisher-api-reference/setTargetingForGPTAsync.html
           * @documentation https://developers.google.com/publisher-tag/reference#googletag.pubadsservice-settargetingkey,-value
           */
          pbjs.setTargetingForGPTAsync(adUnitCodes);

          googleTag
            /**
             * Returns a reference to the pubads service.
             *
             * @documentation https://developers.google.com/publisher-tag/reference#googletag.PubAdsService
             */
            .pubads()
            /**
             * Fetches and displays new ads for specific or all slots on the page. Works only in
             * asynchronous rendering mode
             *
             * @documentation https://developers.google.com/publisher-tag/reference#refreshopt_slots,-opt_options
             */
            .refresh(slots);

          if (
            /**
             * This event is tracked only once. It's because of a need to compare with "bidsRequested" metric.
             * However, the ads might be requested more than once.
             * E.g. with the case on VIP, when vip_bot is showed lazily after a scrolling down to it.
             */
            !bannerRefreshEventMeasured
          ) {
            /**
             * Logs to the `adsRequested` metric in Grafana
             */
            addPerformanceMetric('adsRequested');

            bannerRefreshEventMeasured = true;
          }
        });
      });
    }
  }
}

const enableUsePrebidSizes = () => {
  pbjs.setConfig({
    improvedigital: {
      /**
       * By default, the adapter doesn’t send Prebid ad unit sizes to Improve Digital’s ad server
       * and the sizes defined for each placement in the 360Polaris platform will be used. If the ad
       * server should only respond with creative sizes as defined in Prebid ad unit configuration,
       * turn on usePrebidSizes
       *
       * @platform https://www.improvedigital.com/360polaris/
       * @documentation https://docs.prebid.org/dev-docs/bidders/improvedigital.html#configuration
       */
      usePrebidSizes: true,
    },
  });
};

const checkUserConsent = () => {
  pbjs.setConfig({
    consentManagement: {
      /**
       * cmpApi is the CMP interface that is in use.
       * Supported values are ‘iab’ or ‘static’. Static allows integrations where IAB-formatted consent
       * strings are provided in a non-standard way. Default is 'iab'.
       *
       * We use static because we don't want to wait for the iab's CMP interface to load.
       * Since the tcString is already available in the MpTCString cookie, we just get it and
       * construct the users purposes and vendor preferences
       */
      cmpApi: 'static',
      consentData: {
        /**
         * getTCData should reflect the TCData data model link below. However PreBid only uses `tcString`,
         * `addtlConsent`, `gdprApplies`, `purpose` and `vendor`.
         *
         * @dataModel https://github.com/InteractiveAdvertisingBureau/iabtcf-es/blob/master/modules/cmpapi/src/response/TCData.ts
         * @documentation https://docs.prebid.org/dev-docs/modules/consentManagement.html#bidder-adapter-gdpr-integration
         */
        getTCData: config.experiments.useSourcePointCMP ? W._sp_lib.TCData : getTCData(),
      },
    },
  });
};

const setTargetingControlsForBackwardCompatability = () => {
  /**
   * 4. Prebid.js 5.0 changes the default keys sent to the ad server.
   * If publishers are currently leveraging the hb_cache_id or hb_source in any capacity they should be sure to add these keys back
   * into their configurations using setConfig in the page when upgrading to 5.0. While it’s recommended that publishers take this
   * opportunity to review which targeting variables they need, you can get all available banner targeting variables by adding this
   * command to the page:
   *
   * @link https://prebid.org/blog/prebid-5-0-release/
   */
  pbjs.setConfig({
    targetingControls: {
      allowTargetingKeys: [
        'BIDDER',
        'AD_ID',
        'PRICE_BUCKET',
        'SIZE',
        'DEAL',
        'SOURCE',
        'FORMAT',
        'UUID',
        'CACHE_ID',
        'CACHE_HOST',
        'ADOMAIN',
      ],
    },
  });
};

const setPrebidConfigs = () => {
  setTargetingControlsForBackwardCompatability();
  setupCurrencyModule(pbjs);
  adjustBidPrices(pbjs, tenantData);
  checkUserConsent();
  enableUsePrebidSizes();

  pbjs.setConfig({
    /**
     * Sets the global bidder timeout, defaults to 1.5 seconds
     */
    bidderTimeout: config.bidderTimeout,
    /**
     * Index Exchange config,
     */
    ix: {
      /**
       * Setting a server-side timeout allows you to control the max
       * length of time taken to connect to the server. The default
       * value when unspecified is 50ms.
       *
       * This is distinctly different from the global bidder
       * timeout that can be set in Prebid.js in the browser.
       */
      timeout: config.bidderTimeout,
    },
    /**
     * This configuration defines the price bucket granularity setting
     * that will be used for the hb_pb keyword.
     *
     * `high` is $0.01 increments, capped at $20 CPM
     */
    priceGranularity: 'high',
  });

  /**
   * It seems cbTimeout was removed but it used to be the time out for callbacks
   * set in `requestBids`
   *
   * @documentation https://github.com/prebid/Prebid.js/pull/3028
   */
  pbjs.cbTimeout = config.bidderTimeout;

  if (config.experiments.enableTmp) {
    /**
     * Tmp Worldwide is a partner of AppNexus (Acquired by Xandr in June 2018).
     * Here we are aliasing the App Nexus adapter to Tmp Worldwide so we can use the same
     * adapter for header bidding.
     *
     * @link https://docs.xandr.com/bundle/service-policies/page/third-party-providers.html
     * @documentation https://docs.prebid.org/dev-docs/publisher-api-reference/aliasBidder.html
     */
    pbjs.aliasBidder(SSP_PROVIDERS.SSP_APPNEXUS, SSP_PROVIDERS.SSP_TMP);
  }

  pbjs.aliasBidder(SSP_PROVIDERS.SSP_APPNEXUS, SSP_PROVIDERS.SSP_APPNEXUS_WEBORAMA);

  const userSyncEnabledConfig = {
    /**
     * filterSettings Configures lists of adapters to include or
     * exclude their user syncing based on the pixel type (image/iframe).
     */
    filterSettings: {
      iframe: {
        bidders: [SSP_PROVIDERS.SSP_IMPROVE],
        filter: 'exclude',
      },
      image: {
        bidders: [SSP_PROVIDERS.SSP_IMPROVE],
        filter: 'include',
      },
    },
  };

  const userSyncDisabledConfig = {
    syncEnabled: false,
  };

  pbjs.setConfig({
    /**
     * The user sync configuration options in this section give publishers control over
     * how adapters behave with respect to dropping pixels or scripts to cookie users with IDs.
     *
     * This practice is called “user syncing” because the aim is to let the bidders match IDs between
     * their cookie space and the DSP’s cookie space. There’s a good reason for bidders to be doing this
     * – DSPs are more likely to bid on impressions where they know something about the history of the user.
     * However, there are also good reasons why publishers may want to control the use of these practices:
     *
     * Page performance: Publishers may wish to move ad-related cookie work to much later in the page load after
     *  ads and content have loaded.
     * User privacy: Some publishers may want to opt out of these practices even though it limits their users’
     *  values on the open market.
     * Security: Publishers may want to control which bidders are trusted to inject images and JavaScript into
     *  their pages.
     */
    userSync: config.isConsentPresent ? userSyncEnabledConfig : userSyncDisabledConfig,
  });
  setupSupplyChainModule(pbjs, SUPPLY_CHAIN_NODES.WEBORAMA);
};

/**
 * Failsafe Timeout - This is a timeout entirely outside of Prebid.js.
 * It’s a JavaScript setTimeout() that publishers should consider establishing after the Prebid.js code is loaded.
 * It’s a safety net that invokes the ad server callback in case something goes wrong.
 * In all regular scenarios, Prebid.js will have already invoked the callback before this timeout is reached.
 * This value should be much larger than the auction timeout.
 *
 * https://docs.prebid.org/features/timeouts.html
 */
function setPrebidFailsafeTimeout(timeout: number): Promise<TPrebidResult> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        bids: null,
        isTimedOut: true,
      });
    }, timeout);
  });
}

function requestBidsHandler(adSlotsData, isRefreshAction): Promise<TPrebidResult> {
  return new Promise((resolve) => {
    const preBidAdUnits = buildAdUnitForPreBid(adSlotsData);

    const adUnitCodes = preBidAdUnits.map((value) => value.code);

    debugMessage(DEBUG_NAMESPACE, 'Prebid Ad Units', preBidAdUnits, adUnitCodes);
    /**
     * `pbjs.que` is not part of prebid's api's documentation or at least I couldn't find it.
     *  I only found it in the examples. But this is a global Array we have to define on the
     * `pbjs` global ourselves.
     *
     * It is then accessed by the prebid library on line 1004 of the loadCmp.js
     * library.
     *
     * @prebidGithub https://github.com/prebid/Prebid.js/blob/master/src/prebid.js#L1004
     */
    pbjs.que.push(() => {
      if (
        /**
         * We don't need to add the preBidAdUnits again if we are only refreshing bids
         */
        !isRefreshAction
      ) {
        /**
         * addAdUnits Takes one ad unit object or an array of ad unit objects and adds
         * them to the Prebid auction.
         */
        pbjs.addAdUnits(preBidAdUnits);
      }

      /**
       * Request bids.
       *
       * When adUnits or adUnitCodes are not specified it will request bids for all ad units added.
       */
      pbjs.requestBids({
        /**
         * Callback to execute when all the bid responses are back or the timeout hits.
         * Callback will be passed three parameters, the bidResponses themselves, a
         * timedOut flag (true if any bidders timed out) and the auctionId.
         *
         * @param bids {object}
         */
        bidsBackHandler: (bids) => {
          resolve({ bids });
        },
        /**
         * adUnit codes, this is an array of strings to request.
         * Default to all adUnitCodes if empty.
         */
        adUnitCodes,
        /**
         * Timeout for requesting the bids specified in milliseconds
         * Investigate if we can use this to remove the `Promise.race`
         * requestBids(adSlotsData, isRefreshAction)` function
         * https://jira.es.ecg.tools/browse/BNL-13052
         */
        // timeout: config.prebidTimeout,
      });
    });
  });
}

export default {
  initPrebid() {
    if (isPubmaticWrapperEnabled) {
      debugMessage(DEBUG_NAMESPACE, 'Pubmatic Wrapper is enabled, skipping initPrebid');
      return;
    }

    debugMessage(DEBUG_NAMESPACE, 'initPrebid function is called');

    setPrebidConfigs();
  },

  requestBids(adSlotsData: Array<TBannerConfig>, isRefreshAction?: boolean): Promise<TPrebidResult | undefined> {
    const targets: Array<string | undefined> = adSlotsData.map((adSlotData) => adSlotData.target);

    if (isPubmaticWrapperEnabled) {
      debugMessage(
        DEBUG_NAMESPACE,
        'Pubmatic Wrapper is enabled, calling requestBids as we still need targets to communicate with GAM',
        targets,
      );

      try {
        return new Promise((resolve) => {
          resolve({
            targets,
          });
          return;
        });
      } catch (error) {
        debugMessage(DEBUG_NAMESPACE, 'Error in requesting bids', error);
      }
    }

    return new Promise((resolve) => {
      if (adSlotsData.length === 0) {
        resolve({});
        return;
      }

      debugMessage(DEBUG_NAMESPACE, 'Requesting bids using', adSlotsData);

      Promise.race([
        requestBidsHandler(adSlotsData, isRefreshAction),
        setPrebidFailsafeTimeout(config.failsafeTimeoutDuration),
      ])
        .then((result) => {
          if (result.isTimedOut) {
            debugMessage(DEBUG_NAMESPACE, `Timed out`, adSlotsData);
            addMetrics({ [PREBID_FAILSAFE_TIMEOUT]: 1, timeout: config.failsafeTimeoutDuration });
          }

          resolve({
            targets,
            timeOut: result.isTimedOut || false,
            bids: result.bids,
          });
        })
        .catch((error) => {
          debugMessage(DEBUG_NAMESPACE, 'Error in requesting bids', error);
        });
    });
  },

  requestAds,
};
