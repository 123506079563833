import { getSlotReference } from '../../requestAds/slotReferences';
import setAdSlotProperty from './setAdSlotProperty';
import debugMessage from '../tools/debugMessage';

/**
 * If a slot reference already exists,
 * we do not want to build the ad slot config again
 */
const doesSlotReferenceExist = (position: string, adSlot: TBannerConfig): boolean => {
  if (getSlotReference(adSlot.target)) {
    setAdSlotProperty(adSlot, 'delete', true);

    debugMessage(position, 'Slot reference exists already, set `adSlot.delete=true`');

    return true;
  }

  return false;
};

export default doesSlotReferenceExist;
