import isDebugMode from './helpers/isDebugMode';
import bannerLibVersion from './helpers/bannerLibVersion';
import getPillStyle from './getPillStyle';

/**
 * Outputs a debug message if the user has set the cookie MpDebug to true
 *
 * @param namespace
 * @param message
 * @param rest - any data to output
 */
const debugMessage = (namespace, message, ...rest) => {
  if (!isDebugMode()) {
    return;
  }

  const pillStyle = getPillStyle(namespace);

  // eslint-disable-next-line no-console
  console.debug(`%c${bannerLibVersion()}%c${namespace}%c${message}`, pillStyle, pillStyle, pillStyle, ...rest);
};

export default debugMessage;
