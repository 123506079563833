/**
 * Extracting out the logic for getting the correct function for adding
 * event listeners so we can reduce complexity
 *
 * @returns {function(*=): *}
 */
const addEventListener = (callback) => {
  /**
   * `addEventListener` is for IE9+
   * `attachEvent` is for IE8-, Opera
   */
  const eventMethodName = typeof window?.addEventListener === 'function' ? 'addEventListener' : 'attachEvent';

  /**
   * `message` is for IE9+
   * `onmessage` is for IE8-
   */
  const message = eventMethodName === 'addEventListener' ? 'message' : 'onmessage';

  return window?.[eventMethodName]?.(message, callback, false);
};

export default addEventListener;
