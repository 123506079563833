import hideBannerElement from '../helpers/hideBannerElement';
import setAdSlotProperty from './setAdSlotProperty';

/**
 * Used for marking an ad slot for deletion and hiding the banner element
 * associated with the ad slot
 */
const deleteSlotAndHideElement = (adSlot: TBannerConfig): void => {
  setAdSlotProperty(adSlot, 'delete', true);
  hideBannerElement(adSlot.hideEl);
};

export default deleteSlotAndHideElement;
