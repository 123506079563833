import SSP_PROVIDERS from './SSP_PROVIDERS';

const SUPPLY_CHAIN_NODES = {
  WEBORAMA: {
    bidder: SSP_PROVIDERS.SSP_APPNEXUS_WEBORAMA,
    asi: 'weborama.nl',
    sid: '10691',
  },
};

export default SUPPLY_CHAIN_NODES;
