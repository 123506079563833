import getConfig from '../../requestAds/getConfig';

/**
 * Helper to get the slot target
 */
const getSlotTarget = (position: string = ''): string => {
  const config = getConfig();
  const slotConfig = config.bannerData?.[position];

  return slotConfig?.target || '';
};

export default getSlotTarget;
