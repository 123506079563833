import W from '../globals/Window';
import pushConsentToDataLayer from './pushConsentToDataLayer';

/**
 * @BNL11160.B
 */
const createTcfApiConsentListener = async (callback) => {
  // we load the banners only when the user has TCData
  await W._sp_lib.waitForTCData();
  // emit custom event trigger to our GTM so we know if it is safe to load certain tags
  if (W.dataLayer && W._sp_lib.getConsentState(document.cookie).Google) {
    pushConsentToDataLayer(W);
  }
  callback();
};

export default createTcfApiConsentListener;
