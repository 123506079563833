import isDebugMode from './helpers/isDebugMode';
import bannerLibVersion from './helpers/bannerLibVersion';

/**
 * Creates a version indicator for debugging purposes
 */
const createVersionIndicator = () => {
  if (!isDebugMode()) {
    return;
  }

  const version = document.createElement('div');

  version.style.position = 'fixed';
  version.style.bottom = '0';
  version.style.left = '0';
  version.style.border = '6px solid red';
  version.style.fontSize = '30px';
  version.style.display = 'block';
  version.style.padding = '10px';
  version.style.background = 'white';
  version.style.fontWeight = 'bold';
  version.style.color = 'black';
  version.style.zIndex = '99999999';

  version.innerHTML = bannerLibVersion();

  document.body.appendChild(version);
};

export default createVersionIndicator;
