import debugMessage from '../../v6/tools/debugMessage';
import logSentryError from '../../v6/metrics/sentry/logSentryError';

const REFRESH_FAILED = 'refreshFailed';

const resetAllBannersInPageAdapter = ({ googleTag, eventLogger, setupPageWithBanners }) =>
  function resetAllBannersInPage() {
    try {
      debugMessage(`resetAllBannersInPage`, 'Resetting all banners');

      if (googleTag) {
        /**
         * googleTag only exists if an adblocker is not used.
         */
        googleTag.destroySlots();

        setupPageWithBanners(true);
      }
    } catch (error) {
      logSentryError(error);

      debugMessage(`resetAllBannersInPage`, 'Resetting banners failed', error);

      eventLogger({
        eventAction: REFRESH_FAILED,
      });
    }
  };

export default resetAllBannersInPageAdapter;
