import getPillColors from './getPillColors';

const getPillStyle = (namespace) => {
  const pillColors = getPillColors(namespace);

  return [
    'padding: 5px',
    'font-size: 10px',
    'border-radius: 3px',
    'margin-left: 5px',
    `color: ${pillColors.text}`,
    `background: ${pillColors.background}`,
  ].join('; ');
};

export default getPillStyle;
