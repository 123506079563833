const isBrowserViewportTooSmall = (minimumViewportWidth: number): boolean => {
  const browserViewportWidth =
    /**
     * Use the the interior width of the window in pixels
     * including the size of a rendered scroll bar
     */
    window.innerWidth ||
    /**
     * Fallback to using the client width of the <html> element
     */
    document.documentElement.clientWidth ||
    /**
     * Fallback to using the client width of the <body> element
     */
    document.body.clientWidth;

  /**
   * True if the minimumViewportWidth is wider than the width of the browser viewport
   */
  return Number(minimumViewportWidth) > browserViewportWidth;
};

export default isBrowserViewportTooSmall;
