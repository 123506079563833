import debugMessage from '../../v6/tools/debugMessage';
import { addPerformanceMetric } from '../../v6/metrics/bannerMetrics';

const setupAdSlotsAdapter = ({ gptPrebid, trackContainerGetsInView, defineSlots, allAdSlots }) =>
  function setupAdSlots(adSlotsData, pageLevel) {
    adSlotsData.forEach(() => addPerformanceMetric('customBidsRequestedPerAdUnit'));

    return Promise.all([defineSlots(adSlotsData, pageLevel), gptPrebid.requestBids(adSlotsData)]).then(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, requestBidsResult]) => {
        if (pageLevel) {
          trackContainerGetsInView(allAdSlots.data.filter((adSlot) => adSlot.lazyloading));
        }
        if (requestBidsResult) {
          debugMessage(DEBUG_NAMESPACE, 'requestBidsResult ', requestBidsResult);
          /**
           * logging metric to learn how many bid requests have returned valid bids
           */
          if (requestBidsResult.bids) {
            Object.keys(requestBidsResult.bids).forEach(() => addPerformanceMetric(`customWinningBidsReturned`));
          }
          gptPrebid.requestAds(requestBidsResult.targets, requestBidsResult.timeOut, requestBidsResult.bids);
        } else {
          debugMessage(DEBUG_NAMESPACE, 'requestBids failed: requestBidsResult is undefined', requestBidsResult);
        }
      },
    );
  };

export default setupAdSlotsAdapter;
