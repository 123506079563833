import W from '../globals/Window';

type TScreenSize = 'small' | 'medium' | 'large' | 'xlarge';

/**
 * Gets a screen size label using `window.matchMedia`
 *
 * This is used with our internal `prebidConfig.getAdUnit` function
 */
const getScreenSize = (): TScreenSize => {
  let screenSize = 'small';
  const screenDefinitions = [
    {
      query: '(min-width: 760px)',
      label: 'medium',
    },
    {
      query: '(min-width: 970px)',
      label: 'large',
    },
    {
      query: '(min-width: 1800px)',
      label: 'xlarge',
    },
  ];

  screenDefinitions.forEach(({ query, label }) => {
    if (W.matchMedia(query).matches) {
      screenSize = label;
    }
  });

  return <'small' | 'medium' | 'large' | 'xlarge'>screenSize;
};

export default getScreenSize;
