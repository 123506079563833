/**
 * Formats object values into arrays, if already in array, just use the value
 *
 * @param params
 */
const objectValuesToArray = (params) =>
  Object.keys(params).reduce<TPrebidKeyValues>((accumulator, currentKey): TPrebidKeyValues => {
    const param = params[currentKey];

    if (!param) {
      return accumulator;
    }

    /**
     * Directly assign values that are arrays
     */
    if (Array.isArray(param)) {
      accumulator[currentKey] = params[currentKey];
    }

    /**
     * Otherwise wrap all values in an array
     */
    accumulator[currentKey] = [`${param}`];

    return accumulator;
  }, {});

export default objectValuesToArray;
