/**
 * Pushes a consent event to the dataLayer.
 */
export const pushConsentToDataLayer = (windowObj: Window) => {
  const eventObj = { event: 'consentedToAllPurposes' };
  if (windowObj.ecGa) {
    windowObj.ecGa('pushToDataLayer', eventObj); // prefer using ecGa
  } else windowObj?.dataLayer?.push(eventObj); // fallback
};

export default pushConsentToDataLayer;
