/* eslint-disable no-magic-numbers */
import merge from 'lodash.merge';
import { isAllPurposesAccepted } from '@ecg-marktplaats/gdpr-consent-string';
import W from '../globals/Window';

type TBannerConfigWithDefaults = TMpBannerLibConfig & {
  failsafeTimeoutDuration: number;
  bidderTimeout: number;
  consentedVendors: TConsentState;
};
const { _sp_lib } = W;

const defaultConfig: TBannerConfigWithDefaults = {
  adblockerFile: '',
  bannerData: {},
  enableSingleRequest: false,
  publisherProvidedId: '',
  failsafeTimeoutDuration: 2000,
  bidderTimeout: 1500,
  categoryId: '',
  grafanaSuffix: '',
  gptUrl: '//securepubads.g.doubleclick.net/tag/js/gpt.js',
  mainScriptUrl: '',
  enableAnalytics: true,
  enableSentryErrorLogging: false,
  experiments: {
    useEcgJsBannersV6: true,
    useSourcePointCMP: false,
  },
  isConsentPresent: W.mpBannerLibConfig?.experiments?.useSourcePointCMP
    ? Boolean(_sp_lib.getConsentState(document.cookie).Google)
    : isAllPurposesAccepted(),
  enableNonCompliantSsps: false,
  malvertisingProtectionId: '',
  /**
   * Virtual Url, is an identifier for the current page
   * https://wiki.es.ecg.tools/display/ANYL/Custom+Dimensions
   */
  vurl: '',
  consentedVendors: _sp_lib?.getConsentState(document.cookie),
};

const createConfig = () => {
  return merge({}, defaultConfig, W.mpBannerLibConfig);
};

let globalMpBannerLibConfig: TBannerConfigWithDefaults = createConfig();

export default function getConfig(init?: boolean): TBannerConfigWithDefaults {
  if (init) {
    globalMpBannerLibConfig = createConfig();
  }
  return globalMpBannerLibConfig;
}
