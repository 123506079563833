/**
 * Slot is an object representing single ad slot on a page.
 *
 * @documentation https://developers.google.com/publisher-tag/reference#googletag.slot
 */
let slotReferences = {};
/**
 * adUnitReferences holds a reference to the adUnitCode for each
 * target position
 *
 *  {
 *      "banner-top-dt": {
 *          "adUnitCode": "/4282/web_nl/0/homepage/top"
 *      }
 *  }
 */
let adUnitReferences = {};

export function clearSlotReferences() {
  slotReferences = {};
  adUnitReferences = {};
}

/**
 * This function sets the GAM slot references to the slotReferences
 * variable above for fetching slots later on
 *
 * @param target - one of the target values from `src/requestAds/bannerConfig.js`
 * @param slot - an object representing single ad slot on a page.
 * @param adUnitCode - a string that represents the ad unit id, it normally looks
 *  something like this `/4282/web_nl/1099/srp/left`.
 */
export function setSlotReference(target, slot, adUnitCode) {
  slotReferences[target] = {
    slot,
  };
  adUnitReferences[target] = {
    adUnitCode,
  };
}

/**
 * @param target - one of the target values from `src/requestAds/bannerConfig.js`
 */
export function markSlotAsDisplayed(target) {
  slotReferences[target].displayed = true;
}

/**
 * @param target - one of the target values from `src/requestAds/bannerConfig.js`
 */
export function getSlotReference(target) {
  return slotReferences[target];
}

/**
 * @param target - one of the target values from `src/requestAds/bannerConfig.js`
 */
export function getAdUnitReference(target): { adUnitCode: string } {
  return adUnitReferences[target];
}

export function getAllUnfilledSlotReferences() {
  const unfilledSlots = {};

  Object.entries<TBannerConfig>(slotReferences).forEach(([key, slot]) => {
    if (!slot.displayed) {
      unfilledSlots[key] = slot;
    }
  });

  return unfilledSlots;
}
