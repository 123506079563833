import W from '../globals/Window';
import D from '../globals/Document';
// eslint-disable-next-line import/no-cycle
import { setupAdditionalBanners } from './requestBanners';

const THROTTLE_COUNTER = 200;
const IN_VIEWPORT_OFFSET = -250;
const isSmallScreen = W.matchMedia && W.matchMedia('(max-width: 560px)').matches;
let unfilledAdSlots = {};

/* eslint-disable */
let throttledTrackSlotInView;

function throttle(fn: Function, threshold: number, scope?: any) {
  threshold || (threshold = 250);
  let last;
  let deferTimer;

  return () => {
    // @ts-ignore
    const context = scope || this;

    const now = +new Date;
    // @ts-ignore
    const args = arguments;
    if (last && now < last + threshold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

/* eslint-enable */

function isInViewport(elem, offset) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (W.innerHeight || D.documentElement.clientHeight) - offset &&
    bounding.right <= (W.innerWidth || D.documentElement.clientWidth)
  );
}

function trackSlotInView() {
  Object.keys(unfilledAdSlots).forEach((slot) => {
    const adSlot = unfilledAdSlots[slot];

    if (isInViewport(adSlot.el, IN_VIEWPORT_OFFSET) || isSmallScreen) {
      setupAdditionalBanners([adSlot.position]);

      delete unfilledAdSlots[slot];
    }

    if (Object.keys(unfilledAdSlots).length <= 0) {
      W.removeEventListener('scroll', throttledTrackSlotInView);
    }
  });
}

function setUnfilledAdSlots(adSlots) {
  adSlots.forEach((adSlot) => {
    const el = D.getElementById(adSlot.target);
    if (el) {
      unfilledAdSlots[adSlot.target] = adSlot;
      unfilledAdSlots[adSlot.target].el = el;
    }
  });
  trackSlotInView();
}

throttledTrackSlotInView = throttle(trackSlotInView, THROTTLE_COUNTER);

/**
 * Purpose of this function is to request additional banners when positions come into view
 * Originally some positions are hidden by default, this means they are not visible + they have not been requested from GPT
 *
 * What does it mean to "request additional banners"?
 *  - it means we will repeat the process again for those hidden ads
 *  - this is the file for setting up ads https://github.es.ecg.tools/ecg-marktplaats/ecg-js-banners/blob/master/src/requestAds/adaptersForRefactoring/setupAdSlotsAdapter.ts#L1
 *
 * Config for hiding it can be found here https://github.es.ecg.tools/ecg-marktplaats/aurora-node-gpt-banners-data/blob/master/src/constants/BANNER_CONFIG.ts#L241
 * @param adSlots
 */
export default function trackContainerGetsInView(adSlots) {
  if (adSlots.length) {
    unfilledAdSlots = {};
    W.removeEventListener('scroll', throttledTrackSlotInView);
    setUnfilledAdSlots(adSlots);
    W.addEventListener('scroll', () => {
      throttledTrackSlotInView(adSlots);
    });
  }
}
