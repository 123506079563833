import SLOT_EVENTS from '../constants/SLOT_EVENTS';
import debugMessage from '../../../tools/debugMessage';
import logV6ExperimentMetric from '../../../tools/logV6ExperimentMetric';

/**
 * Event listener that is fired  when an ad response has been received for a particular slot.
 *
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotresponsereceived
 *
 * @param event {{ slot: {} }} see https://developers.google.com/publisher-tag/reference#googletag.slot
 *  for slot definition
 */
const slotResponseReceivedEventListener = (event: googletag.events.SlotResponseReceived): void => {
  /**
   * Get targeting will return an array and not a string
   */
  const position = event.slot.getTargeting('pos')?.slice()?.pop();

  if (!position) {
    return;
  }

  debugMessage(position, 'Ad response for slot received.', event);

  logV6ExperimentMetric(position, SLOT_EVENTS.SLOT_RESPONSE_RECIEVED);
};

export default slotResponseReceivedEventListener;
