import debugMessage from '../../v6/tools/debugMessage';
import buildAdSlotConfigV6 from '../../v6/adSlots/buildAdSlotConfigV6';

const refreshBidsAdapter = ({ gptPrebid, allAdSlots }) =>
  /**
   * Exposed global function used for refreshing banners
   *
   * @param targetBanners - a banner target, i.e 'banner-viptop-dt' or 'banner-vipleft-dt'
   * @param isAddingRefreshParameterActive
   * @returns {*}
   */
  function refreshBids(targetBanners: Array<string> | string, isAddingRefreshParameterActive: boolean) {
    if (!allAdSlots.data) {
      allAdSlots.data = buildAdSlotConfigV6();
    }

    const adSlotsData = allAdSlots.data.filter((adSlot) => targetBanners?.includes(adSlot.target));

    debugMessage('refreshBidsAdapter', `Refreshing bids for the following slots, ${targetBanners}`, adSlotsData);

    return gptPrebid.requestBids(adSlotsData, true).then((result) => {
      if (result) {
        gptPrebid.requestAds(result.targets, result.timeOut, result.bids, isAddingRefreshParameterActive);
      }
    });
  };

export default refreshBidsAdapter;
