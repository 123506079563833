const isBrowserViewportTooLarge = (maximumViewportWidth: number | string): boolean => {
  const browserViewportWidth =
    /**
     * Use the the interior width of the window in pixels
     * including the size of a rendered scroll bar
     */
    window.innerWidth ||
    /**
     * Fallback to using the client width of the <html> element
     */
    document.documentElement.clientWidth ||
    /**
     * Fallback to using the client width of the <body> element
     */
    document.body.clientWidth;

  /**
   * True if the maximumViewportWidth is less than the width of the browser viewport
   */
  return parseInt(`${maximumViewportWidth}`, 10) < browserViewportWidth;
};

export default isBrowserViewportTooLarge;
