import deleteSlotAndHideElement from './deleteSlotAndHideElement';
import debugMessage from '../tools/debugMessage';

const HOMESCREEN_UTM_PARAM = 'utm_source=a2hs';

/**
 * Prevents the home_top banner from loading if the homepage
 * is launched via a progressive web app
 */
const isUserUsingAPWA = (position: string, adSlot: TBannerConfig): boolean => {
  if (position === 'home_top' && window.location.href.includes(HOMESCREEN_UTM_PARAM)) {
    deleteSlotAndHideElement(adSlot);

    debugMessage(
      position,
      `User is using a PWA and the adSlot position is home_top, set 'adSlot.delete = true' and hide '${adSlot.hideEl}'`,
    );

    return true;
  }

  return false;
};

export default isUserUsingAPWA;
