export const GOOGLE_VENDOR_ID = 755;

export const GOOGLE_REQUIRED_PURPOSE = {
  STORE_AND_OR_ACCESS_INFORMATION_ON_A_DEVICE: 1,
  SELECT_BASIC_ADS: 2,
  MEASURE_AD_PERFORMANCE: 7,
  APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE_INSIGHTS: 9,
  DEVELOP_AND_IMPROVE_PRODUCTS: 10,
};

/**
 * Checks if the minimum requirements to get google to serve ads
 * has been satisfied.
 *
 * @param tcData
 * @return {boolean}
 */
// eslint-disable-next-line complexity
const ifMinGoogleConsentGranted = (tcData: any = {}) => {
  if (!tcData?.vendor || !tcData?.purpose) {
    return false;
  }

  const { vendor, purpose } = tcData;
  const requirements = [
    vendor?.consents[GOOGLE_VENDOR_ID],
    vendor?.legitimateInterests[GOOGLE_VENDOR_ID],
    purpose?.consents[GOOGLE_REQUIRED_PURPOSE.STORE_AND_OR_ACCESS_INFORMATION_ON_A_DEVICE],
    purpose?.consents[GOOGLE_REQUIRED_PURPOSE.SELECT_BASIC_ADS] ||
      purpose?.legitimateInterests[GOOGLE_REQUIRED_PURPOSE.SELECT_BASIC_ADS],
    purpose?.consents[GOOGLE_REQUIRED_PURPOSE.MEASURE_AD_PERFORMANCE] ||
      purpose?.legitimateInterests[GOOGLE_REQUIRED_PURPOSE.MEASURE_AD_PERFORMANCE],
    purpose?.consents[GOOGLE_REQUIRED_PURPOSE.APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE_INSIGHTS] ||
      purpose?.legitimateInterests[GOOGLE_REQUIRED_PURPOSE.APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE_INSIGHTS],
    purpose.consents[GOOGLE_REQUIRED_PURPOSE.DEVELOP_AND_IMPROVE_PRODUCTS] ||
      purpose?.legitimateInterests[GOOGLE_REQUIRED_PURPOSE.DEVELOP_AND_IMPROVE_PRODUCTS],
  ];

  return requirements.every(Boolean);
};

export default ifMinGoogleConsentGranted;
