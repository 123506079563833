import objectValuesToArray from '../prebid/objectValuesToArray';
import debugMessage from '../tools/debugMessage';
import generateUUID from '../../helpers/generateUUID';
import stringHash from 'string-hash';
import getViewportSizesForPositionV6 from '../helpers/getViewportSizesForPositionV6';
import getConfig from '../../requestAds/getConfig';
import getScreenSize from '../../helpers/getScreenSize';
import buildPlacementIdObject from './buildPlacementIdObject';
import buildSSpImproveBidConfig from './buildSSpImproveBidConfig';
import buildSSPRubiconBidConfig from './buildSSPRubiconBidConfig';
import buildSSPAppNexusBidConfig from './buildSSPAppNexusBidConfig';
import buildSSPTmpBidConfig from './buildSSPTmpBidConfig';
import buildSSPCriteoBidConfig from './buildSSPCriteoBidConfig';
import buildSSPIndexExchangeBidConfig from './buildSSPIndexExchangeBidConfig';
import buildSSPGumGumBidConfig from './buildSSPGumGumBidConfig';
import buildSSPAdheseBidConfig from './buildSSPAdheseBidConfig';
import getBannerPosition from './getBannerPosition';
import buildSSPAppNexusWeboramaBidConfig from './buildSSPAppNexusWeboramaBidConfig';

const PREBID_CONFIG_NAMESPACE = 'prebidConfig';

const screenSize = getScreenSize();

type TAdUnitConfig = {
  code?: string;
  sizes: TBannerSizes[];
  sizeMapping?: { minWidth: number; sizes: any[] }[];
  bids: TPrebidSSPConfig[];
  mediaTypes: {
    banner: {
      sizes: TBannerSizes[];
    };
  };
} | null;

const mapAdSlotConfig = (adSlot: TBannerConfig): TAdUnitConfig => {
  const { categoryId, publisherProvidedId, isConsentPresent, bannerData } = getConfig();

  const bannerParameters = Object.assign(adSlot.bannerParameters || {}, {
    userid: publisherProvidedId || null,
    iid: generateUUID(),
    aus: stringHash(adSlot.adUnit || ''),
  });

  const sizes = getViewportSizesForPositionV6(adSlot);

  const position = getBannerPosition(bannerParameters);

  const bannerParametersFormatted = objectValuesToArray(bannerParameters);

  debugMessage(PREBID_CONFIG_NAMESPACE, `Using Key Value Object`, bannerParametersFormatted);

  const placementIdObject = buildPlacementIdObject({ categoryId, position });

  const biddersUnFlatted = [
    buildSSpImproveBidConfig({ placementIdObject, bannerParametersFormatted }),
    buildSSPRubiconBidConfig({ placementIdObject, bannerParametersFormatted }),
    buildSSPAppNexusBidConfig({ placementIdObject, bannerParametersFormatted }),
    buildSSPAppNexusWeboramaBidConfig({ placementIdObject, bannerParametersFormatted }),
    buildSSPTmpBidConfig({ placementIdObject, bannerParametersFormatted }),
    buildSSPCriteoBidConfig({ placementIdObject, sizes }),
    buildSSPIndexExchangeBidConfig({ placementIdObject, sizes }),
    buildSSPGumGumBidConfig({ placementIdObject }),
    buildSSPAdheseBidConfig({
      placementIdObject,
      isConsentPresent,
      position,
      screenSize,
      publisherProvidedId,
    }),
  ];

  const bidders: TPrebidSSPConfig[] = biddersUnFlatted.flat();

  const sizeMapping = bannerData[position].prebidMapping;

  debugMessage(PREBID_CONFIG_NAMESPACE, `${position}: Setting prebid size mappings`, sizeMapping);

  const preBidConfig = {
    code: adSlot.adUnit,
    sizes,
    sizeMapping,
    bids: bidders,
    mediaTypes: {
      banner: {
        sizes,
      },
    },
  };

  debugMessage(position, `${position}: Using prebid config`, preBidConfig);

  /**
   * We don't have any placement id for lrp_video position. Meaning for lrp_video
   * position, bidders array will always be empty, and adUnitCodes will always
   * be not specified, and it will request bids for all ad units added (again).
   * In order to prevent this we need to let `lrp_video` pass through even though
   * it's not part of the header bidding
   */
  if (position === 'lrp_video' && preBidConfig?.code !== undefined) {
    return preBidConfig;
  }

  return bidders.length ? preBidConfig : null;
};

export default mapAdSlotConfig;
