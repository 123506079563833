import debugMessage from './debugMessage';
import getConfig from '../../requestAds/getConfig';
import { addMetrics } from '../metrics/bannerMetrics';
import logSentryError from '../metrics/sentry/logSentryError';

const logV6ExperimentMetric = (position, metric) => {
  const config = getConfig();

  if (!config.gptBatchMetricsEndpointUrl) {
    return;
  }

  try {
    const INCREMENT_COUNT = 1;

    addMetrics(
      {
        [`${metric}Counter`]: INCREMENT_COUNT,
        [`${metric}`]: Math.round(window?.performance?.now()),
      },
      position,
    );

    debugMessage(position, `Metric logged to server for ${metric}`, INCREMENT_COUNT);
  } catch (error) {
    debugMessage(position, `Error logging metric to server for ${metric}`, error);

    logSentryError(error);
  }
};

export default logV6ExperimentMetric;
