import checkAdBlocker from './checkAdBlocker';
import checkDependencies from './checkDependencies';
import checkConsent from './checkConsent/checkConsent';
import initiateBannerLib from './initiateBannerLib';
import debugMessage from '../tools/debugMessage';

const setup = async () => {
  try {
    debugMessage(`bannerSetup`, 'Start setting up banner lib');
    await checkAdBlocker();
    await checkDependencies();
    await checkConsent();
    initiateBannerLib();
  } catch (e) {
    debugMessage(`bannerSetup`, 'Banner lib failed to load');
  }
};

export default setup;
