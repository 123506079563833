import { setupAdditionalBanners, refreshBids, resetAllBannersInPage } from '../../requestAds/requestBanners';
import addAdobeRsiSegments from './addAdobeRsiSegments';

const setupGlobalMethods = () => {
  window.ecgJsBanners = {
    setupAdditionalBanners,
    refreshBids,
    resetAllBannersInPage,
    addAdobeRsiSegments,
  };
};

export default setupGlobalMethods;
