import { isAllPurposesAccepted } from '@ecg-marktplaats/gdpr-consent-string';
import D from '../globals/Document';
import googleTag from '../globals/googletag';
import eventLogger from './eventLogger';
import { getAllUnfilledSlotReferences, markSlotAsDisplayed } from './slotReferences';

const displayBanners = () => {
  const allSlotReferences = Object.entries(getAllUnfilledSlotReferences());

  allSlotReferences.forEach(([target]) => {
    markSlotAsDisplayed(target);

    if (D.getElementById(target)) {
      googleTag.display(target);

      eventLogger({
        eventAction: 'EcgJsBannersAdRequested',
        eventLabel: target,
        dimension188: `TCF 2: ${isAllPurposesAccepted() ? 'Consent Given' : 'No Consent Given'}`,
      });
    } else {
      eventLogger({
        eventAction: 'Banner not rendered',
      });
    }
  });
};

export default displayBanners;
