const COLORS = {
  YELLOW: '#F2CA19',
  PURPLE: '#8931EF',
  PINK: '#FF00BD',
  GREEN: '#87E911',
  TEAL: '#00C7FC',
  RED: '#FC0000',
  LIGHT_RED: '#FFD1D1',
  ORANGE: '#FF7700',
  DARK_ORANGE: '#532800',
  LIGHT_BLUE: '#6AF1FD',
  BLUE: '#003A6F',
};

const COLOR_SCHEMES = [
  {
    text: COLORS.YELLOW,
    background: COLORS.PURPLE,
  },
  {
    text: COLORS.YELLOW,
    background: COLORS.PINK,
  },
  {
    text: COLORS.YELLOW,
    background: COLORS.RED,
  },
  {
    text: COLORS.PURPLE,
    background: COLORS.YELLOW,
  },
  {
    text: COLORS.PURPLE,
    background: COLORS.TEAL,
  },
  {
    text: COLORS.PINK,
    background: COLORS.TEAL,
  },
  {
    text: COLORS.PINK,
    background: COLORS.GREEN,
  },
  {
    text: COLORS.PINK,
    background: COLORS.BLUE,
  },
  {
    text: COLORS.GREEN,
    background: COLORS.PINK,
  },
  {
    text: COLORS.GREEN,
    background: COLORS.PURPLE,
  },
  {
    text: COLORS.TEAL,
    background: COLORS.PINK,
  },
  {
    text: COLORS.TEAL,
    background: COLORS.RED,
  },
  {
    text: COLORS.BLUE,
    background: COLORS.GREEN,
  },
  {
    text: COLORS.LIGHT_RED,
    background: COLORS.RED,
  },
  {
    text: COLORS.LIGHT_RED,
    background: COLORS.PURPLE,
  },
  {
    text: COLORS.PURPLE,
    background: COLORS.LIGHT_BLUE,
  },
  {
    text: COLORS.GREEN,
    background: COLORS.PINK,
  },
  {
    text: COLORS.DARK_ORANGE,
    background: COLORS.ORANGE,
  },
  {
    text: COLORS.LIGHT_BLUE,
    background: COLORS.ORANGE,
  },
  {
    text: COLORS.BLUE,
    background: COLORS.TEAL,
  },
];

export default COLOR_SCHEMES;
