import ifMinGoogleConsentGranted from './ifMinGoogleConsentGranted';
import ADDITIONAL_CONSENT_STRING from '../v6/constants/ADDITIONAL_CONSENT_STRING';

/**
 * This is a temporary work around for vendors who have not signed
 * up to the IAB. Do not add middleware to the `getTCData` function in CmpApi
 * to add the `addtlConsent` because it does not work. In the first few instances,
 * the tcData does not seem to load the full cendor list, so there seems to be
 * some sort of race condition. This function Must be invoked after the `CmpApi` has
 * been instantiated.
 *
 * For more details see this guide
 * https://support.google.com/admanager/answer/9681920?hl=en
 */
const enhanceTcfApi = () => {
  if (typeof window === 'undefined' || !window?.__tcfapi) {
    return;
  }

  window.__tcfapiOriginal = window.__tcfapi;

  window.__tcfapi = (command, version, callback, ...args) => {
    window.__tcfapiOriginal(
      command,
      version,
      (tcData, success) => {
        if (ifMinGoogleConsentGranted(tcData)) {
          tcData.addtlConsent = ADDITIONAL_CONSENT_STRING;
        }

        callback(tcData, success);
      },
      ...args,
    );
  };
};

export default enhanceTcfApi;
