import { TCString } from '@iabtcf/core';
import { getTCStringFromCookies } from '@ecg-marktplaats/gdpr-consent-string';
import ADDITIONAL_CONSENT_STRING from '../v6/constants/ADDITIONAL_CONSENT_STRING';
import logSentryError from '../v6/metrics/sentry/logSentryError';

/**
 * This function deserialises the TCString data so that vendors using loadCmp.js can
 * determine a users preferences for purpose and vendor, consents and legitimate interests.
 *
 * @param tcString
 * @return {{}|TCData}
 * @dataModel https://github.com/InteractiveAdvertisingBureau/iabtcf-es/blob/master/modules/cmpapi/src/response/TCData.ts
 */
const getTCData = (tcString = getTCStringFromCookies()) => {
  if (typeof tcString !== 'string' || tcString?.[0] !== 'C') {
    return {};
  }

  try {
    const tcModel = TCString.decode(tcString);
    const purposesAndVendorsConfig = {
      tcString,
      gdprApplies: true,
      addtlConsent: ADDITIONAL_CONSENT_STRING,
      purpose: {
        consents: {},
        legitimateInterests: {},
      },
      vendor: {
        consents: {},
        legitimateInterests: {},
      },
    };

    tcModel.purposeConsents.forEach((state, purposeId) => {
      purposesAndVendorsConfig.purpose.consents[purposeId] = state;
    });

    tcModel.purposeLegitimateInterests.forEach((state, purposeId) => {
      purposesAndVendorsConfig.purpose.legitimateInterests[purposeId] = state;
    });

    tcModel.vendorConsents.forEach((state, vendorId) => {
      purposesAndVendorsConfig.vendor.consents[vendorId] = state;
    });

    tcModel.vendorLegitimateInterests.forEach((state, vendorId) => {
      purposesAndVendorsConfig.vendor.legitimateInterests[vendorId] = state;
    });

    return purposesAndVendorsConfig;
  } catch (error) {
    logSentryError(error);

    return {};
  }
};

export default getTCData;
