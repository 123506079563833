import debugMessage from '../../tools/debugMessage';

/**
 * This function rewrites the filename of the frame so we can match it to
 * the sourcemaps in sentry
 *
 * @param event
 */
const rewriteSentryFrameFileName = (event) => {
  if (event.exception) {
    event.exception?.values?.[0]?.stacktrace?.frames?.forEach((frame) => {
      /**
       * Rewite the base url so we can map source maps in sentry
       */
      frame.filename = frame?.filename?.replace(/.*ecg-js-banners/, '~/dist');
    });
  }

  debugMessage(DEBUG_NAMESPACE, `Sentry event sent`, event);

  return event;
};

export default rewriteSentryFrameFileName;
