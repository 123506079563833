import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';
import tenantData from '../../locales';

const { SSP_RUBICON } = SSP_PROVIDERS;

const buildSSPRubiconBidConfig = ({ placementIdObject, bannerParametersFormatted }): TSSPRubiconBidConfig[] => {
  /**
   * https://docs.prebid.org/dev-docs/bidders/rubicon.html#sidebar
   */
  if (placementIdObject[SSP_RUBICON]) {
    return [
      {
        bidder: SSP_RUBICON,
        params: {
          zoneId: placementIdObject[SSP_RUBICON],
          accountId: tenantData.RUBICON_CONFIG.accountId,
          siteId: tenantData.RUBICON_CONFIG.siteId,
          referrer: tenantData.RUBICON_CONFIG.referrer,
          inventory: bannerParametersFormatted,
        },
      },
    ];
  }
  return [];
};

export default buildSSPRubiconBidConfig;
