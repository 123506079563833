import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';
import getConfig from '../../requestAds/getConfig';

const { SSP_CRITEO } = SSP_PROVIDERS;

const buildSSPCriteoBidConfig = ({ placementIdObject, sizes }): TSSPCriteoConfig[] => {
  const { consentedVendors } = getConfig();
  /**
   * https://docs.prebid.org/dev-docs/bidders/criteo.html#sidebar
   */
  if (placementIdObject[SSP_CRITEO] && consentedVendors?.Criteo) {
    let widestPlacementId = sizes
      .map((s) => [s[0] + s[1], placementIdObject[SSP_CRITEO][`${s[0]}x${s[1]}`]])
      .filter((n) => n[1] !== undefined);

    if (widestPlacementId.length > 0) {
      widestPlacementId = widestPlacementId.reduce((ref, item) => (item[0] > ref[0] ? item : ref));
      return [
        {
          bidder: SSP_CRITEO,
          params: {
            zoneId: widestPlacementId[1] as any, // TODO fix types for this param + replace it with networkId as zoneId is deprecated
          },
        },
      ];
    }
  }
  return [];
};

export default buildSSPCriteoBidConfig;
