import SLOT_EVENTS from '../constants/SLOT_EVENTS';
import debugMessage from '../../../tools/debugMessage';
import hideBannerElement from '../../../helpers/hideBannerElement';
import eventLogger from '../../../../requestAds/eventLogger';
import logV6ExperimentMetric from '../../../tools/logV6ExperimentMetric';
import getElementsToHide from '../../../helpers/getElementsToHide';

/**
 * Event listener that is fired when the creative code is injected into a slot.
 * This event will occur before the creative's resources are fetched, so the
 * creative may not be visible yet.
 *
 * If you need to know when all creative resources for a slot have finished loading,
 * consider the SlotOnloadEvent instead.
 *
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotrenderendedevent
 *
 * @param event {{ slot: {} }} see https://developers.google.com/publisher-tag/reference#googletag.slot
 *  for slot definition
 */
const slotRenderEndedEventListener = (event: googletag.events.SlotRenderEndedEvent): void => {
  /**
   * Get targeting will return an array and not a string
   */
  const position = event.slot.getTargeting('pos')?.slice()?.pop();

  if (!position) {
    return;
  }

  debugMessage(position, `Slot finished rendering and was ${event.isEmpty ? 'empty' : 'with a banner'}`, event);

  logV6ExperimentMetric(position, SLOT_EVENTS.SLOT_RENDER_ENDED);

  if (event.isEmpty) {
    const hideElement = getElementsToHide(position);

    hideBannerElement(hideElement);

    eventLogger({
      eventAction: 'Banner empty',
      eventLabel: position,
    });
  }

  logV6ExperimentMetric(
    position,
    event.isEmpty ? SLOT_EVENTS.SLOT_RENDER_ENDED_EMPTY : SLOT_EVENTS.SLOT_RENDER_ENDED_WITH_AD,
  );
};

export default slotRenderEndedEventListener;
