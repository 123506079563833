import set from 'lodash.set';
import get from 'lodash.get';
import debugMessage from '../tools/debugMessage';

/**
 * Sets a property on an adSlot
 */
const setAdSlotProperty = (adSlot: TBannerConfig, property: string, dataToSet: any): void => {
  const position = adSlot?.bannerParameters?.pos || 'no_position';

  set(adSlot, property, dataToSet);

  debugMessage(position, `Set the value of 'adSlot.${property}' to`, get(adSlot, property));
};

export default setAdSlotProperty;
