enum SSP_PROVIDERS {
  SSP_IMPROVE = 'improvedigital',
  SSP_RUBICON = 'rubicon',
  SSP_APPNEXUS = 'appnexus',
  SSP_APPNEXUS_WEBORAMA = 'appnexus_weborama',
  SSP_TMP = 'tmp',
  SSP_INDEXEXCHANGE = 'ix',
  SSP_CRITEO = 'criteo',
  SSP_GUMGUM = 'gumgum',
  SSP_ADHESE = 'adhese',
}

export default SSP_PROVIDERS;
