import SSP_PROVIDERS from '../constants/SSP_PROVIDERS';
import tenantData from '../../locales';
const { SSP_APPNEXUS_WEBORAMA } = SSP_PROVIDERS;

const buildSSPAppNexusWeboramaBidConfig = ({
  placementIdObject,
  bannerParametersFormatted,
}): TSSPAppNexusBidConfig<typeof SSP_APPNEXUS_WEBORAMA>[] => {
  /**
   * https://docs.prebid.org/dev-docs/bidders/appnexus.html#sidebar
   */
  if (placementIdObject[SSP_APPNEXUS_WEBORAMA]) {
    const appnexusConfig = {
      keywords: bannerParametersFormatted,
      placementId: placementIdObject[SSP_APPNEXUS_WEBORAMA],
      member: tenantData.APP_NEXUS_WEBORAMA_CONFIG?.member || '',
    };

    return [
      {
        bidder: SSP_APPNEXUS_WEBORAMA,
        params: appnexusConfig,
      },
    ];
  }
  return [];
};

export default buildSSPAppNexusWeboramaBidConfig;
