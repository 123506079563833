import W from '../globals/Window';

const TRUSTED_ORIGIN = 'https://tpc.googlesyndication.com';
const SITE_ORIGIN = `https://${W.location.hostname}`;

/**
 * Checks if the origin of a SafeFrame message is trusted or not
 *
 * @param origin
 * @returns {boolean}
 */
const isTrustedOrigin = (origin) => origin === TRUSTED_ORIGIN || origin === SITE_ORIGIN;

export default isTrustedOrigin;
