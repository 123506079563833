import getRandomColor from './helpers/getRandomColor';

const colorLibrary = {};

const getPillColors = (namespace: string) => {
  if (colorLibrary?.[namespace]) {
    return colorLibrary?.[namespace];
  }

  const colors = getRandomColor();

  colorLibrary[namespace] = colors;

  return colors;
};

export default getPillColors;
