import SLOT_EVENTS from './constants/SLOT_EVENTS';
import googletag from '../../../globals/googletag';
import isDebugMode from '../../tools/helpers/isDebugMode';
import impressionViewableEventListener from './eventListeners/impressionViewableEventListener';
import slotOnloadEventListener from './eventListeners/slotOnloadEventListener';
import slotRenderEndedEventListener from './eventListeners/slotRenderEndedEventListener';
import slotRequestedEventListener from './eventListeners/slotRequestedEventListener';
import slotResponseReceivedEventListener from './eventListeners/slotResponseReceivedEventListener';
import slotVisibilityChangedEventListener from './eventListeners/slotVisibilityChangedEventListener';

const setupSlotMetrics = (isBannerRefresh?: boolean): void => {
  if (isBannerRefresh) {
    /**
     * Prevent setting up the event listeners for the
     * slots again if we are just refreshing the banners on the page
     * @type {boolean}
     */
    return;
  }

  googletag.cmd.push(() => {
    const publisherAdsService = googletag.pubads();
    const eventListeners: [string, typeof googletag.events][] = [
      [SLOT_EVENTS.IMPRESSION_VIEWABLE, impressionViewableEventListener],
      [SLOT_EVENTS.SLOT_ONLOAD, slotOnloadEventListener],
      [SLOT_EVENTS.SLOT_RENDER_ENDED, slotRenderEndedEventListener],
      [SLOT_EVENTS.SLOT_REQUESTED, slotRequestedEventListener],
      [SLOT_EVENTS.SLOT_RESPONSE_RECIEVED, slotResponseReceivedEventListener],
    ];

    if (isDebugMode()) {
      /**
       * Only register this event listeners when the debug mode is on as it renders
       * every 200ms
       */
      eventListeners.push([SLOT_EVENTS.SLOT_VISIBILITY_CHANGED, slotVisibilityChangedEventListener]);
    }

    eventListeners.forEach(([eventName, eventListener]) =>
      publisherAdsService.addEventListener(eventName, eventListener),
    );
  });
};

export default setupSlotMetrics;
