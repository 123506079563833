import W from '../globals/Window';
import getConfig from './getConfig';
import addEventListener from '../helpers/addEventListener';
import isTrustedOrigin from '../helpers/isTrustedOrigin';

const eventLogger = (event) => {
  if (getConfig().enableAnalytics) {
    if (W.ecGa && W.ga) {
      W.ecGa('trackEvent', event);
    } else {
      W.ecGaEventsOnLoad = W.ecGaEventsOnLoad || [];
      W.ecGaEventsOnLoad.push(event);
    }
  }
};

/**
 * The event listener is exported here so we can remove this event listener
 * using W.removeEventListener
 *
 * @param event
 */
export const messageEventListener = (event) => {
  if (
    isTrustedOrigin(event.origin) &&
    // There is event Data
    event.data &&
    // and there is an event action and the event action is a string
    event.data.eventAction &&
    typeof event.data.eventAction === 'string' &&
    // and there isn't and event label or the event label is a string
    (!event.data.eventLabel || typeof event.data.eventLabel === 'string')
  ) {
    // Then track the event
    eventLogger({
      eventAction: event.data.eventAction,
      eventLabel: event.data.eventLabel,
    });
  }
};

/**
 * Adds an event listener that will log message events to GA
 * if the data has eventAction and eventLabel or just an eventAction
 * using the IAB's SafeFrame specification
 */
export const setupSafeFrameTracking = () => addEventListener(messageEventListener);

export default eventLogger;
