import debugMessage from '../../../tools/debugMessage';

/**
 * Event listener that is fired whenever the on-screen percentage of an ad slot's area changes.
 * The event is throttled and will not fire more often than once every 200ms.
 *
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotvisibilitychangedevent
 *
 * @param event {{ slot: {} }} see https://developers.google.com/publisher-tag/reference#googletag.slot
 *  for slot definition
 */
const slotVisibilityChangedEventListener = (event: googletag.events.SlotVisibilityChangedEvent): void => {
  /**
   * Get targeting will return an array and not a string
   */
  const position = event.slot.getTargeting('pos')?.slice()?.pop();

  if (!position) {
    return;
  }

  debugMessage(position, `Visibility of slot changed, visible area: ${event.inViewPercentage}%`, event);
};

export default slotVisibilityChangedEventListener;
