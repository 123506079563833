import createSentryClient from './createSentryClient';

const POLL_TIME = 1000;
const POLL_LIMIT = 10;
const sentryClient = createSentryClient();

const logSentryError = (exception: any, poleLimit = 0) => {
  if (sentryClient) {
    sentryClient.captureException(exception);

    return;
  }

  if (poleLimit === POLL_LIMIT) {
    return;
  }

  setTimeout(() => {
    logSentryError(exception, poleLimit + 1);
  }, POLL_TIME);
};

export default logSentryError;
