import getConfig from '../../../requestAds/getConfig';
import injectAdBlockerDetectionScript from './injectAdBlockerDetectionScript';
import trackGaEvent from '../../helpers/trackGaEvent';

/**
 * To detect AdBlockers we inject a script tag that loads
 * an empty javascript file with a suspicious looking query param
 * that is blocked by easyList. If the file does is not loaded we
 * then know the user is using an ad blocker.
 *
 * @reference https://easylist-downloads.adblockplus.org/easylist.txt
 */
const checkAdBlocker = (): Promise<void> => {
  const config = getConfig();
  if (config?.adblockerFile) {
    return injectAdBlockerDetectionScript(config.adblockerFile).catch(() => {
      trackGaEvent({
        eventLabel: 'ecgJBannersLibrary',
        eventAction: 'adBlocked',
      });
      return Promise.reject();
    });
  }
  return Promise.resolve();
};

export default checkAdBlocker;
